import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SponsorService from '../../services/sponsorService';

/**
 * A functional component that displays the user's profile information.
 * @returns A JSX element that displays the user's profile information.
 */
function UserProfile() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);

  // useEffect hook that checks if the user is logged in and redirects to the homepage if not.
  useEffect(() => {
    if (!SponsorService.checkLoggedIn()) {
      window.location.href = '/';
    } else {
      if (user.length === 0) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  });

  return (
    <div className="user-profile">
      {loading ? (
        <div className="user-profile-loader">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row>
            <div className="user-profile-left">User Profile</div>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={6} lg={4} xl={4}>
              <div className="user-profile-left">
                <div className="user-profile-left-img">
                  {user.profilePic ? (
                    <img src={user.profilePic} alt="Profile" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={18} lg={20} xl={20}>
              <div className="user-profile-right">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row>
                      <Col span={10} className="user-profile-right-text">
                        First Name
                      </Col>
                      <Col span={14} className="user-profile-right-text">
                        {user && user.firstName ? user.firstName : '--'}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10} className="user-profile-right-text">
                        Last Name
                      </Col>
                      <Col span={14} className="user-profile-right-text">
                        {user && user.lastName ? user.lastName : '--'}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10} className="user-profile-right-text">
                        Email
                      </Col>
                      <Col span={14} className="user-profile-right-text">
                        {user && user.email ? user.email : '--'}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10} className="user-profile-right-text">
                        Phone Number
                      </Col>
                      <Col span={14} className="user-profile-right-text">
                        {user && user.phoneNumber ? user.phoneNumber : '--'}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row>
                      <Col span={10} className="user-profile-right-text">
                        Company Name
                      </Col>
                      <Col span={14} className="user-profile-right-text">
                        {user && user.companyName ? user.companyName : '--'}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10} className="user-profile-right-text">
                        Company Type
                      </Col>
                      <Col span={14} className="user-profile-right-text">
                        {user && user.companyType ? user.companyType : '--'}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default UserProfile;
