import React, { useState, useEffect } from 'react';
import { Skeleton, Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import LookUpDataForm from './LookUpDataForm';
import CommonService from '../../services/commonService';
const { Panel } = Collapse;

/**
 * A functional component that retrieves all lookup data from the server and displays it in a collapsible format.
 * @returns A JSX element that displays the lookup data in a collapsible format.
 */
function LookUpDatas() {
  const [lookUpDatas, setLookUpdatas] = useState();

  // useEffect hook that fetches all lookup data and updates the state with the data.
  useEffect(() => {
    CommonService.getAllLookupDatas()
      .then((res) => {
        let catList = {};
        res.data.data.forEach((ld) => {
          if (!catList[ld.key]) {
            catList[ld.key] = [ld];
          } else {
            catList[ld.key].push(ld);
          }
        });
        let updatedList = [];
        for (const key in catList) {
          updatedList.push({
            key: key,
            values: catList[key],
          });
        }
        setLookUpdatas(updatedList);
      })
      .catch(() => {
        window.location.href = '/unauthorized-access';
      });
  }, []);
  return (
    <div className="template-body">
      {lookUpDatas ? (
        lookUpDatas.map((ld) => {
          return (
            <Collapse
              key={ld.key}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <FontAwesomeIcon
                  icon={faCaretRight}
                  rotation={isActive ? 90 : 0}
                  className="site-expand-icon"
                />
              )}
              className="template-collapse"
              destroyInactivePanel
            >
              <Panel
                key={ld.key}
                header={
                  <div className="site-header-bar">
                    <div className="site-header-name">{ld.key}</div>
                  </div>
                }
              >
                <LookUpDataForm data={ld} setLookUpdatas={setLookUpdatas} />
              </Panel>
            </Collapse>
          );
        })
      ) : (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </div>
  );
}

export default LookUpDatas;
