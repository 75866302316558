import React, { useRef, useEffect, useState } from 'react';
import { Spin } from 'antd';
import LoginForm from './LoginForm';
import SponsorService from '../../services/sponsorService';

/**
 * A functional component that renders a login page. If the user is not logged in, it displays a login form.
 * Otherwise, it displays a loading spinner.
 * @returns {JSX.Element} - The login page component.
 */
const Login = () => {
  const mainRef = useRef();
  const [userNotLoggedIn, setuserNotLoggedIn] = useState(false);
  /**
   * useEffect hook that runs when the component mounts. It scrolls the window to the top,
   * It then checks if the user is logged in using SponsorService.checkLoggedIn().
   * If the user is logged in, it redirects them to the home page. Otherwise, it sets the state
   * variable userNotLoggedIn to true.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    if (SponsorService.checkLoggedIn()) {
      window.location.href = '/';
    } else {
      setuserNotLoggedIn(true);
    }
  }, []);
  return userNotLoggedIn ? (
    <div className="login-page-body">
      <LoginForm mainRef={mainRef} />
    </div>
  ) : (
    <div className="spin-loader-div-container">
      <Spin size="large" />
    </div>
  );
};

export default Login;
