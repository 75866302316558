import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import CollectionList from '../../components/CollectionList';
import ReachedOutList from '../../components/ReachedOutList';
import SponsorService from '../../services/sponsorService';

/**
 * A functional component that displays the user's list of collections and reached out sites.
 * @returns A JSX element that displays the user's list of collections and reached out sites.
 */
const MyList = () => {
  const [listCount, setListCount] = useState(0);
  const [reachedCount, setReachedCount] = useState(0);
  /**
   * Runs the specified functions when the component mounts and whenever the listCount or reachedCount state variables change.
   * If the user is not logged in, logs them out. Then, retrieves the collection and reached out lists from the SponsorService API
   * and updates the corresponding state variables.
   */
  useEffect(() => {
    if (!SponsorService.checkLoggedIn()) {
      SponsorService.logout();
    }
    SponsorService.getCollectionList().then((res) => {
      setListCount(res.data.data.length);
    });
    SponsorService.getReachedOutList().then((res) => {
      setReachedCount(res.data.data.length);
    });
  }, [listCount, reachedCount]);

  return (
    <>
      <div className="my-list-container body-container">
        <div className="my-list-header sites-header">My List</div>
        <div className="my-list-body">
          <Tabs
            defaultActiveKey="1"
            type="card"
            destroyInactiveTabPane
            items={[
              {
                label: `List (${listCount})`,
                key: '1',
                children: <CollectionList setListCount={setListCount} />,
              },
              {
                label: `Reached Out (${reachedCount})`,
                key: '2',
                children: <ReachedOutList setReachedCount={setReachedCount} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
export default MyList;
