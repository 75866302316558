import axios from '../../config/axiosConfig';
import {
  acceptedTermsUrl,
  checkUserRegistered,
  commonUrl,
  createPasswordurl,
  forgotEmail,
  getProfileUrl,
  inviteUserUrl,
  loginUrl,
  manageUsersUrl,
  newSignupUrl,
  signupUrl,
  termsAndConditionsUrl,
  userActionUrl,
  userProfilePicurl,
  verifyEmail,
  verifyUserToken,
} from '../../constants/urls';

const SponsorService = {
  /**
   * Checks if the user is logged in by checking if the "SDIR_SPONSOR_TOKEN" key exists in localStorage.
   * @returns {boolean} - true if the user is logged in, false otherwise.
   */
  checkLoggedIn: () => {
    return localStorage.getItem('SDIR_SPONSOR_TOKEN') ? true : false;
  },
  /**
   * Retrieves the profile picture for a given email address.
   * @param {string} email - The email address associated with the user's profile picture.
   * @returns {Promise} A promise that resolves with the user's profile picture.
   */
  getProfilePic: (email) => {
    const payload = {
      email: email,
    };
    return axios.post(userProfilePicurl, payload);
  },
  /**
   * Logs in a user with the given email and password by sending a POST request to the login URL
   * with the email and password as the payload.
   * @param {string} email - The email of the user to log in.
   * @param {string} password - The password of the user to log in.
   * @returns A Promise that resolves to the response from the server.
   */
  login: (email, password) => {
    const payload = {
      email: email,
      password: password,
    };
    return axios.post(loginUrl, payload);
  },
  /**
   * Logs out the current user by removing the sponsor token from local storage and redirecting to the home page.
   * @returns None
   */
  logout: () => {
    localStorage.removeItem('SDIR_SPONSOR_TOKEN');
    window.location.href = '/';
  },
  /**
   * Sends a GET request to the server to retrieve the user's profile information.
   * @returns {Promise} A promise that resolves with the user's profile information.
   */
  getProfile: () => {
    return axios.get(getProfileUrl);
  },
  /**
   * Sends a POST request to the forgotEmail endpoint with the given email.
   * @param {string} email - The email address to send the forgot password email to.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  forgotPassword: (email) => {
    const payload = {
      email: email,
    };
    return axios.post(forgotEmail, payload);
  },
  /**
   * Verifies the user's email using the provided token.
   * @param {string} token - The token to use for email verification.
   * @returns {Promise} A promise that resolves with the verification response.
   */
  verfyUser: (token) => {
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    return axios.get(verifyEmail, { headers });
  },
  /**
   * Sends a POST request to the server to create a new password.
   * @param {Object} payload - The payload object containing the necessary data to create a new password.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  createPassword: (payload) => {
    return axios.post(createPasswordurl, payload);
  },
  /**
   * Sends a POST request to the server to sign up a new user with the given payload and token.
   * @param {Object} payload - The user data to be sent to the server for sign up.
   * @param {string} token - The authentication token to be included in the request headers.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  signupUser: (payload, token) => {
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    return axios.post(signupUrl, payload, { headers });
  },
  /**
   * Sends a POST request to the inviteUserUrl endpoint with the given payload.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A promise that resolves with the response data if successful, or rejects with an error.
   */
  inviteUser: (payload) => {
    return axios.post(inviteUserUrl, payload);
  },
  /**
   * Resends an invitation email to the user with the given email address.
   * @param {string} email - The email address of the user to resend the invitation to.
   * @returns A Promise that resolves with the response from the server.
   */
  resendInviteUser: (email) => {
    var config = {
      method: 'get',
      url: 'user/resend-invite',
      params: {
        email: email,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return axios(config);
  },
  /**
   * Makes the user with the given email an admin by sending a PUT request to the server.
   * @param {string} email - The email of the user to make an admin.
   * @returns A promise that resolves with the response from the server.
   */
  makeAdmin: (email) => {
    var config = {
      method: 'put',
      url: 'user/make-admin',
      params: {
        email: email,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return axios(config);
  },
  /**
   * Sends a PUT request to remove admin privileges from a user with the given email.
   * @param {string} email - The email of the user to remove admin privileges from.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  removeAdmin: (email) => {
    var config = {
      method: 'put',
      url: 'user/remove-admin',
      params: {
        email: email,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return axios(config);
  },
  /**
   * Sends a PUT request to the server to mark the given site as a sample site.
   * @param {string} siteId - The ID of the site to mark as a sample site.
   * @returns A Promise that resolves with the response from the server.
   */
  makeSample: (siteId) => {
    return axios.put('user/sample-site/' + siteId);
  },
  /**
   * Sends a GET request to retrieve promotional data from the server.
   * @returns {Promise} A promise that resolves with the promotional data.
   */
  getPromotionalData: () => {
    return axios.get(commonUrl + 'admin-promo');
  },
  /**
   * Sends a PUT request to the server to update promotional data.
   * @param {Object} payload - The data to be updated.
   * @returns {Promise} A promise that resolves with the updated data if the request is successful, or rejects with an error message if the request fails.
   */
  updatePromotionalData: (payload) => {
    return axios.put(commonUrl + 'admin-promo', payload);
  },
  /**
   * Retrieves a list of invited users from the server.
   * @param {number} pageSize - The number of users to retrieve per page.
   * @param {number} pageNumber - The page number to retrieve.
   * @param {string} search - The search term to filter the users by.
   * @returns {Promise} A promise that resolves with the list of invited users.
   */
  getInvitedUsers: (pageSize, pageNumber, search) => {
    var config = {
      method: 'get',
      url: 'user/invited-users',
      params: {
        pageSize: pageSize,
        pageNum: pageNumber - 1,
        search: search,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return axios(config);
  },
  /**
   * Verifies the given signup token by sending a GET request to the server with the token in the Authorization header.
   * @param {string} token - The signup token to verify.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  verifySignupToken: (token) => {
    const headers = {
      Authorization: 'Bearer ' + token,
    };
    return axios.get(verifyUserToken, { headers });
  },

  /**
   * Verify a user's email address using a PIN code.
   * @param {string} otp - The PIN code to verify the user's email address.
   * @param {string} email - The email address of the user to verify.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  verifyUserByPin: (otp, email) => {
    const payload = {
      otp: otp,
      email: email,
    };
    return axios.post(newSignupUrl, payload);
  },
  /**
   * Retrieves the terms and conditions from the specified URL using an HTTP GET request.
   * @returns {Promise} A promise that resolves with the terms and conditions data.
   */
  getTermsAndConditions: () => {
    return axios.get(termsAndConditionsUrl);
  },
  /**
   * Sends a POST request to the server to indicate that the user has accepted the terms and conditions.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  acceptedTerms: () => {
    var config = {
      method: 'post',
      url: acceptedTermsUrl,
      params: {
        isTermsAccepted: true,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return axios(config);
  },
  /**
   * Sends a POST request to the server to check if a user is registered.
   * @param {Object} payload - The payload to send with the request.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  checkRegistered: (payload) => {
    return axios.post(checkUserRegistered, payload);
  },
  /**
   * Sends a GET request to the "/collection" endpoint to retrieve a list of collections.
   * @returns {Promise} A promise that resolves with the list of collections.
   */
  getCollectionList: () => {
    return axios.get('/collection');
  },
  /**
   * Sends a POST request to the "/collection" endpoint with the given payload to create a new collection.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A promise that resolves with the response data.
   */
  createCollection: (payload) => {
    return axios.post('/collection', payload);
  },
  /**
   * Sends a PUT request to the "/collection" endpoint with the given payload to add an item to the collection.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A promise that resolves with the response data if the request is successful, or rejects with an error if the request fails.
   */
  addToCollection: (payload) => {
    return axios.put('/collection', payload);
  },
  /**
   * Removes a site from a collection.
   * @param {string} siteId - The ID of the site to remove from the collection.
   * @param {string} collectonId - The ID of the collection to remove the site from.
   * @returns A promise that resolves with the result of the deletion.
   */
  removeFromCollection: (siteId, collectonId) => {
    return axios.delete('/collection/' + collectonId + '/site/' + siteId);
  },
  /**
   * Deletes a collection with the given ID.
   * @param {string} collectonId - The ID of the collection to delete.
   * @returns A promise that resolves with the result of the deletion.
   */
  deleteCollection: (collectonId) => {
    return axios.delete('/collection/' + collectonId);
  },
  /**
   * Deletes the reached collection with the given ID.
   * @param {number} collectonId - The ID of the collection to delete.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  deleteReachedCollection: (collectonId) => {
    return axios.delete('/collection/' + collectonId + '/reach-out');
  },
  /**
   * Sends a POST request to the server to initiate a reach-out campaign for a given collection.
   * @param {string} collectonId - The ID of the collection to initiate the reach-out campaign for.
   * @param {Object} payload - The payload to send with the POST request.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  reachOutCollection: (collectonId, payload) => {
    return axios.post('/collection/' + collectonId + '/reach-out', payload);
  },
  /**
   * Renames a collection with the given ID using the provided payload.
   * @param {string} collectonId - The ID of the collection to rename.
   * @param {Object} payload - The payload containing the new name for the collection.
   * @returns A Promise that resolves with the updated collection data.
   */
  renameCollection: (collectonId, payload) => {
    return axios.put('/collection/' + collectonId + '/update', payload);
  },
  /**
   * Sends a GET request to the "/collection/reached-out" endpoint to retrieve a list of reached out items.
   * @returns {Promise} A promise that resolves with the list of reached out items.
   */
  getReachedOutList: () => {
    return axios.get('/collection/reached-out');
  },
  /**
   * Sends a GET request to the manage users endpoint with the given parameters and token.
   * @param {Object} paramsObj - An object containing the parameters for the request.
   * @param {string} token - The token to use for authentication.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  manageUsers: async (paramsObj, token) => {
    let params = Object.keys(paramsObj);
    let finalUrl = manageUsersUrl + '?';
    params.forEach((param, index) => {
      finalUrl = finalUrl + param + '=' + paramsObj[param] + '&';
    });
    finalUrl = finalUrl.substring(0, finalUrl.length - 1);
    return await axios.get(finalUrl, { cancelToken: token });
  },

  /**
   * Sends a PUT request to the user action URL with the given payload.
   * @param {Object} payload - The data to send in the request body.
   * @returns {Promise} A promise that resolves with the response data.
   */
  userAction: (payload) => {
    return axios.put(userActionUrl, payload);
  },
};

export default SponsorService;
