import { Button, Result } from 'antd';

/**
 * A functional component that renders a 403 error page with a message indicating that the user is not authorized to access the page.
 * @returns A React component that displays a 403 error page with a button to return to the home page.
 */
const App = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
      <Button
        type="primary"
        onClick={() => {
          window.location.href = '/';
        }}
        className="signin-button w-200"
      >
        Back Home
      </Button>
    }
  />
);
export default App;
