import React from 'react';
import { Checkbox, Divider } from 'antd';

/**
 * A component that displays a table of filters and allows the user to select which filters to display.
 * @param {{boolean}} blockedCheck - A boolean indicating whether the "Blocked" checkbox is checked.
 * @param {{function}} setBlockedCheck - A function to set the value of the "Blocked" checkbox.
 * @param {{boolean}} unblockedCheck - A boolean indicating whether the "Unblocked" checkbox is checked.
 * @param {{function}} setUnblockedCheck - A function to set the value of the "Unblocked" checkbox.
 * @param {{function}} clearSelection - A function to clear the selection of checkboxes.
 * @returns A React component that displays a table of filters and allows the user to select which
 */
export default function FilterTable({
  blockedCheck,
  setBlockedCheck,
  unblockedCheck,
  setUnblockedCheck,
  clearSelection,
}) {
  return (
    <>
      <div className="sites-left-header">
        Status
        <p onClick={clearSelection}>clear</p>
      </div>
      <div className="sites-left-body">
        <Checkbox
          className={
            'sites-checkbox ' + (blockedCheck ? 'sites-checkbox-checked' : '')
          }
          checked={blockedCheck}
          onChange={(e) => {
            setBlockedCheck(e.target.checked);
          }}
        >
          Blocked
        </Checkbox>
        <Divider type="horizontal" className="horizontal-divider-class" />
        <Checkbox
          className={
            'sites-checkbox ' + (unblockedCheck ? 'sites-checkbox-checked' : '')
          }
          checked={unblockedCheck}
          onChange={(e) => {
            setUnblockedCheck(e.target.checked);
          }}
        >
          Unblocked
        </Checkbox>
        <Divider type="horizontal" className="horizontal-divider-class" />
      </div>
    </>
  );
}
