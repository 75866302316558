import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  notification,
  Skeleton,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faCheck,
  faExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons';
import SponsorService from '../services/sponsorService';
import { saveCollections } from '../features/collections';
import guestUserHand from '../../assets/images/guestUserHand.png';

/**
 * A modal component that allows users to save a site to their collection. The modal
 * displays a list of existing collections and allows users to add the site to an
 * existing collection or create a new one. If the user is not logged in, they are
 * prompted to sign in or schedule a demo.
 * @param {{site: Site, addText: string}} props - The site object and the text to display
 * for the "add to collection" button.
 * @returns A modal component that allows users to save a site to their collection.
 */
const CollectionModal = ({ site, addText }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRemove, setIsModalOpenRemove] = useState(false);
  const [isModalOpenNew, setIsModalOpenNew] = useState(false);
  const addedToList = false;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const collectionsList = useSelector((state) => state.collections);
  const [loading, setLoading] = useState(false);
  const [guestOpen, setGuestOpen] = useState(false);
  const loadingCollections = false;
  const dispatch = useDispatch();

  /**
   * Shows a modal window if the user is logged in, otherwise shows a guest window.
   */
  const showModal = () => {
    if (isLoggedIn) {
      setIsModalOpen(true);
    } else {
      setGuestOpen(true);
    }
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModalRemove = () => {
    setIsModalOpenRemove(true);
  };
  const handleOkRemove = () => {
    setIsModalOpenRemove(false);
  };
  const handleCancelRemove = () => {
    setIsModalOpenRemove(false);
  };
  const showModalNew = () => {
    setIsModalOpenNew(true);
  };
  const handleOkNew = () => {
    setIsModalOpenNew(false);
    showModal();
  };
  const handleCancelNew = () => {
    setIsModalOpenNew(false);
    showModal();
  };
  const newCollection = () => {
    handleCancel();
    showModalNew();
  };

  /**
   * Checks if a given site is present in a list of sites.
   * @param {{list}} list - The list of sites to check.
   * @returns {boolean} - True if the site is present in the list, false otherwise.
   */
  const checkPresent = (list) => {
    let present = false;
    if (!list.sites) {
      return false;
    }
    list.sites.map((el) => {
      if (el.siteId === site.siteId) {
        present = true;
      }
    });
    return present;
  };

  /**
   * Saves a new collection with the given values and adds the current site to it.
   * @param {{Object}} values - The values of the new collection to be created.
   * @returns None
   */
  const saveNewCollection = (values) => {
    setLoading(true);
    SponsorService.createCollection(values)
      .then((res) => {
        let tempList = [];
        collectionsList.map((el) => {
          tempList.push(el);
        });
        tempList.push(res.data.data);
        dispatch(saveCollections(tempList));
        let payload = {
          siteId: site.siteId,
          collectionId: res.data.data.collectionId,
        };
        SponsorService.addToCollection(payload)
          .then((res2) => {
            dispatch(saveCollections(res2.data.data));
            setLoading(false);
            handleOkNew();
            notification.success({
              message: 'Successfully Site added to ' + values.collectionName,
            });
            form.resetFields();
          })
          .catch(() => {
            notification.error({
              message: 'Adding to new collection failed !! Please try again...',
            });
            setLoading(false);
            handleCancelNew();
            form.resetFields();
          });
      })
      .catch((err) => {
        notification.error({
          message: err.response.data.error.message,
        });
        setLoading(false);
      });
  };

  /**
   * Adds a given list to the user's collection.
   * @param {{list}} list - The list to add to the collection.
   * @returns None
   */
  const addToCollection = (list) => {
    setLoading(list.collectionId);
    let payload = {
      siteId: site.siteId,
      collectionId: list.collectionId,
    };
    SponsorService.addToCollection(payload)
      .then((res) => {
        dispatch(saveCollections(res.data.data));
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: 'Adding to collection failed !! Please try again...',
        });
        setLoading(false);
      });
  };

  /**
   * Removes a collection from the list of collections for a given site.
   * @param {{list}} list - The collection to remove.
   * @returns None
   */
  const removeFromCollection = (list) => {
    setLoading(list.collectionId);
    SponsorService.removeFromCollection(site.siteId, list.collectionId)
      .then((res) => {
        dispatch(saveCollections(res.data.data));
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: 'Removing from collection failed !! Please try again...',
        });
        setLoading(false);
      });
  };

  /**
   * Runs the checkLoggedIn function from the SponsorService module when the component mounts.
   * If the user is logged in, sets the isLoggedIn state to true, otherwise sets it to false.
   */
  useEffect(() => {
    if (SponsorService.checkLoggedIn()) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  return (
    <>
      <Modal
        open={guestOpen}
        footer={null}
        onCancel={() => {
          setGuestOpen(false);
        }}
        style={{
          top: 40,
        }}
      >
        <div className="demo-warning-modal">
          <h3>This is an Exclusive Feature for Signed-In Users</h3>
          <img src={guestUserHand} alt="scheduleademoAlert" />
          <p>
            Thank you for your interest in our exclusive content. Our goal is to
            connect you with our sites to help you achieve your goals. To
            explore the full benefits of our platform, we require a demo to be
            scheduled. Our team will be happy to show you around and answer any
            questions you may have.
          </p>
          <Button
            className="schedule-demo-button"
            onClick={() => {
              setGuestOpen(false);
              window.open(process.env.REACT_APP_SCHEDULE_A_DEMO_URL, '_blank');
            }}
          >
            GET ACCESS
          </Button>
          <Button
            type="text"
            className="schedule-demo-login-button"
            onClick={() => {
              setGuestOpen(false);
              window.location.href = '/login';
            }}
          >
            Already a user? Sign in
          </Button>
        </div>
      </Modal>
      {addedToList ? (
        <div onClick={showModalRemove}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      ) : (
        <div onClick={showModal}>
          <Space className="site-page-header-right-add">
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
            {addText ? addText : null}
          </Space>
        </div>
      )}
      <Modal
        title={
          <div className="remove-collection-modal-header">
            <div className="remove-collection-modal-header-icon">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </div>
            <div>
              <div className="remove-collection-modal-header-text">
                Remove {site.siteName} from the list?
              </div>
              <div className="remove-collection-modal-body">
                Are you sure you want to remove this site from the list?
              </div>
              <div className="remove-collection-modal-footer">
                <Space size={20}>
                  <Button
                    size="large"
                    className="remove-collection-modal-footer-cancel"
                    onClick={handleOkRemove}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="text"
                    onClick={handleCancelRemove}
                    className="remove-collection-modal-footer-remove"
                  >
                    Remove
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        }
        centered
        open={isModalOpenRemove}
        onOk={handleOkRemove}
        onCancel={handleCancelRemove}
        className="collection-modal"
        footer={false}
      />
      <Modal
        title={<div className="collection-modal-header">Save to My List</div>}
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        className="collection-modal"
        footer={[
          <Button
            key="addNewCollection"
            type="primary"
            size="large"
            onClick={newCollection}
            className="collection-modal-footer-button"
          >
            Save to a New Collection
          </Button>,
        ]}
      >
        <div className="collection-modal-body">
          <div className="collection-modal-body-header">Existing Lists</div>
          {loadingCollections ? (
            <>
              <Skeleton active style={{ padding: '15px' }} />
            </>
          ) : collectionsList.length === 0 ? (
            <div
              className="search-site-card-descrition"
              style={{ margin: '0 0 20px 15px' }}
            >
              No List present.. Click on <b>Save to a New Collection</b>
            </div>
          ) : (
            collectionsList.map((list) => {
              return (
                <div
                  className="collection-modal-body-list"
                  key={list.collectionId}
                >
                  <div className="collection-modal-body-list-left">
                    <div className="collection-modal-body-list-left-img">
                      {list.collectionLogo ? (
                        <img src={list.collectionLogo} alt="Collection IMG" />
                      ) : (
                        <Skeleton.Image
                          style={{ height: '100%', width: '100%' }}
                        />
                      )}
                    </div>
                    <div className="collection-modal-body-list-left-text">
                      <a>{list.collectionName}</a>
                      <p>
                        {list.countOfSites}{' '}
                        {list.countOfSites > 1 ? ' Sites' : ' Site'}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="collection-modal-body-list-right">
                    {checkPresent(list) ? (
                      <Button
                        type="primary"
                        className="collection-modal-body-list-right-button-cancel"
                        loading={loading === list.collectionId ? true : false}
                        onClick={() => {
                          removeFromCollection(list);
                        }}
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className="collection-modal-body-list-right-button"
                        onClick={() => {
                          addToCollection(list);
                        }}
                        loading={loading === list.collectionId ? true : false}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Modal>
      <Modal
        title={<div className="collection-modal-header">New Collection</div>}
        open={isModalOpenNew}
        onOk={handleOkNew}
        onCancel={handleCancelNew}
        className="collection-modal"
        centered
        footer={null}
        destroyOnClose
      >
        <Form form={form} onFinish={saveNewCollection} preserve={false}>
          <div className="new-collection-modal-body">
            <div className="collection-modal-body-list-left-text new-collection-modal-body-label">
              <a>Collection Name</a>
            </div>
            <Form.Item
              name={'collectionName'}
              rules={[
                {
                  required: true,
                  message: 'Please Enter collection name',
                },
              ]}
            >
              <Input
                className="signin-input"
                placeholder="Enter Collection Name"
              />
            </Form.Item>
          </div>
          <div className="new-collection-modal-footer">
            <Form.Item>
              <Space size={20}>
                <Button
                  type="primary"
                  size="large"
                  className="new-collection-modal-footer-save"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
                <Button
                  size="large"
                  onClick={handleCancelNew}
                  className="new-collection-modal-footer-cancel"
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default CollectionModal;
