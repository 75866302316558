import React, { useRef, useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import {
  Card,
  Carousel,
  Divider,
  Input,
  Form,
  Button,
  notification,
  Checkbox,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../../assets/images/CRIO_logo.png';
import SponsorService from '../../services/sponsorService';

/**
 * A component that renders a login form with various steps for logging in, resetting password, and accepting terms and conditions.
 * @returns A React component that renders a login form.
 */
const LoginForm = () => {
  const loginRef = useRef();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [forgetLoading, setForgetLoading] = useState(false);
  const [, setPassword] = useState();
  const [forgetEmail, setForgetEmail] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordForm] = Form.useForm();
  const [loginFormEmail] = Form.useForm();
  const [loginFormPassword] = Form.useForm();
  const [loginFormForgetEmail] = Form.useForm();
  const [, setProfilePic] = useState();
  const [OTP, setOTP] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [resetLoading, setResetLoading] = useState(false);
  const [terms, setTerms] = useState('');
  const [termsLoading, setTermsLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  /**
   * Retrieves the profile picture of a user with the given email address from the SponsorService API.
   * @param {{Object}} values - An object containing the email address of the user.
   */
  const getProfilePic = (values) => {
    setLoading(true);
    SponsorService.getProfilePic(values.email)
      .then((res) => {
        setLoading(false);
        setProfilePic(res.data.data.profilePictureUrl);
        loginFormPassword.setFields([{ name: 'email', value: email }]);
        loginRef.current.next();
      })
      .catch(() => {
        setLoading(false);
        loginFormEmail.setFields([
          {
            name: 'email',
            errors: ['Email is not registered.. Please register...'],
          },
        ]);
      });
  };

  /**
   * Logs in the user with the given email and password.
   * @param {{Object}} e - An object containing the user's email and password.
   */
  const login = (e) => {
    setLoading(true);
    SponsorService.getProfilePic(e.email)
      .then(() => {
        SponsorService.login(e.email, e.password)
          .then((res) => {
            setLoading(false);
            localStorage.setItem('SDIR_SPONSOR_TOKEN', res.data.data.token);
            if (res.data.data.user.isTermsConditionAccepted !== 1) {
              setShowTerms(true);
              loginRef.current.next();
            } else {
              window.location.href = '/';
            }
          })
          .catch((error) => {
            setLoading(false);
            loginFormPassword.setFields([
              {
                name: 'password',
                errors: [error.response.data.error.message],
              },
            ]);
          });
      })
      .catch(() => {
        setLoading(false);
        loginFormPassword.setFields([
          {
            name: 'email',
            errors: ['Email is not registered.. Please register...'],
          },
        ]);
      });
  };

  /**
   * Sends a request to the server to reset the user's password.
   * @param {{values}} values - An object containing the user's email address.
   */
  const forgetPassword = (values) => {
    setForgetLoading(true);
    SponsorService.forgotPassword(values.email)
      .then(() => {
        setForgetLoading(false);
        loginRef.current.next();
      })
      .catch((error) => {
        setForgetLoading(false);
        loginFormForgetEmail.setFields([
          {
            name: 'email',
            errors: [error.response.data.error.message],
          },
        ]);
      });
  };
  // Sends another forget password email to the user's email address.
  const sendAnotherForgetEmail = () => {
    setResetLoading(true);
    SponsorService.forgotPassword(forgetEmail)
      .then(() => {
        setResetLoading(false);
        notification.success({
          message: 'Successfully sent verification code !!',
        });
      })
      .catch(() => {
        setResetLoading(false);
        notification.error({
          message:
            'Error while resending reset email. Please try again later..',
          /**
           * Verifies the user by the given OTP and email using SponsorService.verifyUserByPin.
           * If the verification is successful, the user's token is stored in local storage and the loginRef is advanced.
           * If the verification fails, an error message is set.
           * @param {{string}} OTP - the OTP to verify the user with
           * @param {{string}} email - the email of the user to verify
           * @returns None
           */
        });
      });
  };

  /**
   * Verifies the user's OTP by making a request to the SponsorService API.
   * @param {{string}} OTP - The OTP entered by the user.
   * @param {{string}} email - The email address of the user.
   */
  const otpVerification = (OTP, email) => {
    setResetLoading(true);
    SponsorService.verifyUserByPin(OTP, email)
      .then((response) => {
        setResetLoading(false);
        localStorage.setItem('SDIR_SPONSOR_TOKEN', response.data.data.token);
        setOTP(null);
        loginRef.current.next();
      })
      .catch(() => {
        setResetLoading(false);
        setErrorMsg('Invalid PIN! Please enter correct PIN...');
      });
  };

  /**
   * Updates the OTP state and clears any error messages.
   * @param {{string}} OTP - The new OTP value to set.
   * @returns None
   */
  const otpChange = (OTP) => {
    setErrorMsg('');
    setOTP(OTP);
  };

  /**
   * Creates a new password for the sponsor user and logs them out.
   * @returns None
   */
  const createPassword = () => {
    setForgetLoading(true);
    let payload = {
      password: newPassword,
    };
    SponsorService.createPassword(payload)
      .then(() => {
        setForgetLoading(false);
        localStorage.removeItem('SDIR_SPONSOR_TOKEN');
        newPasswordForm.resetFields();
        loginRef.current.next();
      })
      .catch(() => {
        setForgetLoading(false);
        notification.error({
          message: 'Something went wrong, please try again later...',
        });
      });
  };

  /**
   * Accepts the terms and conditions for the sponsor service and redirects the user to the home page.
   * @returns None
   */
  const acceptTerms = () => {
    setTermsLoading(true);
    SponsorService.acceptedTerms()
      .then(() => {
        window.location.href = '/';
      })
      .catch(() => {
        setTermsLoading(false);
        notification.error({
          message: 'Failed to Accept Terms and Conditions..',
        });
      });
  };

  /**
   * useEffect hook that retrieves the terms and conditions from the SponsorService API and sets the state with the response data.
   * @returns None
   */
  useEffect(() => {
    SponsorService.getTermsAndConditions()
      .then((res) => {
        setTerms(res.data.data.value);
      })
      .catch(() => {
        notification.error({
          message: 'Failed to get Terms and Conditions..',
        });
      });
  }, []);

  return (
    <Carousel
      dots={false}
      key={'loginCor'}
      ref={loginRef}
      accessibility={false}
      swipe={false}
      swipeToSlide={false}
      draggable={false}
      infinite={false}
    >
      <div className="card-wrapper">
        <Card className="login-page-card">
          <div className="signin">
            <div className="sign-in-logo-text">
              <img src={Logo} alt="Crio Logo" />
              Sign In
            </div>
            <div className="signin-form">
              <Form
                onFinish={getProfilePic}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                form={loginFormEmail}
                layout="vertical"
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email address',
                    },
                    {
                      type: 'email',
                      message:
                        'Invalid email address. Only use numbers, letters, or + - _ characters',
                    },
                  ]}
                >
                  <Input className="signin-input" id="email-1" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="signin-button"
                    loading={loading}
                  >
                    CONTINUE
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Divider style={{ margin: '4px 0 10px 0' }} />
            <a
              onClick={() => {
                window.open(
                  'https://www.clinicalresearch.io/schedule-a-demo/',
                  '_blank',
                );
              }}
              className={'create-button'}
            >
              Request to become a CRIO Connect member
            </a>
          </div>
        </Card>
      </div>
      <div className="card-wrapper">
        <Card className="login-page-card">
          <div className="signin">
            <div className="sign-in-logo-text">
              <img src={Logo} alt="Crio Logo" />
              Sign In
            </div>
            <div className="signin-form">
              <Form
                onFinish={login}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                form={loginFormPassword}
                layout="vertical"
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email address',
                    },
                    {
                      type: 'email',
                      message:
                        'Invalid email address. Only use numbers, letters, or + - _ characters',
                    },
                  ]}
                >
                  <Input className="signin-input" id="email-2" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password',
                    },
                  ]}
                >
                  <Input.Password className="signin-input" id="password-1" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="signin-button"
                    loading={loading}
                  >
                    LOGIN
                  </Button>
                </Form.Item>
              </Form>
              <div className="forget-password-link">
                Having Trouble?{' '}
                <a
                  onClick={() => {
                    loginFormEmail.resetFields();
                    loginRef.current.next();
                  }}
                >
                  Forgot Password
                </a>
              </div>
            </div>
          </div>
        </Card>
      </div>
      {showTerms ? (
        <div className="card-wrapper">
          <Card className="login-page-card">
            <div className="signin">
              <div className="sign-in-logo-text">
                <img src={Logo} alt="Crio Logo" />
                Review Terms & Conditions
              </div>

              <div
                className="agreement-body-text signin-form"
                dangerouslySetInnerHTML={{ __html: terms }}
              ></div>
              <div className="signin-form checkbox-div">
                <Checkbox
                  onChange={(e) => {
                    setAcceptedTerms(e.target.checked);
                  }}
                  className="terms-checkbox check-options"
                >
                  I accept the terms and conditions of service
                </Checkbox>
              </div>
              <div className="signin-form">
                <Button
                  type="primary"
                  style={{ marginTop: '0' }}
                  className="signin-button"
                  loading={termsLoading}
                  disabled={!acceptedTerms}
                  onClick={() => {
                    acceptTerms();
                  }}
                >
                  CONTINUE
                </Button>
              </div>
            </div>
          </Card>
        </div>
      ) : null}
      <div className="card-wrapper">
        <Card className="login-page-card">
          <div className="signin">
            <div className="sign-in-logo-text">
              <img src={Logo} alt="Crio Logo" />
              Find Password
            </div>
            <div className="signin-form">
              <Form
                onFinish={forgetPassword}
                onChange={(e) => {
                  setForgetEmail(e.target.value);
                }}
                form={loginFormForgetEmail}
                layout="vertical"
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your email address',
                    },
                    {
                      type: 'email',
                      message: 'Not a valid email address',
                    },
                  ]}
                >
                  <Input className="signin-input" id="email-3" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="signin-button"
                    loading={forgetLoading}
                  >
                    SEND VERIFICATION PIN
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div
              className="change-email"
              onClick={() => {
                loginRef.current.goTo(0);
                loginFormForgetEmail.resetFields();
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ marginRight: '10px' }}
              />
              Back to Sign in
            </div>
          </div>
        </Card>
      </div>
      <div className="card-wrapper">
        <Card className="login-page-card">
          <div className="signin">
            <div className="sign-in-logo-text">
              <img src={Logo} alt="Crio Logo" />
              Security Verification
            </div>
            <div
              className="login-card-text"
              style={{ textAlign: 'center', width: '80%' }}
            >
              We have sent a verification code to {forgetEmail}. Please retrieve
              and enter it below.
            </div>
            <OtpInput
              onChange={otpChange}
              value={OTP}
              inputStyle={errorMsg !== '' ? 'otp-input error-otp' : 'otp-input'}
              renderInput={(props) => <input {...props} />}
              numInputs={5}
              renderSeparator={<span className="otp-input-span"></span>}
            />
            <span className={'error-otp-span'}>{errorMsg}</span>
            <a
              onClick={() => {
                sendAnotherForgetEmail();
              }}
            >
              Didn’t Receive the code? Resend Code
            </a>
            <div className="signin-form">
              <Button
                type="primary"
                onClick={() => {
                  if (OTP.length < 5) {
                    setErrorMsg('Please enter correct PIN...');
                  } else {
                    otpVerification(OTP, forgetEmail);
                  }
                }}
                style={{ marginTop: '0' }}
                className="signin-button"
                loading={resetLoading}
              >
                CONTINUE
              </Button>
            </div>
            <div
              className="change-email"
              onClick={() => {
                setOTP(null);
                loginRef.current.prev();
              }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ marginRight: '10px' }}
              />
              Back
            </div>
          </div>
        </Card>
      </div>
      <div className="card-wrapper">
        <Card className="login-page-card">
          <div className="signin">
            <div className="sign-in-logo-text">
              <img src={Logo} alt="Crio Logo" />
              Enter New Password
            </div>
            <div className="signin-form">
              <Form
                onFinish={createPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                form={newPasswordForm}
                layout="vertical"
                requiredMark={false}
              >
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message:
                        'Password must be at least 8 characters with 1 upper case letter, 1 number and special character',
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,20}$/,
                      message:
                        'Password must be at least 8 characters with 1 upper case letter, 1 number and special character',
                    },
                  ]}
                >
                  <Input.Password className="signin-input" id="password-2" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="signin-button"
                    loading={forgetLoading}
                  >
                    CONTINUE
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Card>
      </div>
      <div className="card-wrapper">
        <Card className="login-page-card">
          <div className="signin">
            <div className="sign-in-logo-text">
              <img src={Logo} alt="Crio Logo" />
              Password changed!
            </div>
            <div
              className="login-card-text"
              style={{ textAlign: 'center', width: '80%' }}
            >
              You can now sign in using your new password
            </div>
            <div className="signin-form">
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = '/login';
                }}
                style={{ marginTop: '0' }}
                className="signin-button"
              >
                BACK TO SIGN IN
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </Carousel>
  );
};

export default LoginForm;
