import { createSlice } from '@reduxjs/toolkit';

// A Redux slice that manages the state of the therapeutic area.
export const therapeuticSlice = createSlice({
  name: 'therapeuticArea',
  initialState: [],
  reducers: {
    saveTherapeuticArea: (state, action) => {
      return action.payload;
    },
    removeTherapeuticArea: (state, action) => {
      return [];
    },
  },
});

export const { saveTherapeuticArea, removeTherapeuticArea } =
  therapeuticSlice.actions;
export default therapeuticSlice.reducer;
