const util = {
  /**
   * Takes in a list of elements and concatenates them into a single string with commas
   * separating each element.
   * @param {Array} list - the list of elements to concatenate
   * @returns {string} - the concatenated string
   */
  getTitle(list) {
    let title = '';
    list.forEach((element) => {
      if (list.indexOf(element) === 0) {
        title = title + element;
      } else {
        title = title + ', ' + element;
      }
    });
    return title;
  },
  /**
   * Checks if a site with the given siteId has been added to the given collection.
   * @param {Array} collection - The collection to check for the site.
   * @param {string} siteId - The ID of the site to check for.
   * @returns {boolean} - True if the site is in the collection, false otherwise.
   */
  checkAddedToCollection(collection, siteId) {
    if (collection.length < 1) {
      return false;
    }
    let res = false;
    collection.map((col) => {
      col.sites.length > 0 &&
        col.sites.map((site) => {
          if (site.siteId.toString() === siteId.toString()) {
            res = true;
          }
        });
    });
    return res;
  },
  /**
   * Searches for a site in the given array of sites with the matching ID and returns its data.
   * @param {number} id - the ID of the site to search for
   * @param {Array} sites - the array of site objects to search through
   * @returns The site object with the matching ID, or undefined if not found.
   */
  getSiteData(id, sites) {
    let siteData = undefined;
    sites.map((site) => {
      if (site.id.toString() === id.toString()) {
        siteData = site;
      }
    });
    return siteData;
  },
  /**
   * Validates an email address against a list of restricted domains.
   * @param {string} email - The email address to validate.
   * @param {string[]} restrictedEmails - An array of restricted domains.
   * @returns {boolean} - True if the email is valid, false otherwise.
   */
  validateEmail(email, restrictedEmails) {
    let domain = email.split('@')[1];
    if (domain) {
      return restrictedEmails.includes(domain) ? false : true;
    }
    return false;
  },
  /**
   * Generates a search payload object based on the selected areas and facilities.
   * @param {Array} areas - An array of therapeutic areas.
   * @param {Array} facility - An array of facilities and equipment.
   * @param {Array} selectedAreas - An array of selected therapeutic areas.
   * @param {Array} selectedFacility - An array of selected facilities and equipment.
   * @returns {Object} - A search payload object containing the selected therapeutic areas and facilities.
   */
  getSearchPayload(areas, facility, selectedAreas, selectedFacility) {
    let payload = {
      facilityList: [],
      therapeuticList: [],
    };
    areas.forEach((area) => {
      if (selectedAreas.includes(area.therapeuticName)) {
        payload.therapeuticList.push(area.therapeuticId);
      }
    });
    facility.forEach((fa) => {
      if (selectedFacility.includes(fa.facilityAndEquipmentName)) {
        payload.facilityList.push(fa.facilityAndEquipmentId);
      }
    });
    return payload;
  },
  getAreaIdList(areas, selectedAreas) {
    let payload = {
      therapeuticIdList: [],
    };
    selectedAreas &&
      selectedAreas.length !== 0 &&
      areas.forEach((area) => {
        if (selectedAreas.includes(area.therapeuticName)) {
          payload.therapeuticIdList.push(area.therapeuticId);
        }
      });
    return payload;
  },
};

export default util;
