import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  Input,
  notification,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { noOfRowsDropDownData } from '../../../constants/dumps';
import SponsorService from '../../services/sponsorService';
import { Timer } from '../../components/Timer';

// Renders a table of invitation data with pagination and search functionality.
function InvitationList({
  pageNo,
  setPageNo,
  pageSize,
  setPageSize,
  setSearchSiteName,
  searchSiteName,
  tableData,
  loading,
  userCount,
  updateTable,
}) {
  const [resendLoading, setResendLoading] = useState(false);

  /**
   * Handles the search functionality by updating the search site name state based on the user's input.
   * @param {{Event}} e - The event object that triggered the search.
   */
  const onSearch = (e) => {
    const getSearchValue = e.target.value;
    if (getSearchValue.length < 0) {
      setSearchSiteName('');
    } else {
      setSearchSiteName(getSearchValue);
    }
  };

  /**
   * Updates the current page number and calls the function to update the table with the new page number.
   * @param {{number}} page - The new page number to set.
   */
  const onPageChange = (page) => {
    setPageNo(page);
    updateTable(pageSize, page, searchSiteName);
  };
  /**
   * Resends an invitation to the specified email address and updates the table on success.
   * @param {{string}} email - The email address to resend the invitation to.
   */
  const resendInvite = (email) => {
    setResendLoading(email);
    SponsorService.resendInviteUser(email)
      .then(async () => {
        notification.success({
          message: 'Invitation resent successfully to ' + email,
        });
        await updateTable(pageSize, pageNo, searchSiteName);
        setResendLoading(false);
      })
      .catch((err) => {
        notification.error({ message: err.response.data.error.message });
        setResendLoading(false);
      });
  };

  /**
   * An array of objects that define the columns for a table. Each object represents a single column
   * and contains the following properties:
   * @param {{string}} title - The title of the column.
   * @param {{string}} width - The width of the column.
   * @param {{function}} render - A function that returns the content to be displayed in the column.
   * @param {{string}} dataIndex - The key of the data in the record object to be displayed in the column.
   * @returns An array of objects that define the columns for a table.
   */
  let columns = [
    {
      title: 'User',
      width: '25%',

      render: (__, record) => {
        return (
          <div key={record.firstName} className={'table_site_name pop-reg-666'}>
            {(record.firstName ? record.firstName : '') +
              ' ' +
              (record.lastName ? record.lastName : '')}
          </div>
        );
      },
    },
    {
      title: 'Email',
      width: '35%',
      dataIndex: 'email',
      render: (email) => (
        <div key={email} className="table_site_name pop-reg-666 email">
          {email}
        </div>
      ),
    },
    {
      title: 'Invitation Valid',
      width: '20%',
      render: (__, record) => {
        let lastInvited = record.lastInvited
          ? new Date(record.lastInvited)
          : null;
        let displayDate = new Date(
          lastInvited.getTime() - lastInvited.getTimezoneOffset() * 60 * 1000,
        );
        if (record.lastInvited) {
          displayDate.setDate(displayDate.getDate() + 1);
        }
        let formattedDate = displayDate
          ? displayDate.toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true,
            })
          : null;
        return record.invitationExpired ? (
          <Tooltip title="Invitation expired !!">
            <div
              key={record.lastInvited}
              className="table_site_name pop-reg-666"
              style={{ color: 'red' }}
            >
              {formattedDate}
            </div>
          </Tooltip>
        ) : (
          <div key={record.lastInvited} className="table_site_name pop-reg-666">
            {formattedDate}
          </div>
        );
      },
    },
    {
      title: 'Action',
      width: '20%',

      render: (__, record) => {
        let displayDate = record.lastInvited
          ? new Date(record.lastInvited)
          : null;
        if (record.lastInvited) {
          displayDate.setMinutes(displayDate.getMinutes() + 5);
        }
        return (
          <div key={record.email}>
            <div
              className="table_site_name pop-reg-666"
              key={record.sponsorUserId}
            >
              <Timer
                deadline={displayDate}
                record={record}
                resendLoading={resendLoading}
                resendInvite={resendInvite}
              />
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    updateTable(pageSize, pageNo, searchSiteName);
  }, []);
  /**
   * Renders the header of a table component with a search bar and dropdown for selecting the number of rows to display.
   * @returns A JSX element representing the table header.
   */
  const tableHeader = () => {
    return (
      <>
        <div className="title-container">
          <div className="table-header-wrapper">
            <div className="table-header-wrapper-title">
              <Space>
                <p>Show</p>
                <div className="table-header-wrapper-title-dropdown">
                  <Select
                    defaultValue="5"
                    onChange={(value) => {
                      setPageSize(value);
                      updateTable(value, pageNo, searchSiteName);
                    }}
                    options={noOfRowsDropDownData}
                    style={{ width: '100%' }}
                    className="crio-input"
                  />
                </div>
                <p>Entries</p>
              </Space>
            </div>
            <div className="table-header-wrapper-search">
              <Input
                className="crio-input"
                placeholder="Search by User"
                size="large"
                suffix={
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="dashboard-search-icon"
                    onClick={() => {
                      updateTable(pageSize, pageNo, searchSiteName);
                    }}
                  />
                }
                onChange={(e) => onSearch(e)}
                onPressEnter={() => {
                  updateTable(pageSize, pageNo, searchSiteName);
                }}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <Divider type="horizontal" className="horizontal-divider-class" />
        </div>
        <div className="table-header-column">
          <div className="col-title" style={{ width: '25%' }}>
            {columns[0].title}
          </div>
          <div className="col-title" style={{ width: '35%' }}>
            {columns[1].title}
          </div>
          <div className="col-title" style={{ width: '20%' }}>
            {columns[2].title}
          </div>
          <div className="col-title" style={{ width: '20%' }}>
            {columns[3].title}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="invitation-list-wrapper">
      <Table
        columns={columns}
        dataSource={tableData}
        title={tableHeader}
        className={'ant-side-table'}
        showHeader={false}
        loading={loading}
        pagination={{
          pageSize: pageSize,
          total: userCount,
          current: pageNo,
          onChange: (page) => {
            onPageChange(page);
          },
        }}
      />
    </div>
  );
}

export default InvitationList;
