import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Space, Spin, Skeleton, Result, Button, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import StudiesTable from '../../components/StudiesTable';
import AboutSites from '../../components/AboutSites';
import SiteFlagReport from '../../components/SiteFlagReport';
import SitePageService from '../../services/sitePageService';
import CollectionModal from '../../components/CollectionModal';

/**
 * A functional component that renders the SitePage. It retrieves the site data, studies, images, and PI information
 * from the SitePageService and displays them in a table format. It also allows users to add the site to a collection
 * and report a flag. If the site is not available, it displays a 404 error message.
 * @returns A JSX element that displays the SitePage.
 */
const SitePage = () => {
  let { id } = useParams();
  let { pId } = useParams();
  const history = useHistory();
  const [site, setSite] = useState();
  const [studies, setStudies] = useState();
  const [studyCount, setStudyCount] = useState(0);
  const [siteImages, setSiteImages] = useState([]);
  const [siteAvailable, setSiteAvailable] = useState(true);
  const [pi, setPi] = useState([]);

  // useEffect hook that fetches data for a site page and sets the state of the component.
  useEffect(() => {
    // let visitedSites = [];
    // if (localStorage.getItem("visitedSites")) {
    //   visitedSites = localStorage.getItem("visitedSites").split(",");
    // }
    // if (
    //   SponsorService.checkLoggedIn() ||
    //   visitedSites.includes(id.toString())
    // ) {
    // } else {
    //   if (visitedSites.length > 2) {
    //     history.push("/login");
    //   } else {
    //     visitedSites.push(id);
    //     localStorage.setItem("visitedSites", visitedSites);
    //   }
    // }
    if (id) {
      SitePageService.getSiteData(id)
        .then((res) => {
          let temp = Object.assign({}, res.data.data);
          temp.siteId = id * 1;
          setSite(temp);
        })
        .catch(() => {
          setSiteAvailable(false);
        });
      SitePageService.getStudies(id).then((res) => {
        setStudies(res.data.data);
        setStudyCount(res.data.data.length);
      });
      SitePageService.getImages(id)
        .then((res) => {
          setSiteImages(res.data.data);
        })
        .catch(() => {
          setSiteImages(false);
        });
      SitePageService.getSinglePi(id, pId)
        .then((res) => {
          setPi(res.data.data);
        })
        .catch(() => {
          setPi(false);
        });
    }
    window.scrollTo(0, 0);
  }, []);
  return site ? (
    <div className="body-container">
      <div className="site-page-container">
        <div
          className="site-page-back"
          onClick={() => {
            if (pId) {
              history.goBack();
            } else {
              window.location.href = '/search';
            }
          }}>
          <span>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          Back to Main
        </div>
        <Row className="site-page-header" gutter={24}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={6}
            xl={4}
            className={'site-page-header-left'}>
            <div className="site-page-header-left-logo">
              {site && site.siteLogo ? (
                <img src={site.siteLogo} alt="Site Logo" />
              ) : (
                <Skeleton.Image className="site-skaleton-img" />
              )}
            </div>
          </Col>
          <Col
            xs={16}
            sm={16}
            md={12}
            lg={12}
            xl={14}
            className={'site-page-header-left'}>
            <div className="site-page-header-left-text">
              <Space size={18} className="site-page-header-left-text-name">
                <FontAwesomeIcon icon={faBuilding} />
                {site && site.siteName ? site.siteName : <Skeleton.Input />}
              </Space>
              <div className="site-page-header-left-text-org-name">
                {site && site.orgName ? site.orgName : <Skeleton.Input />}
              </div>
            </div>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={12}
            lg={6}
            xl={6}
            style={{ padding: '12px' }}
            className="sitepage-extra-button-with-text">
            <Space className="site-page-header-right">
              <CollectionModal site={site} addText="Add to List" />
              <div>
                <Divider
                  type="vertical"
                  style={{
                    borderInlineStart: '1.8px solid #cccccc',
                    height: '1.5em',
                  }}
                />
              </div>

              <SiteFlagReport id={id} />
            </Space>
          </Col>
          <Col
            xs={8}
            sm={8}
            md={0}
            lg={0}
            xl={0}
            style={{ padding: '12px' }}
            className="sitepage-extra-button-without-text">
            <Space className="site-page-header-right">
              <CollectionModal site={site} />
              <div>
                <Divider
                  type="vertical"
                  style={{
                    borderInlineStart: '1.8px solid #cccccc',
                    height: '1.5em',
                  }}
                />
              </div>

              <SiteFlagReport id={id} />
            </Space>
          </Col>
        </Row>
        <div className="site-page-body">
          <span>
            Studies<p>{studyCount}</p>
          </span>
          {studyCount === 0 ? null : studies ? (
            <StudiesTable studies={studies} />
          ) : (
            <Skeleton active />
          )}

          <span>About the Site</span>
          <AboutSites pi={pi} site={site} siteImages={siteImages} />
        </div>
      </div>
    </div>
  ) : siteAvailable ? (
    <Spin tip="Loading" size="large">
      <div className="site-page-loading-container" />
    </Spin>
  ) : (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => {
            history.goBack();
          }}>
          Go Back
        </Button>
      }
    />
  );
};

export default SitePage;
