import { createSlice } from '@reduxjs/toolkit';

// A Redux slice that manages the user state.
export const userSlice = createSlice({
  name: 'user',
  initialState: [],
  reducers: {
    saveUser: (state, action) => {
      return action.payload;
    },
    removeUser: (state, action) => {
      return [];
    },
  },
});

export const { saveUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
