import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  Button,
  Divider,
  Input,
  notification,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { faSearch, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { noOfRowsDropDownData } from '../../../constants/dumps';
import ActionModal from './ActionModal';
import SponsorService from '../../services/sponsorService';

/**
 * A component that displays a table of users with various actions that can be taken on each user.
 * @param {{function}} setSearch - A function to set the search value for the table.
 * @param {{array}} users - An array of user objects to display in the table.
 * @param {{function}} changePageSize - A function to change the number of rows displayed per page.
 * @param {{number}} userCount - The total number of users in the table.
 * @param {{function}} pageChange - A function to change the current page of the table.
 * @param {{boolean}} loading - A boolean indicating whether the table is currently loading.
 * @returns A table of users with various actions that can be taken
 */
export default function UsersTable({
  setSearch,
  users,
  changePageSize,
  userCount,
  pageChange,
  loading,
  pgNo,
  pgSize,
}) {
  const [pageSize, setPageSize] = useState('5');
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState();
  const [openActionModal, setOpenActionModal] = useState(false);
  const [clickedRecord, setClickedRecord] = useState();
  const [searchSiteName, setSearchSiteName] = useState('');
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [action, setAction] = useState();

  /**
   * useEffect hook that updates the table data when the users state changes.
   * @param {{Array}} users - An array of user objects to be displayed in the table.
   */
  useEffect(() => {
    if (users) {
      let tempUsers = JSON.parse(JSON.stringify(users));
      tempUsers.forEach((record) => {
        record.key = record.sponsorUserId.toString();
      });
      setTableData(tempUsers);
    }
    setPageNo(pgNo + 1);
    setPageSize(pgSize);
  }, [users]);

  /**
   * Handles the search functionality for the search bar. Sets the search site name state
   * based on the value of the search input.
   * @param {{Event}} e - the event object
   */
  const onSearch = (e) => {
    const getSearchValue = e.target.value;
    if (getSearchValue.length < 0) {
      setSearchSiteName('');
    } else {
      setSearchSiteName(getSearchValue);
    }
  };

  // Handles the submission of a search query by setting the search state to the value of the searchSiteName variable.
  const handleSearchSubmit = () => {
    setSearch(searchSiteName);
  };

  /**
   * Calls the pageChange function with the given page and sets the current page number to the given page.
   * @param {{number}} page - the page number to change to
   */
  const onPageChange = (page) => {
    pageChange(page);
    setPageNo(page);
  };

  /**
   * Opens a modal window with the given record and action.
   * @param {{Event}} e - The event that triggered the modal window to open.
   * @param {{Object}} record - The record to display in the modal window.
   * @param {{string}} action - The action to perform on the record.
   */
  const openModal = (e, record, action) => {
    setClickedRecord(record);
    setAction(action);
    setOpenActionModal(true);
  };

  /**
   * Handles the cancel event for a modal by resetting the state variables.
   * @param {{Event}} e - The event object.
   */
  const handleModalCancel = (e) => {
    setOpenActionModal(false);
    setClickedRecord(null);
    setAction('');
  };

  /**
   * Updates the table based on the given email and action.
   * @param {{string}} email - the email to update the table with
   * @param {{string}} action - the action to perform on the table
   */
  const updateTable = (email, action) => {
    handleSearchSubmit();
  };
  /**
   * Makes the given user an admin by calling the SponsorService.makeAdmin function.
   * @param {{object}} user - The user object to make an admin.
   */
  const makeAdmin = (user) => {
    setLoadingAdmin(user.email);
    SponsorService.makeAdmin(user.email)
      .then(() => {
        handleSearchSubmit();
        setLoadingAdmin(false);
        notification.success({
          message: 'Admin access added to ' + user.email,
        });
      })
      .catch((err) => {
        setLoadingAdmin(false);
        notification.error({ message: err.response.data.error.message });
      });
  };
  /**
   * Removes admin access from the given user.
   * @param {{object}} user - The user object to remove admin access from.
   */
  const removeAdmin = (user) => {
    setLoadingAdmin(user.email);
    SponsorService.removeAdmin(user.email)
      .then((res) => {
        handleSearchSubmit();
        setLoadingAdmin(false);
        notification.success({
          message: 'Admin access removed from ' + user.email,
        });
      })
      .catch((err) => {
        setLoadingAdmin(false);
        notification.error({ message: err.response.data.error.message });
      });
  };

  /**
   * An array of objects that define the columns for a table. Each object represents a column
   * and contains a title, width, dataIndex, and render function. The render function returns
   * the JSX to be displayed in the cell for that column.
   * @type {Array}
   */
  let columns = [
    {
      title: 'User',
      width: '25%',

      render: (__, record) => {
        return (
          <div key={record.firstName} className={'table_site_name pop-reg-666'}>
            {record.profilePic ? (
              <div className="login-profile-pic">
                <img src={record.profilePic} alt="profilePic" />
              </div>
            ) : (
              <FontAwesomeIcon
                icon={faUserCircle}
                fontSize={30}
                style={{ marginRight: '10px' }}
              />
            )}
            {(record.firstName ? record.firstName : '') +
              ' ' +
              (record.lastName ? record.lastName : '')}
          </div>
        );
      },
    },
    {
      title: 'Email',
      width: '30%',
      dataIndex: 'email',
      render: (email) => (
        <div key={email} className="table_site_name pop-reg-666 email">
          {email}
        </div>
      ),
    },
    {
      title: 'Last Login',
      width: '20%',
      render: (__, record) => {
        let unformDate = record.lastLogin
          ? record.lastLogin.split('T')[0]
          : null;
        let displayDate = unformDate ? new Date(unformDate) : null;
        let formattedDate = displayDate
          ? displayDate.toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : null;

        return (
          <div key={record.lastLogin} className="table_site_name pop-reg-666">
            {formattedDate}
          </div>
        );
      },
    },
    {
      title: 'Action',
      width: '25%',
      dataIndex: 'isBlackList',

      render: (__, record) => {
        return (
          <div key={record.isBlackList}>
            {record.isBlackList === 1 ? (
              <div
                className="table_site_name pop-reg-666"
                key={record.sponsorUserId}
              >
                <Button
                  onClick={(e) => openModal(e, record, 'unblock')}
                  className="unblock-button"
                >
                  Unblock
                </Button>
                <Tooltip title={record.blackListReason}>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="blocked-reason-info-icon"
                  />
                </Tooltip>
              </div>
            ) : (
              <div
                className="table_site_name pop-reg-666"
                key={record.sponsorUserId}
              >
                <Button
                  onClick={(e) => openModal(e, record, 'block')}
                  className="block-button"
                >
                  Block
                </Button>
                {record.isSuperUser === 0 ? (
                  <Popconfirm
                    title="Make Admin"
                    placement="leftTop"
                    description="Are you sure to add admin access to this user?"
                    onConfirm={() => {
                      makeAdmin(record);
                    }}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Button
                      type="link"
                      loading={loadingAdmin === record.email}
                      style={{ color: '#0d2b3a' }}
                      className="link-button"
                    >
                      Make Admin
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Remove Admin"
                    placement="leftTop"
                    description="Are you sure to remove admin access from this user?"
                    onConfirm={() => {
                      removeAdmin(record);
                    }}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Button
                      type="link"
                      loading={loadingAdmin === record.email}
                      style={{ color: '#932f2f' }}
                      className="link-button"
                    >
                      Remove Admin
                    </Button>
                  </Popconfirm>
                )}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  /**
   * Renders the header of a table component with a dropdown to select the number of rows to display,
   * a search bar to search for specific entries, and column titles.
   * @returns A JSX element representing the table header.
   */
  const tableHeader = () => {
    return (
      <>
        <div className="title-container">
          <div className="table-header-wrapper">
            <div className="table-header-wrapper-title">
              <Space>
                <p>Show</p>
                <div className="table-header-wrapper-title-dropdown">
                  <Select
                    defaultValue="5"
                    onChange={(value) => {
                      setPageSize(value);
                      changePageSize(value);
                    }}
                    options={noOfRowsDropDownData}
                    style={{ width: '100%' }}
                    className="crio-input"
                  />
                </div>
                <p>Entries</p>
              </Space>
            </div>
            <div className="table-header-wrapper-search">
              <Input
                className="crio-input"
                placeholder="Search by User"
                size="large"
                suffix={
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="dashboard-search-icon"
                    onClick={(e) => {
                      handleSearchSubmit(e);
                    }}
                  />
                }
                onChange={(e) => onSearch(e)}
                onPressEnter={(e) => {
                  handleSearchSubmit(e);
                }}
                style={{ width: '100%', minWidth: '270px' }}
              />
            </div>
          </div>
          <Divider type="horizontal" className="horizontal-divider-class" />
        </div>
        <div className="table-header-column">
          <div className="col-title" style={{ width: '25%' }}>
            {columns[0].title}
          </div>
          <div className="col-title" style={{ width: '30%' }}>
            {columns[1].title}
          </div>
          <div className="col-title" style={{ width: '20%' }}>
            {columns[2].title}
          </div>
          <div className="col-title" style={{ width: '25%' }}>
            {columns[3].title}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={tableData}
        title={tableHeader}
        className={'ant-side-table'}
        showHeader={false}
        loading={loading || loadingAdmin !== false}
        pagination={{
          pageSize: pageSize,
          total: userCount,
          current: pageNo,
          onChange: (page) => {
            onPageChange(page);
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // console.log(record);
            },
          };
        }}
      />
      {openActionModal ? (
        <ActionModal
          handleModalCancel={handleModalCancel}
          record={clickedRecord}
          action={action}
          updateTable={updateTable}
        />
      ) : null}
    </div>
  );
}
