import { createSlice } from '@reduxjs/toolkit';

// A Redux slice that manages the state of collections.
export const collectionsSlice = createSlice({
  name: 'collections',
  initialState: [],
  reducers: {
    saveCollections: (state, action) => {
      return action.payload;
    },
    updateCollection: (state, action) => {
      return state.map((coll) => {
        if (action.payload.collectionsId === coll.collectionsId) {
          return action.payload;
        }
        return coll;
      });
    },
  },
});

export const { saveCollections, updateCollection } = collectionsSlice.actions;
export default collectionsSlice.reducer;
