import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import logo from '../../assets/images/CRIO_logo_notagline-nocolor.png';
import CommonService from '../services/commonService';

/**
 * A functional component that renders the footer of the page. It displays the company logo,
 * links to the security and compliance page, privacy policy page, and the current year's
 * copyright information.
 * @returns A JSX element that displays the footer of the page.
 */
const Footer = () => {
  const [copyRight, setCopyRight] = useState('');

  /**
   * Runs an useEffect that fetches the copyright information from the server and sets it in state.
   * This useEffect runs only once, when the component mounts.
   */
  useEffect(() => {
    CommonService.getCopyright().then((res) => {
      setCopyRight(res.data.data.copyright);
    });
  }, []);
  return (
    <>
      <div className="footer">
        <div className="footer-branding">
          <img src={logo} alt="footerlogo" />
        </div>
        <div className="footer-rights">
          <a
            href={process.env.REACT_APP_SECURITY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Security & Compliance
          </a>
          <a
            href={process.env.REACT_APP_PRIVACY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          {copyRight !== '' ? <p>{copyRight}</p> : <Skeleton.Input />}
        </div>
      </div>
    </>
  );
};

export default Footer;
