import axios from 'axios';
import { verifyEmail } from '../constants/urls';
import { getEnvVar } from '../utils/getApiEndpoint';

/**
 * Creates an instance of Axios with a base URL and an interceptor that adds an
 * authorization header to requests if a token is present in local storage.
 * @returns {AxiosInstance} - An instance of Axios with the interceptor.
 */
const instance = axios.create({
  baseURL: getEnvVar('REACT_APP_BASE_URL'),
});
instance.interceptors.request.use(function (config) {
  if (config.url.includes(verifyEmail)) {
    return config;
  }
  const token = localStorage.getItem('SDIR_SPONSOR_TOKEN');
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

/**
 * Intercepts the response from an HTTP request and checks for network errors or a 403 status code.
 * If either of these are found, the user's sponsor token is removed from local storage and they are redirected to a session timeout page.
 * @param {Object} response - The response object from the HTTP request.
 * @param {Object} error - The error object from the HTTP request.
 * @returns The response object if there are no errors, otherwise a rejected promise with the error object.
 */
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === 'ERR_NETWORK' || error.response.status === 403) {
      localStorage.removeItem('SDIR_SPONSOR_TOKEN');
      window.location.href = '/session-timeout';
    }
    return Promise.reject(error);
  },
);

export default instance;
