import { createSlice } from '@reduxjs/toolkit';

// A Redux slice that manages the state of the promotional banner.
export const promotionalBannerSlice = createSlice({
  name: 'promotionalBanner',
  initialState: [],
  reducers: {
    savePromotionalBanner: (state, action) => {
      return action.payload;
    },
    removePromotionalBanner: (state, action) => {
      return [];
    },
  },
});

export const { savePromotionalBanner, removePromotionalBanner } =
  promotionalBannerSlice.actions;
export default promotionalBannerSlice.reducer;
