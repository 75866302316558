import React from 'react';
import { Checkbox, Input } from 'antd';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Renders a component that displays a list of organization options as checkboxes.
 * @param {{organizationOptions: Array, selectedOrgAreas: Array, setSelectedOrgAreas: Function, setOrgSearch: Function}} props - The props object containing the organization options, selected organization areas, and functions to update them.
 * @returns A React component that displays a list of organization options as checkboxes.
 */
function OrganizationOptions({
  organizationOptions,
  selectedOrgAreas,
  setSelectedOrgAreas,
  setOrgSearch,
}) {
  /**
   * Updates the state of the organization search value when the user types in the search bar.
   * @param {{Event}} e - The event object generated by the change in the search bar.
   */
  const handleOrgSearchChange = (e) => {
    setOrgSearch(e.target.value);
  };
  return (
    <>
      <div className="sites-left-header">
        <div>Organization</div>
        <p
          onClick={() => {
            setSelectedOrgAreas([]);
          }}
        >
          clear
        </p>
      </div>
      <div className="sites-left-body manage-sites-checkbox-wrapper">
        <div
          className="table-header-wrapper-search"
          style={{ marginBottom: '10px' }}
        >
          <Input
            className="manage-site-org-input"
            placeholder="Search Organization"
            size="small"
            suffix={
              <FontAwesomeIcon
                icon={faSearch}
                className="dashboard-search-icon"
              />
            }
            onChange={handleOrgSearchChange}
            style={{ width: '100%' }}
          />
        </div>
        <Checkbox.Group
          className="manage-sites-area-container"
          onChange={(e) => {
            setSelectedOrgAreas(e);
          }}
          value={selectedOrgAreas}
        >
          {organizationOptions.map((el) => {
            return (
              <Checkbox
                className={
                  'sites-checkbox ' +
                  (selectedOrgAreas.includes(el.organizationId)
                    ? 'sites-checkbox-checked'
                    : '')
                }
                key={el.organizationId}
                value={el.organizationId}
              >
                {el.organizationName}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </div>
    </>
  );
}

export default OrganizationOptions;
