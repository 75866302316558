import React, { useEffect, useState } from 'react';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faPen,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Row,
  Skeleton,
  Space,
  notification,
  Modal,
  Form,
  Input,
} from 'antd';
import SponsorService from '../services/sponsorService';

/**
 * A component that displays a list of sites in a collection, and allows the user to perform various actions on the collection.
 * @param {{collectionData: Object, setList: Function, setListCount: Function, list: Array}} props - An object containing the collection data, the setList function, the setListCount function, and the list of collections.
 * @returns A JSX element that displays the collection list card.
 */
function CollectionListCard({ collectionData, setList, setListCount, list }) {
  const [editVisible, setEditVisible] = useState(false);
  const [box, setBox] = useState();
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [selectedSite, setSelectedSite] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [isModalOpenNew, setIsModalNew] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  // Scrolls the content of a container element to the left by the width of the container.
  const btnPrev = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
  };

  // Scrolls the content of a container horizontally to the next page.
  const btnNext = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
  };

  /**
   * Handles the removal of a site from a collection when the user clicks the "Ok" button.
   * Sends a request to the server to remove the site from the collection and updates the state
   * of the component accordingly.
   */
  const handleOkRemove = () => {
    setLoadingRemove(true);
    SponsorService.removeFromCollection(
      selectedSite.siteId,
      collectionData.collectionId,
    )
      .then((res) => {
        setList(res.data.data);
        setListCount(res.data.data.length);
        setLoadingRemove(false);
        setOpen(false);
        setSelectedSite(-1);
      })
      .catch(() => {
        notification.error({
          message: 'Removing from collection failed !! Please try again...',
        });
        setLoadingRemove(false);
      });
  };

  /**
   * Handles the cancel action when removing a site from a list.
   * Closes the dialog box and resets the selected site to -1.
   */
  const handleCancelRemove = () => {
    setOpen(false);
    setSelectedSite(-1);
  };

  /**
   * Handles the deletion of a collection by calling the SponsorService.deleteCollection method.
   * If the deletion is successful, updates the list of collections and closes the delete confirmation modal.
   * If the deletion fails, displays an error notification.
   */
  const handleOk = () => {
    setLoading(true);
    SponsorService.deleteCollection(collectionData.collectionId)
      .then((res) => {
        setList(res.data.data);
        setListCount(res.data.data.length);
        setLoading(false);
        setOpenDelete(false);
      })
      .catch(() => {
        notification.error({
          message: 'Deleting collection failed !! Please try again...',
        });
        setLoading(false);
      });
  };

  /**
   * Handles the cancel action for deleting an item.
   * Sets the openDelete state to false, which closes the delete confirmation dialog.
   */
  const handleCancel = () => {
    setOpenDelete(false);
  };

  /**
   * Handles the "Ok" button click event for the "New" modal.
   * Sets the "isModalNew" state to false and resets the form fields.
   */
  const handleOkNew = () => {
    setIsModalNew(false);
    form.resetFields();
  };

  // Handles the cancel action for creating a new item. Resets the form fields and closes the modal.
  const handleCancelNew = () => {
    setIsModalNew(false);
    form.resetFields();
  };

  // Handles the edit functionality by resetting the edit form fields and setting the edit state to false.
  const handleEdit = () => {
    setEdit(false);
    editForm.resetFields();
  };

  // Handles the cancel edit action by resetting the edit form fields and setting the edit state to false.
  const handleCancelEdit = () => {
    setEdit(false);
    editForm.setFields([
      {
        name: 'collectionName',
        value: collectionData.collectionName,
      },
    ]);
  };

  /**
   * Sends a bulk reachout request to the SponsorService for the given collection ID and values.
   * @param {{any}} values - The values to send in the reachout request.
   */
  const bulkReachout = (values) => {
    setLoadingNew(true);
    SponsorService.reachOutCollection(collectionData.collectionId, values)
      .then((res) => {
        notification.success({
          message: 'Successfully reached out...',
        });
        let tempList = [];
        list.map((el) => {
          if (el.collectionId !== collectionData.collectionId) {
            tempList.push(el);
          }
        });
        setList(tempList);
        setListCount(tempList.length);
        setLoadingNew(false);
        handleOkNew();
      })
      .catch(() => {
        notification.error({
          message: 'Reachout collection failed !! Please try again...',
        });
        setLoadingNew(false);
      });
  };

  /**
   * Renames a collection using the SponsorService API and updates the list of collections.
   * @param {{Object}} values - The new values for the collection
   */
  const rename = (values) => {
    setEditLoading(true);
    SponsorService.renameCollection(collectionData.collectionId, values)
      .then((res) => {
        let tempList = [];
        list.map((el) => {
          if (el.collectionId === collectionData.collectionId) {
            tempList.push(res.data.data);
          } else {
            tempList.push(el);
          }
        });
        setList(tempList);
        setListCount(tempList.length);
        setEditLoading(false);
        handleEdit();
      })
      .catch((err) => {
        notification.error({
          message: err.response.data.error.message,
        });
        setEditLoading(false);
      });
  };

  /**
   * useEffect hook that sets the box element and the initial value of the collection name field
   * in the edit form.
   */
  useEffect(() => {
    setBox(document.querySelector('.list-no-' + collectionData.collectionId));
    editForm.setFields([
      {
        name: 'collectionName',
        value: collectionData.collectionName,
      },
    ]);
  }, []);
  return (
    <>
      <Modal
        title={
          <div className="collection-modal-header">Rename Collection </div>
        }
        open={edit}
        onOk={handleEdit}
        onCancel={handleCancelEdit}
        className="collection-modal"
        centered
        footer={null}
        destroyOnClose
      >
        <Form form={editForm} onFinish={rename}>
          <div className="new-collection-modal-body">
            <div className="collection-modal-body-list-left-text new-collection-modal-body-label">
              <a>Collection Name</a>
            </div>
            <Form.Item
              name={'collectionName'}
              rules={[
                {
                  required: true,
                  message: 'Please Enter collection name',
                },
              ]}
            >
              <Input
                className="signin-input"
                placeholder="Enter Collection Name"
              />
            </Form.Item>
          </div>
          <div className="new-collection-modal-footer">
            <Form.Item>
              <Space size={20}>
                <Button
                  type="primary"
                  size="large"
                  className="new-collection-modal-footer-save"
                  htmlType="submit"
                  loading={editLoading}
                >
                  Save
                </Button>
                <Button
                  size="large"
                  onClick={handleCancelEdit}
                  className="new-collection-modal-footer-cancel"
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        title={
          <div className="collection-modal-header">Send Bulk Reach Out</div>
        }
        open={isModalOpenNew}
        onOk={handleOkNew}
        onCancel={handleCancelNew}
        className="collection-modal"
        centered
        footer={null}
        destroyOnClose
      >
        <Form form={form} onFinish={bulkReachout}>
          <div className="new-collection-modal-body">
            <div className="collection-modal-body-list-left-text new-collection-modal-body-label">
              <a>
                By requesting to reach out to the sites on the list in bulk,
                CRIO will bulk email these sites and let them know that you are
                interested in working with them.
              </a>
            </div>
            <Form.Item
              name="customMessage"
              rules={[
                {
                  required: true,
                  message: 'Please Enter custom message',
                },
              ]}
            >
              <Input.TextArea
                className="signin-input"
                placeholder="Enter custom message you want us to attach"
                style={{
                  height: 120,
                  resize: 'none',
                  fontSize: '14px',
                  fontFamily: ['Poppins-Light', 'Poppins Light', 'Poppins'],
                  fontWeight: '200',
                  color: '#999999 !important',
                }}
              />
            </Form.Item>
          </div>
          <div className="new-collection-modal-footer">
            <Form.Item>
              <Space size={20}>
                <Button
                  type="primary"
                  size="large"
                  className="new-collection-modal-footer-save"
                  htmlType="submit"
                  loading={loadingNew}
                >
                  Save
                </Button>
                <Button
                  size="large"
                  onClick={handleCancelNew}
                  className="new-collection-modal-footer-cancel"
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        title={
          <div className="remove-collection-modal-header">
            <div className="remove-collection-modal-header-icon">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </div>
            <div>
              <div className="remove-collection-modal-header-text">
                Delete Collection?
              </div>
              <div className="remove-collection-modal-body">
                Are you sure you want to delete {collectionData.collectionName}?
              </div>
              <div className="remove-collection-modal-footer">
                <Space size={20}>
                  <Button
                    size="large"
                    className="remove-collection-modal-footer-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="text"
                    onClick={handleOk}
                    className="remove-collection-modal-footer-remove"
                    loading={loading}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        }
        centered
        open={openDelete}
        onOk={handleOk}
        onCancel={handleCancel}
        className="collection-modal"
        closable={false}
        footer={false}
      />
      <Modal
        title={
          <div className="remove-collection-modal-header">
            <div className="remove-collection-modal-header-icon">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </div>
            <div>
              <div className="remove-collection-modal-header-text">
                Remove site from the list?
              </div>
              <div className="remove-collection-modal-body">
                Are you sure you want to remove this site from the list?
              </div>
              <div className="remove-collection-modal-footer">
                <Space size={20}>
                  <Button
                    size="large"
                    className="remove-collection-modal-footer-cancel"
                    onClick={handleCancelRemove}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="text"
                    onClick={handleOkRemove}
                    className="remove-collection-modal-footer-remove"
                    loading={loadingRemove}
                  >
                    Remove
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        }
        centered
        open={open}
        onOk={handleOkRemove}
        onCancel={handleCancelRemove}
        className="collection-modal"
        closable={false}
        footer={false}
      />
      <div className="list-card">
        <Row>
          <Col xs={24} sm={24} md={18}>
            <Row>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <div className="list-card-header">
                  <p
                    onMouseEnter={() => {
                      setEditVisible(true);
                    }}
                    onMouseLeave={() => {
                      setEditVisible(false);
                    }}
                    onClick={() => {
                      editForm.setFields([
                        {
                          name: 'collectionName',
                          value: collectionData.collectionName,
                        },
                      ]);
                      setEdit(true);
                    }}
                  >
                    <span>{collectionData.countOfSites}</span>{' '}
                    {collectionData.collectionName}
                    {editVisible ? (
                      <FontAwesomeIcon
                        icon={faPen}
                        className="collection-icon"
                        style={{ color: '#999999' }}
                      />
                    ) : null}
                  </p>
                </div>
              </Col>
              <Col xs={0} sm={0} md={6} lg={6} xl={6}>
                <div className="sites-scroller">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="collection-icon"
                    onClick={btnPrev}
                  />
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="collection-icon"
                    onClick={btnNext}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={18}>
            <div className="sites-carousel-container">
              <div
                className={
                  'list-sites-carousel list-no-' + collectionData.collectionId
                }
              >
                {collectionData.countOfSites !== 0 &&
                  collectionData.sites.map((site) => {
                    return (
                      <div className="list-site-card-wrapper">
                        {showDeleteIcon === site.siteId ? (
                          <FontAwesomeIcon
                            icon={faTrash}
                            className={'list-site-card-delete-icon'}
                            onMouseEnter={() => setShowDeleteIcon(site.siteId)}
                            onMouseLeave={() => setShowDeleteIcon(-1)}
                            onClick={() => {
                              setOpen(true);
                              setSelectedSite(site);
                            }}
                          />
                        ) : null}
                        <div
                          className="list-site-card"
                          key={site.siteId}
                          onMouseEnter={() => setShowDeleteIcon(site.siteId)}
                          onMouseLeave={() => setShowDeleteIcon(-1)}
                          onClick={() => {
                            window.open('/sitepage/' + site.siteId, '_blank');
                          }}
                        >
                          <div className="list-site-card-img-wrapper">
                            {site.siteLogo ? (
                              <img
                                src={site.siteLogo}
                                alt={'site logo'}
                                className={'list-site-card-img'}
                              />
                            ) : (
                              <Skeleton.Image
                                className={'list-site-card-img'}
                              />
                            )}
                          </div>
                          <div className="list-site-card-p">
                            {site.siteName.length > 25
                              ? site.siteName.slice(0, 24) + '...'
                              : site.siteName}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <div className="list-card-action">
              <Button
                className="list-card-action-1"
                disabled={collectionData.countOfSites === 0}
                onClick={() => setIsModalNew(true)}
              >
                Bulk Reach Out
              </Button>
              <Button
                className="list-card-action-2"
                onClick={() => {
                  setOpenDelete(true);
                }}
              >
                {' '}
                Delete List
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CollectionListCard;
