import { Button, Form, Input, notification, Space } from 'antd';
import React, { useState } from 'react';
import SponsorService from '../../services/sponsorService';
import InvitationList from './InvitationList';

/**
 * Renders a component that allows the user to invite new members to the platform.
 * @returns A React component that displays a form for inviting new users and a table of invited users.
 */
function InviteUser() {
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [pageSize, setPageSize] = useState('5');
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState();
  const [searchSiteName, setSearchSiteName] = useState('');
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [form] = Form.useForm();

  /**
   * Updates the table with the list of invited users based on the given page size, page number, and search query.
   * @param {{number}} ps - The page size to use for pagination.
   * @param {{number}} pn - The page number to use for pagination.
   * @param {{string}} search - The search query to filter the list of invited users.
   */
  const updateTable = async (ps, pn, search) => {
    setLoading(true);
    await SponsorService.getInvitedUsers(ps, pn, search)
      .then((res) => {
        setTableData(res.data.data.listOfUsers);
        setUserCount(res.data.data.totalUsers);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  /**
   * Invites a user with the given values by calling the SponsorService.inviteUser method.
   * If successful, resets the form fields, displays a success notification, and updates the table.
   * If unsuccessful, displays an error notification.
   * @param {{object}} values - An object containing the values needed to invite a user.
   */
  const inviteUser = (values) => {
    setLoadingInvite(true);
    SponsorService.inviteUser(values)
      .then(() => {
        form.resetFields();
        setLoadingInvite(false);
        notification.success({
          message: values.email + 'is successfully invited',
        });
        updateTable(pageSize, pageNo, searchSiteName);
      })
      .catch((err) => {
        setLoadingInvite(false);
        notification.error({ message: err.response.data.error.message });
      });
  };
  return (
    <div className="my-list-container body-container-tab">
      <div className="my-list-header tabs-header">Invite User</div>
      <div className="my-list-body-wrapper">
        <div
          className="my-list-body"
          style={{ width: '70%', marginRight: '20px' }}
        >
          <Form
            form={form}
            onFinish={inviteUser}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name="email"
              label="Work Email Address"
              rules={[
                {
                  required: true,
                  message: 'Please enter user work email address',
                },
                {
                  type: 'email',
                  message:
                    'Invalid email address. Only use numbers, letters, or + - _ characters',
                },
              ]}
            >
              <Input className="signin-input" />
            </Form.Item>
            <Space size={20} className="name-ant-space">
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter user first name',
                  },
                ]}
              >
                <Input className="signin-input" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter user last name',
                  },
                ]}
              >
                <Input className="signin-input" />
              </Form.Item>
            </Space>
            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter company name',
                },
              ]}
            >
              <Input className="signin-input" />
            </Form.Item>
            <Form.Item name="userRole" label="What's users role? (Optional)">
              <Input className="signin-input" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="invite-button"
                loading={loadingInvite}
              >
                Invite to become a member
              </Button>
            </Form.Item>
          </Form>
        </div>
        <InvitationList
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setSearchSiteName={setSearchSiteName}
          searchSiteName={searchSiteName}
          tableData={tableData}
          loading={loading}
          userCount={userCount}
          updateTable={updateTable}
        />
      </div>
    </div>
  );
}

export default InviteUser;
