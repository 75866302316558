import React, { useState } from 'react';
import { Form, Button, Space, notification } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CommonService from '../../services/commonService';

/**
 * A component that displays a form for editing a term and condition value. The form
 * allows the user to edit the value and save or reset the changes.
 * @param {{object}} term - The term and condition object to edit.
 * @param {function} setTerms - A function to update the terms and conditions.
 * @returns A form component with fields for editing the term and condition value.
 */
function TermForm({ term, setTerms }) {
  const [form] = Form.useForm();
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  /**
   * Saves the updated term value to the database and updates the state accordingly.
   * @param {{object}} e - the event object containing the updated term value.
   */
  const saveTerm = (e) => {
    setLoading(true);
    let payload = Object.assign({}, term);
    payload.value = e.value.toString();
    CommonService.updateTerms(payload)
      .then((res) => {
        setTerms(res.data.data);
        setLoading(false);
        setEdited(false);
        notification.success({
          message: term.key + ' updated successfully!',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Something went wrong!! please try again...',
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={saveTerm}
        initialValues={term}
        layout="vertical"
        requiredMark={false}
        onChange={() => {
          setEdited(true);
        }}
        scrollToFirstError
        onReset={() => {
          setEdited(false);
        }}>
        <Form.Item
          name={'value'}
          label="Terms and Condition Value"
          rules={[
            {
              required: true,
              message: 'Please enter Terms and Condition Value',
            },
          ]}>
          <ReactQuill
            theme="snow"
            onChange={() => {
              setEdited(true);
            }}
          />
        </Form.Item>
        <Space>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={
                edited ? 'signin-button-accept' : 'signin-button-disabled'
              }
              disabled={!edited}
              loading={loading}>
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="reset"
              className="signin-button-decline"
              disabled={loading}>
              Reset
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}

export default TermForm;
