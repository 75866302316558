import React, { useState } from 'react';
import { Typography, Modal, Space, Button, notification, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faTrash,
  faPen,
} from '@fortawesome/pro-solid-svg-icons';
import CommonService from '../../services/commonService';
import CreateLookup from './CreateLookup';
const { Text } = Typography;

/**
 * A component that displays a form for managing lookup data. Allows users to edit and delete
 * existing lookup values, and add new ones.
 * @param {{data}} data - The lookup data to display.
 * @param {{setLookUpdatas}} setLookUpdatas - A function to update the lookup data.
 * @returns A form for managing lookup data.
 */
function LookUpDataForm({ data, setLookUpdatas }) {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedLookup, setSelectedLookup] = useState();
  const [loading, setLoading] = useState(false);
  const [oldValue, setOldValue] = useState();
  const [newValue, setNewValue] = useState('');
  /**
   * Updates a list of data by grouping it by a specific key and setting the state of the component
   * with the updated list.
   * @param {{object}} res - The response object containing the data to be updated.
   */
  const updateList = (res) => {
    let catList = {};
    res.data.data.forEach((ld) => {
      if (!catList[ld.key]) {
        catList[ld.key] = [ld];
      } else {
        catList[ld.key].push(ld);
      }
    });
    let updatedList = [];
    for (const key in catList) {
      updatedList.push({
        key: key,
        values: catList[key],
      });
    }
    setLookUpdatas(updatedList);
  };

  // Handles the update of a lookup value by sending a request to the server with the updated value.
  const handleUpdate = () => {
    setLoading(true);
    let payload = Object.assign({}, oldValue);
    payload.value = newValue;
    CommonService.updateLookup(payload)
      .then((res) => {
        updateList(res);
        setLoading(false);
        handleCancel();
        notification.success({
          message: oldValue.key + ' updated successfully!',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Something went wrong!! please try again...',
        });
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setOpen(false);
    setLoading(false);
  };

  /**
   * Deletes the selected lookup from the database and updates the list of lookups.
   * Displays a success message if the deletion is successful, and an error message if it fails.
   */
  const deleteLookup = () => {
    setLoading(true);
    CommonService.deleteLookup(selectedLookup.lookupId)
      .then((res) => {
        updateList(res);
        notification.success({
          message: selectedLookup.value + ' deleted successfully!',
        });
        cancelDelete();
      })
      .catch(() => {
        notification.error({
          message: 'Something went wrong!! please try again...',
        });
        setLoading(false);
      });
  };
  // Cancels the delete action and closes the delete confirmation dialog.
  const cancelDelete = () => {
    setLoading(false);
    setDeleteOpen(false);
  };
  return (
    data && (
      <>
        {oldValue !== undefined && newValue !== '' && (
          <Modal
            key={oldValue.key}
            title={
              <div className="remove-collection-modal-header">
                <div className="update-collection-modal-header-icon">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </div>
                <div>
                  <div className="remove-collection-modal-header-text">
                    Update {oldValue.key} ?
                  </div>
                  <div className="remove-collection-modal-body">
                    Are you sure you want to change <b>{oldValue.value}</b> to{' '}
                    <b>{newValue}</b>?
                    <br />
                    <br />
                  </div>
                  <div className="remove-collection-modal-footer">
                    <Space size={20}>
                      <Button
                        size="large"
                        onClick={() => {
                          handleUpdate();
                        }}
                        className="new-collection-modal-footer-save"
                        loading={loading}>
                        Update
                      </Button>
                      <Button
                        size="large"
                        className="new-collection-modal-footer-cancel"
                        disabled={loading}
                        onClick={() => {
                          handleCancel();
                        }}>
                        Discard
                      </Button>
                    </Space>
                  </div>
                </div>
              </div>
            }
            centered
            closable={false}
            open={open}
            className="collection-modal"
            footer={false}
          />
        )}
        {selectedLookup !== undefined && (
          <Modal
            key={selectedLookup.lookupId}
            title={
              <div className="remove-collection-modal-header">
                <div className="remove-collection-modal-header-icon">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </div>
                <div>
                  <div className="remove-collection-modal-header-text">
                    Delete from {selectedLookup.key} ?
                  </div>
                  <div className="remove-collection-modal-body">
                    Are you sure you want to delete{' '}
                    <b>{selectedLookup.value}</b> ?
                    <br />
                    <br />
                  </div>
                  <div className="remove-collection-modal-footer">
                    <Space size={20}>
                      <Button
                        size="large"
                        className="remove-collection-modal-footer-cancel"
                        onClick={cancelDelete}>
                        Cancel
                      </Button>
                      <Button
                        size="large"
                        type="text"
                        onClick={deleteLookup}
                        className="remove-collection-modal-footer-remove">
                        Delete
                      </Button>
                    </Space>
                  </div>
                </div>
              </div>
            }
            centered
            closable={false}
            open={deleteOpen}
            className="collection-modal"
            footer={false}
          />
        )}
        {data.values[0].key !== 'VERSION' &&
          data.values[0].key !== 'COPYRIGHT_MESSAGE' && (
            <CreateLookup
              key={data.values[0].key}
              cat={data.values[0].key}
              values={data.values}
              updateList={updateList}
            />
          )}

        <div className="tags-wrapper">
          {data.values.map((value) => {
            return (
              <div>
                <Text
                  key={value.lookupId}
                  className="value-tags"
                  editable={{
                    tooltip: 'Edit',
                    onChange: (e) => {
                      if (e !== value.value) {
                        setNewValue(e);
                        setOldValue(value);
                        setOpen(true);
                      }
                    },
                    triggerType: ['icon', 'text'],
                    icon: (
                      <FontAwesomeIcon
                        icon={faPen}
                        fontSize={12}
                        color="#315b86"
                      />
                    ),
                  }}>
                  {value.value}
                </Text>
                {data.values[0].key !== 'VERSION' &&
                  data.values[0].key !== 'COPYRIGHT_MESSAGE' && (
                    <Tooltip title="Delete">
                      <FontAwesomeIcon
                        icon={faTrash}
                        fontSize={12}
                        color="#932f2f"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedLookup(value);
                          setDeleteOpen(true);
                        }}
                      />
                    </Tooltip>
                  )}
              </div>
            );
          })}
        </div>
      </>
    )
  );
}

export default LookUpDataForm;
