import React from 'react';
import { Checkbox, Divider } from 'antd';

/**
 * A component that displays a table of filters and allows the user to select which filters to display.
 * @param {{boolean}} optedInCheck - A boolean indicating whether the "Opted In" checkbox is checked.
 * @param {{function}} setOptedInCheck - A function to set the value of the "Opted In" checkbox.
 * @param {{boolean}} notOptedInCheck - A boolean indicating whether the "Not Opted In" checkbox is checked.
 * @param {{function}} setNotOptedInCheck - A function to set the value of the "Not Opted In" checkbox.
 * @param {{function}} clearSelection - A function to clear the selection of checkboxes.
 */
export default function FilterTableOrg({
  optedInCheck,
  setOptedInCheck,
  notOptedInCheck,
  setNotOptedInCheck,
  clearSelection,
}) {
  return (
    <>
      <div className="sites-left-header">
        Status
        <p onClick={clearSelection}>clear</p>
      </div>
      <div className="sites-left-body">
        <Checkbox
          className={
            'sites-checkbox ' + (optedInCheck ? 'sites-checkbox-checked' : '')
          }
          checked={optedInCheck}
          onChange={(e) => {
            setOptedInCheck(e.target.checked);
          }}
        >
          Opted In
        </Checkbox>
        <Divider type="horizontal" className="horizontal-divider-class" />
        <Checkbox
          className={
            'sites-checkbox ' +
            (notOptedInCheck ? 'sites-checkbox-checked' : '')
          }
          checked={notOptedInCheck}
          onChange={(e) => {
            setNotOptedInCheck(e.target.checked);
          }}
        >
          Not Opted In
        </Checkbox>
        <Divider type="horizontal" className="horizontal-divider-class" />
      </div>
    </>
  );
}
