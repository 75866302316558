import axios from '../../config/axiosConfig';
import {
  organizationUrl,
  sitePagePiUrl,
  sitesUrl,
  studiesUrl,
} from '../../constants/urls';

const SitePageService = {
  /**
   * Retrieves site data for a given site ID using an HTTP POST request to the sites URL.
   * @param {string} siteId - The ID of the site to retrieve data for.
   * @returns {Promise} A promise that resolves with the site data.
   */
  getSiteData: (siteId) => {
    return axios.post(sitesUrl + siteId);
  },
  /**
   * Retrieves studies for a given site ID using an HTTP POST request to the studies URL.
   * @param {string} siteId - The ID of the site to retrieve studies for.
   * @returns {Promise} A promise that resolves with the studies data.
   */
  getStudies: (siteId) => {
    return axios.post(studiesUrl + siteId);
  },
  /**
   * Retrieves images for a given site ID using an HTTP POST request to the server.
   * @param {string} siteId - The ID of the site to retrieve images for.
   * @returns {Promise} A promise that resolves with the retrieved images.
   */
  getImages: (siteId) => {
    return axios.post(sitesUrl + siteId + '/images');
  },
  /**
   * Sends a POST request to the server to retrieve the single pi value for the given site ID.
   * @param {string} siteId - The ID of the site to retrieve the pi value for.
   * @returns {Promise} A promise that resolves with the single pi value for the given site ID.
   */
  getSinglePi: (siteId, pId) => {
    if (pId) {
      return axios.post('principal-investigator/' + pId + '/site/' + siteId);
    }
    return axios.post(sitePagePiUrl + siteId);
  },
  /**
   * Sends a PUT request to the server to flag a site with the given siteId.
   * @param {Object} payload - The payload to send with the request.
   * @param {string} siteId - The ID of the site to flag.
   * @returns A Promise that resolves with the response data.
   */
  flagSite: (payload, siteId) => {
    return axios.put(sitesUrl + siteId + '/flag-site', payload);
  },
  /**
   * Retrieves all organizations from the specified URL using an HTTP GET request.
   * @returns {Promise} A promise that resolves with the response data from the request.
  
   */
  getAllOrgs: () => {
    return axios.get(organizationUrl);
  },
  /**
   * Retrieves all sites from the server using the given payload, page size, page number, and token.
   * @param {Object} payload - The payload object to send with the request.
   * @param {number} pageSize - The number of sites to retrieve per page.
   * @param {number} pageNumber - The page number to retrieve.
   * @param {string} token - The token to use for authentication.
   * @returns {Promise} A promise that resolves with the response from the server.
   */
  getAllSites: async (payload, pageSize, pageNumber, token) => {
    var config = {
      method: 'post',
      url: sitesUrl + 'org/all-sites',
      params: {
        pageSize: pageSize,
        pageNumber: pageNumber,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload,
      cancelToken: token,
    };
    return await axios(config);
  },
  /**
   * Retrieves all organizations for management using the provided payload, page size, page number, and token.
   * @param {Object} payload - The payload object to send with the request.
   * @param {number} pageSize - The number of items to include in each page.
   * @param {number} pageNumber - The page number to retrieve.
   * @param {string} token - The authorization token to use for the request.
   * @returns {Promise} A promise that resolves with the response data from the request.
   */
  getAllorgsForManage: async (payload, pageSize, pageNumber, token) => {
    var config = {
      method: 'post',
      url: sitesUrl + 'org/all-orgs',
      params: {
        pageSize: pageSize,
        pageNumber: pageNumber,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload,
      cancelToken: token,
    };
    return await axios(config);
  },
  /**
   * Sends a PUT request to opt-out the organization with the given ID from data collection.
   * @param {string} orgId - The ID of the organization to opt-out.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  optOut: (orgId) => {
    return axios.put(sitesUrl + 'org/opt-out/' + orgId);
  },
  /**
   * Sends a PUT request to the server to update the blacklisted status of a site.
   * @param {string} siteId - The ID of the site to update.
   * @param {Object} payload - The payload to send with the request.
   * @returns {Promise} A promise that resolves with the response data.
   */
  siteAction: (siteId, payload) => {
    return axios.put(sitesUrl + siteId + '/blackList', payload);
  },
};

export default SitePageService;
