import { Popover } from 'antd';
import React from 'react';
import FacilitySelectButton from 'js/components/SearchBar/Facilities/FacilitySelectButton';
import FacilityContent from 'js/components/SearchBar/Facilities/FacilityContent';

const PopoverTitle = () => (
  <div className="crio-dropdown-title">Select up to 5</div>
);

function FacilitiesSelect({
  facilities,
  onChangeFacility,
  facility,
  setShowWarning,
  setOpen,
  open,
  getTitle,
}) {
  return (
    <div className="select-bar search-bar-card-wrapper">
      Facility and Equipment
      <Popover
        placement="bottom"
        title={<PopoverTitle />}
        overlayClassName="option-box-facility"
        content={
          <FacilityContent
            facilities={facilities}
            facility={facility}
            onChangeFacility={onChangeFacility}
          />
        }
        trigger="click"
        onOpenChange={(e) => {
          setShowWarning(false);
          setOpen(!e);
        }}>
        <>
          <FacilitySelectButton
            facility={facility}
            open={open}
            getTitle={getTitle}
          />
        </>
      </Popover>
    </div>
  );
}

export default FacilitiesSelect;
