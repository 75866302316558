import React, { useEffect, useState } from 'react';
import { Collapse, Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import EmailTemplateForm from './EmailTemplateForm';
import CommonService from '../../services/commonService';
const { Panel } = Collapse;

/**
 * Renders a list of email templates and allows the user to edit them.
 * @returns {JSX.Element} - The email templates component.
 */
function EmailTamplates() {
  const [templates, setTemplates] = useState();
  /**
   * useEffect hook that fetches all templates from the server and sets the state with the response data.
   * If there is an error, the user is redirected to an unauthorized access page.
   */
  useEffect(() => {
    CommonService.getAllTemplates()
      .then((res) => {
        setTemplates(res.data.data);
      })
      .catch(() => {
        window.location.href = '/unauthorized-access';
      });
  }, []);
  return (
    <div className="template-body">
      {templates ? (
        templates.map((template) => {
          return (
            <Collapse
              key={template.templateId}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <FontAwesomeIcon
                  icon={faCaretRight}
                  rotation={isActive ? 90 : 0}
                  className="site-expand-icon"
                />
              )}
              className="template-collapse"
              destroyInactivePanel
            >
              <Panel
                key={template.templateId}
                header={
                  <div className="site-header-bar">
                    <div className="site-header-name">{template.type}</div>
                  </div>
                }
              >
                <EmailTemplateForm
                  template={template}
                  setTemplate={setTemplates}
                />
              </Panel>
            </Collapse>
          );
        })
      ) : (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </div>
  );
}

export default EmailTamplates;
