import React from 'react';
import { Tooltip } from 'antd';

const TOOLTIP_STYLE = {
  color: '#666666',
  backgroundColor: '#fff !important',
  padding: '20px 20px',
  fontSize: '14px',
};

const TOOLTIP_OVERLAY_STYLE = {
  '--antd-arrow-background-color': '#fff',
};

function TherapySelectButton({ open, areas, getTitle }) {
  return (
    <div className="select-button">
      {areas.length <= 0 ? (
        'Select'
      ) : (
        <>
          {open ? (
            <Tooltip
              placement="topLeft"
              title={getTitle(areas)}
              overlayInnerStyle={TOOLTIP_STYLE}
              autoAdjustOverflow
              overlayStyle={TOOLTIP_OVERLAY_STYLE}>
              <span className={'big-selected-label'}>{getTitle(areas)}</span>
            </Tooltip>
          ) : (
            <span className={'big-selected-label'}>{getTitle(areas)}</span>
          )}
          <span
            style={{ fontWeight: 500, color: '#0d2b3a' }}
            className={'small-selected-label'}>
            {areas.length + ' Selected'}
          </span>
        </>
      )}
    </div>
  );
}

export default TherapySelectButton;
