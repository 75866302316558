import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import SponsorService from '../services/sponsorService';
import ReachedOutListCard from './ReachedOutListCard';

/**
 * A component that displays a list of reached out items. It retrieves the list from the SponsorService
 * and sets the count of reached out items. It also renders ReachedOutListCard components for each item in the list.
 * @param {{function}} setReachedCount - A function to set the count of reached out items.
 * @returns A React component that displays a list of reached out items.
 */
function ReachedOutList({ setReachedCount }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * useEffect hook that retrieves the reached out list from the SponsorService and sets the state
   * variables for the list and reachedCount. Also sets the loading state to true while the data is being fetched.
   */
  useEffect(() => {
    setLoading(true);
    SponsorService.getReachedOutList().then((res) => {
      setList(res.data.data);
      setReachedCount(res.data.data.length);
      setLoading(false);
    });
  }, []);
  return (
    <div className="list-card-container">
      {loading ? (
        <Skeleton active />
      ) : (
        list.map((el) => {
          return (
            <ReachedOutListCard
              collectionData={el}
              key={el.collectionId}
              setReachedCount={setReachedCount}
              setList={setList}
              list={list}
            />
          );
        })
      )}
    </div>
  );
}

export default ReachedOutList;
