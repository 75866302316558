import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import SponsorService from '../services/sponsorService';
import CollectionListCard from './CollectionListCard';

/**
 * A component that displays a list of collections. It retrieves the list of collections
 * from the SponsorService and renders a CollectionListCard for each collection.
 * @param {{function}} setListCount - A function to set the count of the list.
 * @returns A React component that displays a list of collections.
 */
function CollectionList({ setListCount }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect hook that fetches a list of collections from the SponsorService API and updates the component state.
  useEffect(() => {
    setLoading(true);
    SponsorService.getCollectionList().then((res) => {
      setList(res.data.data);
      setListCount(res.data.data.length);
      setLoading(false);
    });
  }, []);
  return (
    <div className="list-card-container">
      {loading ? (
        <Skeleton active />
      ) : (
        list.map((el) => {
          return (
            <CollectionListCard
              collectionData={el}
              key={el.collectionId}
              setList={setList}
              setListCount={setListCount}
              list={list}
            />
          );
        })
      )}
    </div>
  );
}

export default CollectionList;
