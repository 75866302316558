import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import FilterTable from './FilterTable';
import UsersTable from './UsersTable';
import SponsorService from '../../services/sponsorService';
let cancelToken;

/**
 * A component that allows a superuser to manage users. The component displays a table of users
 * and allows the superuser to filter and sort the users based on various criteria. The component
 * also allows the superuser to block and unblock users.
 * @returns A React component that displays a table of users and allows the superuser to manage them.
 */
export default function ManageUsers() {
  const [userCount, setUserCount] = useState();
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState();
  const [, setAllUsers] = useState();
  const [block, setBlock] = useState(false);
  const [unblock, setUnblock] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  /**
   * useEffect hook that fetches a list of users from the server and sets the state variables
   * accordingly.
   */
  useEffect(() => {
    setLoading(true);
    const queryParams = {
      search: '',
      block: 0,
      unblock: 0,
      pageNum: 0,
      pageSize: 5,
    };
    SponsorService.manageUsers(queryParams)
      .then((response) => {
        setUsers(response.data.data.listOfUsers);
        setAllUsers(response.data.data.listOfUsers);
        setUserCount(response.data.data.totalUsers);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        window.location.href = '/unauthorized-access';
      });
  }, []);

  /**
   * Changes the search data and applies the filters with the new search data.
   * @param {{Object}} searchData - The new search data to apply.
   */
  const changeSearch = (searchData) => {
    const queryParams = {
      search: searchData,
      block: block ? 1 : 0,
      unblock: unblock ? 1 : 0,
      pageNum: 0,
      pageSize: pageSize,
    };
    setSearch(searchData);
    applyFilters(queryParams);
  };

  /**
   * Applies filters to the list of users and updates the state with the filtered results.
   * @param {{object}} params - The parameters to filter the users by.
   */
  const applyFilters = async (params) => {
    setLoading(true);
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Canceling previous call.');
    }
    cancelToken = axios.CancelToken.source();
    try {
      let response = await SponsorService.manageUsers(
        params,
        cancelToken.token,
      );
      setPageNum(params.pageNum);
      setPageSize(params.pageSize);
      setUsers(response.data.data.listOfUsers);
      setUserCount(response.data.data.totalUsers);
      setLoading(false);
    } catch (err) {}
  };

  /**
   * Clears the current selection by resetting the block and unblock flags to false and
   * applying the filters with the default query parameters.
   */
  const clearSelection = () => {
    const queryParams = {
      search: search,
      block: 0,
      unblock: 0,
      pageNum: 0,
      pageSize: pageSize,
    };
    setBlock(false);
    setUnblock(false);
    applyFilters(queryParams);
  };
  /**
   * Changes the blocked status of a given data object and applies the updated filters.
   * @param {{any}} data - the data object to update the blocked status of.
   */
  const changeBlocked = (data) => {
    const queryParams = {
      search: search,
      block: data ? 1 : 0,
      unblock: unblock ? 1 : 0,
      pageNum: 0,
      pageSize: pageSize,
    };
    setBlock(data);
    applyFilters(queryParams);
  };
  /**
   * Changes the unblocked status of the given data and applies the filters with the new parameters.
   * @param {{any}} data - the data to change the unblocked status of
   */
  const changeUnBlocked = (data) => {
    const queryParams = {
      search: search,
      block: block ? 1 : 0,
      unblock: data ? 1 : 0,
      pageNum: 0,
      pageSize: pageSize,
    };
    setUnblock(data);
    applyFilters(queryParams);
  };

  /**
   * Changes the page size of the current page and applies the new page size to the filters.
   * @param {{number}} data - The new page size to set.
   */
  const changePageSize = (data) => {
    const queryParams = {
      search: search,
      block: block ? 1 : 0,
      unblock: unblock ? 1 : 0,
      pageNum: 0,
      pageSize: data,
    };
    setPageSize(data);
    applyFilters(queryParams);
  };

  /**
   * Updates the page number and applies filters based on the new page number.
   * @param {{any}} data - The new page number.
   */
  const pageChange = (data) => {
    const queryParams = {
      search: search,
      block: block ? 1 : 0,
      unblock: unblock ? 1 : 0,
      pageNum: data - 1,
      pageSize: pageSize,
    };
    setPageNum(data);
    applyFilters(queryParams);
  };

  return (
    <>
      {user.isSuperUser === 1 ? (
        <div className="body-container-tab">
          <div className="tabs-header">
            Users<p>{userCount}</p>
          </div>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <div className="sites-left">
                <FilterTable
                  blockedCheck={block}
                  setBlockedCheck={changeBlocked}
                  unblockedCheck={unblock}
                  setUnblockedCheck={changeUnBlocked}
                  clearSelection={clearSelection}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={19} lg={19} xl={19}>
              <div className="sites-left">
                <UsersTable
                  setSearch={changeSearch}
                  changePageSize={changePageSize}
                  users={users}
                  userCount={userCount}
                  pageChange={pageChange}
                  loading={loading}
                  pgNo={pageNum}
                  pgSize={pageSize}></UsersTable>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="crio-loading" style={{ height: '70vh' }}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
}
