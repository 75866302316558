import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Input,
  Space,
  notification,
  Switch,
  Skeleton,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons';
import SponsorService from '../../services/sponsorService';

/**
 * A component that allows the user to edit and publish a promotional banner.
 * @returns A React component that displays a form for editing and publishing a promotional banner.
 */
function Promotional() {
  const [form] = Form.useForm();
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [checked, setChecked] = useState(false);
  const [outputText, setOutputText] = useState();
  const [template, setTemplate] = useState();
  /**
   * Saves the given values to the promotional banner template.
   * @param {{Object}} values - The values to save to the template.
   */
  const saveTemplate = (values) => {
    setLoading(true);
    let payload = Object.assign({}, template);
    payload.value = values.value;
    SponsorService.updatePromotionalData(payload)
      .then((res) => {
        setTemplate(res.data.data);
        setOutputText(res.data.data.value);
        setChecked(res.data.data.isActive);
        setLoading(false);
        setEdited(false);
        notification.success({
          message: 'Successfully updated promotional banner data ..',
        });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.error.message });
        setLoading(false);
      });
  };
  /**
   * Publishes the promotional banner data to the server.
   * @param {{boolean}} e - A boolean value indicating whether the promotional banner is active or not.
   */
  const publish = (e) => {
    setLoadingSwitch(true);
    let payload = Object.assign({}, template);
    payload.isActive = e ? 1 : 0;
    payload.value = outputText;
    SponsorService.updatePromotionalData(payload)
      .then((res) => {
        setTemplate(res.data.data);
        setOutputText(res.data.data.value);
        setChecked(res.data.data.isActive);
        setLoadingSwitch(false);
        setEdited(false);
        notification.success({
          message: 'Successfully updated promotional banner data ..',
        });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.error.message });
        setLoadingSwitch(false);
      });
  };
  /**
   * useEffect hook that fetches promotional data from the SponsorService API and updates the state variables
   * accordingly.
   */
  useEffect(() => {
    SponsorService.getPromotionalData().then((res) => {
      setTemplate(res.data.data);
      setOutputText(res.data.data.value);
      setChecked(res.data.data.isActive);
    });
  }, []);
  return template ? (
    <div>
      <Space style={{ right: 0, position: 'absolute', zIndex: '999' }}>
        <p
          className="promotion-check"
          style={{ color: checked ? '#0b2532' : '#666' }}
        >
          {checked ? 'Published' : 'Unpublished'}
        </p>
        <Switch checked={checked} onChange={publish} loading={loadingSwitch} />
      </Space>
      <Form
        form={form}
        onFinish={saveTemplate}
        initialValues={template}
        layout="vertical"
        requiredMark={false}
        onChange={() => {
          setEdited(true);
        }}
        scrollToFirstError
        onReset={() => {
          setEdited(false);
          setOutputText(template.value);
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <div style={{ width: '60%' }}>
            <Form.Item
              name={'value'}
              label="Promotional Banner"
              rules={[
                {
                  required: true,
                  message: 'Please enter email body',
                },
              ]}
            >
              <Input.TextArea
                className="crio-input"
                rows={20}
                typeof="code"
                onChange={(e) => {
                  setOutputText(e.target.value);
                }}
                style={{ width: '100%', maxHeight: '446px' }}
              />
            </Form.Item>
          </div>
          <div
            style={{
              width: '40%',
              margin: '31px 0 0 20px',
              maxWidth: '350px',
            }}
          >
            <div className="search-signup-card">
              <div className="search-signup-card-icon">
                <FontAwesomeIcon icon={faMegaphone} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: outputText }} />
            </div>
          </div>
        </div>
        <Space>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={
                edited ? 'signin-button-accept' : 'signin-button-disabled'
              }
              disabled={!edited}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="reset"
              className="signin-button-decline"
              disabled={loading}
            >
              Reset
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  ) : (
    <Skeleton active />
  );
}

export default Promotional;
