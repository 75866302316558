import React from 'react';
import { Popover } from 'antd';
import TherapySelectButton from 'js/components/SearchBar/Therapies/TherapySelectButton';
import TherapyContent from 'js/components/SearchBar/Therapies/TherapyContent';

const PopoverTitle = () => (
  <div className="crio-dropdown-title">Select up to 5</div>
);

function TherapeuticAreasSelect({
  setShowWarning,
  setOpen,
  therapies,
  onChangeAreas,
  areas,
  open,
  getTitle,
}) {
  return (
    <div className="select-bar search-bar-card-wrapper">
      Therapeutic Areas
      <Popover
        placement="bottomLeft"
        title={<PopoverTitle />}
        overlayClassName="option-box"
        onOpenChange={(e) => {
          setShowWarning(false);
          setOpen(!e);
        }}
        content={
          <TherapyContent
            therapies={therapies}
            onChangeAreas={onChangeAreas}
            areas={areas}
          />
        }
        trigger="click">
        {/* Antd popover trigger "click" doesn't work without wrapping this component with <></> for some reason */}
        <>
          <TherapySelectButton open={open} areas={areas} getTitle={getTitle} />
        </>
      </Popover>
    </div>
  );
}

export default TherapeuticAreasSelect;
