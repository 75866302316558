import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Spin } from 'antd';
import EmailTamplates from './EmailTamplates';
import LookUpDatas from './LookUpDatas';
import Promotional from './Promotional';
import TermsTemplate from './TermsTemplate';

/**
 * Renders the ManageTemplate component, which displays different tabs for managing templates
 * based on the user's permissions.
 * @returns A JSX element that displays the appropriate tabs based on the user's permissions.
 */
function ManageTemplate() {
  const user = useSelector((state) => state.user);
  return (
    <>
      {user.isSuperUser === 1 ? (
        <div className="my-list-container body-container-tab">
          <div className="my-list-header tabs-header">Templates</div>
          <div className="my-list-body">
            <Tabs
              defaultActiveKey="1"
              type="card"
              destroyInactiveTabPane
              items={[
                {
                  label: `Email Templates`,
                  key: '1',
                  children: <EmailTamplates />,
                },
                {
                  label: `Terms & Conditions`,
                  key: '2',
                  children: <TermsTemplate />,
                },
                {
                  label: `LookUp Data`,
                  key: '3',
                  children: <LookUpDatas />,
                },
                {
                  label: `Promotional Banner`,
                  key: '4',
                  children: <Promotional />,
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <div className="crio-loading" style={{ height: '70vh' }}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default ManageTemplate;
