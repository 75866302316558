import React, { useState } from 'react';
import { Form, Input, notification } from 'antd';
import CommonService from '../../services/commonService';

/**
 * A functional component that creates a new lookup value for a given category.
 * @param {{string}} cat - The category for which the lookup value is being created.
 * @param {{Array}} values - An array of existing lookup values for the given category.
 * @param {{function}} updateList - A function to update the list of lookup values after a new value is created.
 * @returns A form to create a new lookup value.
 */
function CreateLookup({ cat, values, updateList }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  /**
   * Creates a lookup in the database with the given value and category.
   * If the value already exists in the category, an error message is displayed.
   * @param {{Object}} e - The event object containing the value to add.
   */
  const createLookup = (e) => {
    let flag = false;
    values.map((lookup) => {
      if (lookup.value === e.value) {
        flag = true;
      }
    });
    if (flag) {
      form.setFields([
        {
          name: 'value',
          errors: [e.value + ' is already present in ' + cat + ' ...'],
        },
      ]);
    } else {
      setLoading(true);
      let payload = {
        key: cat,
        value: e.value,
        isActive: 1,
        sortOrder: null,
      };
      CommonService.createLookup(payload)
        .then((res) => {
          updateList(res);
          setLoading(false);
          form.resetFields();
          notification.success({
            message: e.value + ' added successfully!',
          });
        })
        .catch(() => {
          notification.error({
            message: 'Something went wrong!! please try again...',
          });
          setLoading(false);
        });
    }
  };
  return (
    <Form form={form} onFinish={createLookup}>
      <Form.Item
        name={'value'}
        rules={[
          {
            required: true,
            message: 'Please Enter Lookup value',
          },
        ]}>
        <Input
          className="signin-input"
          placeholder="Enter new Lookup value"
          disabled={loading}
        />
      </Form.Item>
    </Form>
  );
}

export default CreateLookup;
