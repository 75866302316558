import {
  Button,
  Card,
  Carousel,
  Checkbox,
  Form,
  Input,
  notification,
  Select,
  Spin,
} from 'antd';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Logo from '../../../assets/images/CRIO_logo.png';
import SponsorService from '../../services/sponsorService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * A functional component that renders a sign up form for users. The component
 * uses the SponsorService to verify the signup token, get the terms and conditions,
 * and sign up the user. The component also uses Redux to get the therapeutic area options.
 * @returns A JSX element that renders the sign up form.
 */
function Signup() {
  let { token } = useParams();
  const [signupForm] = Form.useForm();
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const signupRef = useRef();
  const options = useSelector((state) => state.therapeuticArea);
  const [terms, setTerms] = useState('');
  const [termsLoading, setTermsLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [initialValue, setInitialValue] = useState();
  /**
   * Signs up a user with the given values and token using the SponsorService.
   * @param {{Object}} values - An object containing the user's signup information.
   */
  const signup = (values) => {
    setLoading(true);
    SponsorService.signupUser(values, token)
      .then(() => {
        signupRef.current.next();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({ message: err.response.data.error.message });
      });
  };
  /**
   * Accepts the terms and conditions for the sponsor service by setting the SDIR_SPONSOR_TOKEN
   * in local storage and making a request to the SponsorService to accept the terms. If the request
   * is successful, the user is redirected to the home page. If the request fails, the SDIR_SPONSOR_TOKEN
   * is removed from local storage and an error notification is displayed.
   */
  const acceptTerms = () => {
    localStorage.setItem('SDIR_SPONSOR_TOKEN', token);
    setTermsLoading(true);
    SponsorService.acceptedTerms()
      .then(() => {
        window.location.href = '/';
      })
      .catch(() => {
        setTermsLoading(false);
        localStorage.removeItem('SDIR_SPONSOR_TOKEN');
        notification.error({
          message: 'Failed to Accept Terms and Conditions..',
        });
      });
  };

  /**
   * useEffect hook that verifies a signup token and sets the initial value of the component
   * and fetches the terms and conditions from the server.
   * @param {{string}} token - the signup token to verify
   */
  useEffect(() => {
    SponsorService.verifySignupToken(token)
      .then((res) => {
        setInitialValue(res.data.data);
        setPageLoading(false);
        console.log(res.data.data);
      })
      .catch((err) => {
        setError(err.response.data.error.message);
        setPageLoading(false);
      });
    SponsorService.getTermsAndConditions()
      .then((res) => {
        setTerms(res.data.data.value);
      })
      .catch(() => {
        notification.error({
          message: 'Failed to get Terms and Conditions..',
        });
      });
  }, []);
  return pageLoading && !initialValue ? (
    <div className="loader-wrappper">
      <Spin size="large" />
    </div>
  ) : error ? (
    <div className="expired-div">{error}</div>
  ) : (
    <div className="login-page-body">
      <Carousel
        dots={false}
        key={'signupCor'}
        ref={signupRef}
        accessibility={false}
        swipe={false}
        swipeToSlide={false}
        draggable={false}
      >
        <div className="card-wrapper">
          <Card className="login-page-card">
            <div className="signin">
              <div className="sign-in-logo-text">
                <img src={Logo} alt="Crio Logo" />
                Welcome {initialValue.firstName
                  ? initialValue.firstName
                  : ''}{' '}
                {initialValue.lastName ? initialValue.lastName : ''}!
              </div>
              <div className="signin-form">
                <Form
                  onFinish={signup}
                  form={signupForm}
                  layout="vertical"
                  requiredMark={false}
                  initialValues={initialValue}
                >
                  <Form.Item
                    name="email"
                    label="Work Email Address"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your work email address',
                      },
                      {
                        type: 'email',
                        message:
                          'Invalid email address. Only use numbers, letters, or + - _ characters',
                      },
                    ]}
                  >
                    <Input className="signin-input" name="email" readOnly />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message:
                          'Password must be at least 8 characters with 1 upper case letter, 1 number and special character',
                      },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,20}$/,
                        message:
                          'Password must be at least 8 characters with 1 upper case letter, 1 number and special character',
                      },
                    ]}
                  >
                    <Input.Password className="signin-input" name="password" />
                  </Form.Item>
                  <Form.Item
                    name="companyName"
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your company name',
                      },
                    ]}
                  >
                    <Input
                      className="signin-input"
                      name="companyName"
                      readOnly
                    />
                  </Form.Item>
                  <Form.Item name="userRole" label="Your role (optional)">
                    <Input className="signin-input" name="userRole" />
                  </Form.Item>
                  <Form.Item
                    name="therapeuticArea"
                    label="Therapeutic area of interest (optional)"
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      suffixIcon={<FontAwesomeIcon icon={faCaretDown} />}
                      maxTagCount="responsive"
                      className="crio-input-select crio-input"
                      placeholder="-Select Therapeutic Areas-"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {options.map((el) => {
                        return (
                          <Select.Option
                            value={el.therapeuticId}
                            key={el.therapeuticId}
                          >
                            {el.therapeuticName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="emailNotification" valuePropName="checked">
                    <Checkbox className="terms-checkbox check-options">
                      Sign up for our biweekly email campaign to receive new
                      sites related to these therapeutic areas
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="signin-button"
                      loading={loading}
                    >
                      Next
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Card>
        </div>
        <div className="card-wrapper">
          <Card className="login-page-card">
            <div className="signin">
              <div className="sign-in-logo-text">
                <img src={Logo} alt="Crio Logo" />
                Review Terms & Conditions
              </div>

              <div
                className="agreement-body-text signin-form"
                dangerouslySetInnerHTML={{ __html: terms }}
              ></div>
              <div className="signin-form checkbox-div">
                <Checkbox
                  onChange={(e) => {
                    setAcceptedTerms(e.target.checked);
                  }}
                  className="terms-checkbox check-options"
                >
                  I accept the terms and conditions of service
                </Checkbox>
              </div>
              <div className="signin-form">
                <Button
                  type="primary"
                  style={{ marginTop: '0' }}
                  className="signin-button"
                  loading={termsLoading}
                  disabled={!acceptedTerms}
                  onClick={() => {
                    acceptTerms();
                  }}
                >
                  CONTINUE
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Carousel>
    </div>
  );
}

export default Signup;
