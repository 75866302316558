import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Space,
  Modal,
  Checkbox,
  notification,
  Row,
  Col,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { elastic as Menu } from 'react-burger-menu';
import Logo from '../../assets/images/CRIO_Logo_White.png';
import Logo2 from '../../assets/images/CRIO_logo2.png';
import Logo3 from '../../assets/images/CRIO_logo.png';
import SponsorService from '../services/sponsorService';
import { saveUser } from '../features/user';
import { saveTherapeuticArea } from '../features/therapeuticArea';
import { saveFacility } from '../features/facility';
import { saveCollections } from '../features/collections';
import CommonService from '../services/commonService';
import { saveBannedEmail } from '../features/bannedEmail';
import { savePromotionalBanner } from '../features/promotionalBanner';

/**
 * A functional component that renders the header for the sponsor page. The header contains
 * a logo, a menu for logged in users, and buttons for logging in and scheduling a demo for
 * non-logged in users.
 * @returns The SponsorHeader component.
 */
const SponsorHeader = () => {
  const user = useSelector((state) => state.user);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [terms, setTerms] = useState('');
  const [termsLoading, setTermsLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const dispatch = useDispatch();

  /**
   * Creates an array of objects representing the navigation items for the user.
   * If the user is a super user, the array will contain an additional item for managing the admin.
   * @param {{boolean}} user.isSuperUser - a boolean indicating whether the user is a super user or not.
   * @returns An array of objects representing the navigation items for the user.
   */
  const items = user.isSuperUser
    ? [
        {
          label: <a href="/profile">Profile</a>,
          key: '0',
        },
        {
          label: <a href="/my-list">My list</a>,
          key: '1',
        },
        {
          label: <a href="/crio-admin/dashboard">Manage Admin</a>,
          key: '2',
        },
        {
          label: (
            <a
              onClick={() => {
                SponsorService.logout();
              }}
            >
              Logout
            </a>
          ),
          key: '3',
        },
      ]
    : [
        {
          label: <a href="/profile">Profile</a>,
          key: '0',
        },
        {
          label: <a href="/my-list">My list</a>,
          key: '1',
        },
        {
          label: (
            <a
              onClick={() => {
                SponsorService.logout();
              }}
            >
              Logout
            </a>
          ),
          key: '2',
        },
      ];

  /**
   * Accepts the terms and conditions for the user and updates the user's information
   * in the database.
   */
  const acceptTerms = () => {
    setTermsLoading(true);
    SponsorService.acceptedTerms()
      .then(() => {
        setTermsLoading(false);
        setTermsModalOpen(false);
        let temp = Object.assign({}, user);
        temp.isTermsConditionAccepted = 1;
        dispatch(saveUser(temp));
      })
      .catch(() => {
        setTermsLoading(false);
        notification.error({
          message: 'Failed to Accept Terms and Conditions..',
        });
      });
  };

  /**
   * useEffect hook that fetches data from various endpoints and saves them to the Redux store.
   * If a sponsor token is present in local storage, it also fetches the user's profile and collection list.
   * If the user has not accepted the terms and conditions, it logs them out and redirects them to the homepage.
   */
  useEffect(() => {
    CommonService.getTherapeuticAreas().then((res) => {
      dispatch(saveTherapeuticArea(res.data.data));
    });
    CommonService.getFacility().then((res) => {
      dispatch(saveFacility(res.data.data));
    });
    CommonService.getBannedEmails().then((res) => {
      dispatch(saveBannedEmail(res.data.data));
    });
    CommonService.getPromo().then((res) => {
      dispatch(savePromotionalBanner(res.data.data));
    });
    if (localStorage.getItem('SDIR_SPONSOR_TOKEN')) {
      SponsorService.getTermsAndConditions().then((res) => {
        setTerms(res.data.data.value);
      });
      SponsorService.getProfile()
        .then((res) => {
          dispatch(saveUser(res.data.data));
          if (
            (!res.data.data.isTermsConditionAccepted ||
              res.data.data.isTermsConditionAccepted === 0) &&
            window.location.pathname !== '/pageNotFound'
          ) {
            if (window.location.pathname !== '/') {
              window.location.href = '/';
            }
            SponsorService.logout();
          }
        })
        .catch(() => {
          SponsorService.logout();
        });
      SponsorService.getCollectionList().then((res) => {
        dispatch(saveCollections(res.data.data));
      });
    }
  }, [localStorage.getItem('SDIR_SPONSOR_TOKEN')]);
  return (
    <>
      <Modal
        centered
        open={termsModalOpen}
        closable={false}
        className="collection-modal"
        footer={null}
      >
        <div className="signin">
          <div className="sign-in-logo-text">
            <img src={Logo3} alt="Crio Logo" />
            Review Terms & Conditions
          </div>

          <div
            className="agreement-body-text signin-form"
            dangerouslySetInnerHTML={{ __html: terms }}
          ></div>
          <div className="signin-form checkbox-div">
            <Checkbox
              onChange={(e) => {
                setAcceptedTerms(e.target.checked);
              }}
              className="terms-checkbox check-options"
            >
              I accept the terms and conditions of service
            </Checkbox>
          </div>
          <Row className="signin-form" gutter={24}>
            <Col span={12} style={{ paddingLeft: '0' }}>
              <Button
                type="primary"
                style={{ marginTop: '0' }}
                className={
                  acceptedTerms
                    ? 'signin-button-accept'
                    : 'signin-button-disabled'
                }
                loading={termsLoading}
                disabled={!acceptedTerms}
                onClick={() => {
                  acceptTerms();
                }}
              >
                Accept
              </Button>
            </Col>
            <Col span={12} style={{ paddingRight: '0' }}>
              <Button
                type="secondary"
                style={{ marginTop: '0' }}
                className="signin-button-decline"
                loading={termsLoading}
                onClick={() => {
                  SponsorService.logout();
                }}
              >
                Decline
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <div position="static" className="header">
        <div className="logo">
          <img
            src={Logo}
            alt="logo"
            onClick={() => {
              window.location.href = '/';
            }}
          />
        </div>
        <div className="logo2">
          <img
            src={Logo2}
            alt="logo"
            onClick={() => {
              window.location.href = '/';
            }}
          />
        </div>
        {window.location.pathname !== '/login' ? (
          user.length === 0 && !SponsorService.checkLoggedIn() ? (
            <>
              <Menu right>
                <Button
                  type="text"
                  style={{ color: 'white' }}
                  className="crio-signup-button"
                  onClick={() => {
                    window.sessionStorage.setItem('loginFormId', 1);
                    window.location.href = '/login';
                  }}
                >
                  LOGIN
                </Button>

                <Button
                  type="primary"
                  className="crio-signup-button crio-signup-button-big"
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_SCHEDULE_A_DEMO_URL,
                      '_blank',
                    );
                  }}
                >
                  GET ACCESS
                </Button>
              </Menu>
              <ul className="crio-li-menu">
                <li>
                  <Button
                    type="text"
                    style={{ color: 'white' }}
                    className="crio-login-button-big"
                    onClick={() => {
                      window.sessionStorage.setItem('loginFormId', 1);
                      window.location.href = '/login';
                    }}
                  >
                    LOGIN
                  </Button>
                </li>
                <li>
                  <Button
                    type="primary"
                    className="crio-signup-button-big"
                    onClick={() => {
                      window.open(
                        process.env.REACT_APP_SCHEDULE_A_DEMO_URL,
                        '_blank',
                      );
                    }}
                  >
                    GET ACCESS
                  </Button>
                </li>
              </ul>
            </>
          ) : (
            <div className="profile-nav">
              <Dropdown menu={{ items }} trigger={['click']}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{ cursor: 'pointer' }}
                >
                  <Space>
                    <div className="profile-nav-img">
                      {user.profilePic ? (
                        <div className="login-profile-pic">
                          <img src={user.profilePic} alt="profilePic" />
                        </div>
                      ) : (
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          fontSize={30}
                          style={{ marginRight: '10px' }}
                        />
                      )}
                    </div>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </Space>
                </a>
              </Dropdown>
            </div>
          )
        ) : null}
      </div>
    </>
  );
};

export default SponsorHeader;
