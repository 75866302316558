import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Divider, Tooltip } from 'antd';
import util from '../../../utils/util';
import TherapeuticAreasSelect from 'js/components/SearchBar/Therapies/TherapeuticAreasSelect';
import FacilitiesSelect from 'js/components/SearchBar/Facilities/FacitiliesSelect';

/**
 * A component that renders a search bar with options to select therapeutic areas and facilities/equipment.
 * @param {{function}} setSelectedFacility - A function to set the selected facility.
 * @param {{function}} setSelectedAreas - A function to set the selected areas.
 * @param {{function}} onSearch - A function to execute when the search button is clicked.
 * @param {{array}} areas - An array of selected areas.
 * @param {{function}} setAreas - A function to set the selected areas.
 * @param {{array}} facility - An array of selected facilities.
 * @param {{function}} setFacility - A function to set the selected facility.
 * @returns A search bar component with options to select therapeutic
 */
function SearchBarView({
  setSelectedFacility,
  setSelectedAreas,
  onSearch,
  areas,
  setAreas,
  facility,
  setFacility,
}) {
  let therapies = useSelector((state) => state.therapeuticArea);
  let facilities = useSelector((state) => state.facility);
  const [open, setOpen] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  const search = () => {
    if (areas.length === 0) {
      setShowWarning(true);
    } else {
      onSearch();
    }
  };

  /**
   * Updates the facility list in session storage and sets the facility state variables.
   * @param {{list}} list - The new facility list to be stored in session storage.
   */
  const onChangeFacility = (list) => {
    window.sessionStorage.setItem('facility', list);
    setFacility(list);
    setSelectedFacility(list);
  };

  /**
   * Updates the areas in session storage and sets the areas and selected areas state to the given list.
   * @param {{Array}} list - The list of areas to update to.
   */
  const onChangeAreas = (list) => {
    window.sessionStorage.setItem('areas', list);
    setAreas(list);
    setSelectedAreas(list);
  };

  /**
   * Get the title of a given list using the utility function getTitle.
   * @param {{Array}} list - The list to get the title of.
   * @returns The title of the list.
   */
  const getTitle = (list) => {
    return util.getTitle(list);
  };

  const closeTooltip = (event) => {
    const searchIcon = document.querySelector('.search-button');

    // Check if the click target is the search icon or its parent element
    if (!searchIcon.contains(event.target)) {
      // Handle the click event for the rest of the page here
      setShowWarning(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeTooltip);
    return () => {
      document.removeEventListener('click', closeTooltip);
    };
  }, []);

  /**
   * useEffect hook that runs when the component mounts. It checks if the therapeutic or facilityData arrays are empty, and if so,
   * removes the "facility" and "areas" items from sessionStorage. If "facility" and "areas" items are present in sessionStorage,
   * it sets the facility and areas state variables to their respective values.
   */
  useEffect(() => {
    if (therapies.length === 0 || facilities.length === 0) {
      window.sessionStorage.removeItem('facility');
      window.sessionStorage.removeItem('areas');
    }
    if (window.sessionStorage.getItem('facility')) {
      setFacility(window.sessionStorage.getItem('facility').split(','));
      setSelectedFacility(window.sessionStorage.getItem('facility').split(','));
    }
    if (window.sessionStorage.getItem('areas')) {
      setAreas(window.sessionStorage.getItem('areas').split(','));
      setSelectedAreas(window.sessionStorage.getItem('areas').split(','));
    }
  }, []);

  return (
    <>
      <Card
        className="search-card"
        onAbort={() => {
          setShowWarning(false);
        }}>
        <Tooltip
          placement="bottomLeft"
          title={'Please enter a therapeutic area to start searching'}
          open={showWarning}
          overlayInnerStyle={{
            border: '2px solid #FFB7B7',
            borderRadius: '10px',
          }}
          overlayClassName="search-warning-tooltip">
          <div className="search-bar">
            <TherapeuticAreasSelect
              setShowWarning={setShowWarning}
              setOpen={setOpen}
              therapies={therapies}
              onChangeAreas={onChangeAreas}
              areas={areas}
              open={open}
              getTitle={getTitle}
            />
            <Divider type="vertical" className="vertical-divider-class" />
            <FacilitiesSelect
              facilities={facilities}
              onChangeFacility={onChangeFacility}
              facility={facility}
              setShowWarning={setShowWarning}
              setOpen={setOpen}
              open={open}
              getTitle={getTitle}
            />
            <Divider type="vertical" className="vertical-divider-class" />
            <div className={'search-div'}>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className={'search-button'}
                onClick={search}
              />
            </div>
          </div>
        </Tooltip>
      </Card>
    </>
  );
}

export default SearchBarView;
