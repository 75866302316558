import React, { useState } from 'react';
import { Button, Form, Input, Modal, notification, Space } from 'antd';
import SponsorService from '../../services/sponsorService';

/**
 * A modal component that allows the user to block or unblock a record.
 * @param {{record}} record - The record to block or unblock.
 * @param {{string}} action - The action to perform (block or unblock).
 * @param {{function}} handleModalCancel - A function to handle when the modal is cancelled.
 * @param {{function}} updateTable - A function to update the table after the action is performed.
 * @returns A modal component with the ability to block or unblock a record.
 */
export default function ActionModal({
  record,
  action,
  handleModalCancel,
  updateTable,
}) {
  const actionModal = true;
  const [blackListReason, setBlackListReason] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  /**
   * Handles the blocking of a user by sending a request to the SponsorService with the
   * necessary payload. Updates the table with the new blocked user and closes the modal.
   */
  const handleBlock = () => {
    setLoading(true);
    const payload = {
      email: record.email,
      blackListReason: blackListReason,
      action: 'block',
    };
    SponsorService.userAction(payload)
      .then(() => {
        updateTable(record.email, 'block');
        handleModalCancel();
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: 'Something went wrong !! Please try again..',
        });
        setLoading(true);
      });
  };

  /**
   * Handles the unblocking of a user by sending a request to the SponsorService API.
   */
  const handleUnblock = () => {
    setLoading(true);
    const payload = {
      email: record.email,
      action: 'unblock',
    };
    SponsorService.userAction(payload)
      .then((response) => {
        console.log(response.data);
        updateTable(record.email, 'unblock');
        handleModalCancel();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: 'Something went wrong !! Please try again..',
        });
      });
  };

  return (
    <div>
      {action === 'block' ? (
        <Modal
          title={[
            record.firstName
              ? 'Sure to block ' +
                record.firstName +
                ' ' +
                record.lastName +
                ' ?'
              : 'Sure to block ' + record.email + ' ?',
          ]}
          open={actionModal}
          onOk={handleBlock}
          onCancel={handleModalCancel}
          footer={false}
        >
          <Form
            form={form}
            onFinish={handleBlock}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name={'blackListReason'}
              label="Block Reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter reason to block.',
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={200}
                value={blackListReason}
                style={{
                  height: 80,
                  width: '100%',
                }}
                onChange={(e) => {
                  setBlackListReason(e.target.value);
                }}
              />
            </Form.Item>
            <Space>
              <Form.Item>
                <Button
                  key="back"
                  className="cancel-user-btn"
                  onClick={handleModalCancel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  key="submit"
                  className=" block-button"
                  loading={loading}
                  htmlType="submit"
                >
                  Block
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Modal>
      ) : (
        <Modal
          title={['Unblock ', record.firstName, ' ', record.lastName]}
          open={actionModal}
          onOk={handleUnblock}
          onCancel={handleModalCancel}
          footer={[
            <Button
              key="back"
              className="cancel-user-btn"
              onClick={handleModalCancel}
              disabled={loading}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              className="unblock-user-btn"
              onClick={handleUnblock}
              loading={loading}
            >
              Unblock
            </Button>,
          ]}
        >
          <p>
            are you sure you want to unblock {record.firstName}{' '}
            {record.lastName} ?
          </p>
          <br />
          <br />
        </Modal>
      )}
    </div>
  );
}
