import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import FilterTableOrg from './FilterTableOrg';
import OrgTable from './OrgTable';
import SitePageService from '../../services/sitePageService';
let cancelToken;

/**
 * A React functional component that renders a page for managing organizations. The component
 * fetches a list of organizations from the server and allows the user to filter and search
 * through the list. The component also allows the user to change the page size and navigate
 * through the pages of the list.
 * @returns A React component that renders the ManageOrgs page.
 */
function ManageOrgs() {
  const user = useSelector((state) => state.user);
  const [orgCount, setOrgCount] = useState(0);
  const [optedInCheck, setOptedInCheck] = useState();
  const [notOptedInCheck, setNotOptedInCheck] = useState();
  const [search, setSearch] = useState('');
  const [orgs, setOrgs] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(false);

  /**
   * Applies filters to the list of organizations and updates the state with the filtered results.
   * @param {{Object}} params - An object containing the search query, optedIn status, notOptedIn status, page size, and page number.
   */
  const applyFilters = async (params) => {
    setLoading(true);
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Canceling previous call.');
    }
    cancelToken = axios.CancelToken.source();
    let payload = {
      searchOrgName: params.search,
      optedIn: params.optedIn,
      notOptedIn: params.notOptedIn,
    };
    try {
      let response = await SitePageService.getAllorgsForManage(
        payload,
        params.pageSize,
        params.pageNum,
        cancelToken.token,
      );
      setPageNum(params.pageNum);
      setPageSize(params.pageSize);
      setOrgCount(response.data.data.orgsCount);
      setOrgs(response.data.data.organizations);
      setLoading(false);
    } catch (err) {}
  };
  // Clears the current selection and applies the default filters to the search results.
  const clearSelection = () => {
    const queryParams = {
      search: search,
      optedIn: 0,
      notOptedIn: 0,
      pageNum: 0,
      pageSize: pageSize,
    };
    setOptedInCheck(false);
    setNotOptedInCheck(false);
    applyFilters(queryParams);
  };
  /**
   * Changes the optedIn value in the query parameters and applies the filters.
   * @param {{any}} data - The new value of optedIn.
   */
  const changeOptedIn = (data) => {
    const queryParams = {
      search: search,
      optedIn: data ? 1 : 0,
      notOptedIn: notOptedInCheck ? 1 : 0,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    setOptedInCheck(data);
    applyFilters(queryParams);
  };
  /**
   * Changes the notOptedIn value in the query parameters and applies the filters.
   * @param {{any}} data - the data to set the notOptedIn value to
   */
  const changeNotOptedIn = (data) => {
    const queryParams = {
      search: search,
      optedIn: optedInCheck ? 1 : 0,
      notOptedIn: data ? 1 : 0,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    setNotOptedInCheck(data);
    applyFilters(queryParams);
  };
  /**
   * Updates the search query parameters and applies any filters to the search results.
   * @param {{Object}} searchData - The search query parameters to update.
   */
  const changeSearch = (searchData) => {
    const queryParams = {
      search: searchData,
      optedIn: optedInCheck ? 1 : 0,
      notOptedIn: notOptedInCheck ? 1 : 0,
      pageNum: pageNum,
      pageSize: pageSize,
    };
    setSearch(searchData);
    applyFilters(queryParams);
  };
  /**
   * Changes the page size of the current page and applies the filters with the new page size.
   * @param {{number}} ps - The new page size to set.
   */
  const changePageSize = (ps) => {
    const queryParams = {
      search: search,
      optedIn: optedInCheck ? 1 : 0,
      notOptedIn: notOptedInCheck ? 1 : 0,
      pageNum: pageNum,
      pageSize: ps,
    };
    setPageSize(ps);
    applyFilters(queryParams);
  };
  /**
   * Updates the page number and applies filters to the page based on the new page number.
   * @param {{any}} data - The new page number.
   */
  const pageChange = (data) => {
    const queryParams = {
      search: search,
      optedIn: optedInCheck ? 1 : 0,
      notOptedIn: notOptedInCheck ? 1 : 0,
      pageNum: data - 1,
      pageSize: pageSize,
    };
    setPageNum(data - 1);
    applyFilters(queryParams);
  };
  // Runs the applyFilters function with the given query parameters when the component mounts.
  useEffect(() => {
    const queryParams = {
      search: '',
      optedIn: 0,
      notOptedIn: 0,
      pageNum: 0,
      pageSize: pageSize,
    };
    applyFilters(queryParams);
  }, []);
  return (
    <>
      {user.isSuperUser === 1 ? (
        <div className="body-container-tab">
          <div className="tabs-header">
            Organizations
            <p>{orgCount}</p>
          </div>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <div className="sites-left">
                <FilterTableOrg
                  optedInCheck={optedInCheck}
                  setOptedInCheck={changeOptedIn}
                  notOptedInCheck={notOptedInCheck}
                  setNotOptedInCheck={changeNotOptedIn}
                  clearSelection={clearSelection}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={19} lg={19} xl={19}>
              <div className="sites-left">
                <OrgTable
                  setSearch={changeSearch}
                  changePageSize={changePageSize}
                  orgs={orgs}
                  orgCount={orgCount}
                  pageChange={pageChange}
                  loading={loading}
                  pgSize={pageSize}
                  pgNo={pageNum}
                />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="crio-loading" style={{ height: '70vh' }}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default ManageOrgs;
