import { createSlice } from '@reduxjs/toolkit';

// A Redux slice that manages the state of banned email addresses.
export const bannedEmailSlice = createSlice({
  name: 'bannedEmail',
  initialState: [],
  reducers: {
    saveBannedEmail: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveBannedEmail } = bannedEmailSlice.actions;
export default bannedEmailSlice.reducer;
