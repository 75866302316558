import React from 'react';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
  faBuilding,
  faClipboard,
  faUserPlus,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs } from 'antd';
import InviteUser from './InviteUser';
import ManageOrgs from './ManageOrgs';
import ManageSites from './ManageSites';
import ManageTemplate from './ManageTemplate';
import ManageUsers from './ManageUsers';

/**
 * Renders the Admin Dashboard component, which displays a tabbed interface for managing users, organizations,
 * sites, templates, and inviting new users.
 * @returns {JSX.Element} - The rendered Admin Dashboard component.
 */
function AdminDashboard() {
  return (
    <div className="tab-wrapper">
      <Tabs
        defaultActiveKey="manage_users"
        tabPosition="left"
        destroyInactiveTabPane
        className="admin-tabs"
        items={[
          {
            label: (
              <span>
                <FontAwesomeIcon icon={faUsers} className="tab-icon-style" />
                Manage Users
              </span>
            ),
            key: 'manage_users',
            children: <ManageUsers />,
          },
          {
            label: (
              <span>
                <FontAwesomeIcon icon={faGlobe} className="tab-icon-style" />
                Manage Organizations
              </span>
            ),
            key: 'manage_organizations',
            children: <ManageOrgs />,
          },
          {
            label: (
              <span>
                <FontAwesomeIcon icon={faBuilding} className="tab-icon-style" />
                Manage Sites
              </span>
            ),
            key: 'manage_sites',
            children: <ManageSites />,
          },
          {
            label: (
              <span>
                <FontAwesomeIcon
                  icon={faClipboard}
                  className="tab-icon-style"
                />
                Manage Templates
              </span>
            ),
            key: 'manage_templates',
            children: <ManageTemplate />,
          },
          {
            label: (
              <span>
                <FontAwesomeIcon icon={faUserPlus} className="tab-icon-style" />
                Invite User
              </span>
            ),
            key: 'invite_user',
            children: <InviteUser />,
          },
        ]}
      />
    </div>
  );
}

export default AdminDashboard;
