import React, { useEffect, useState } from 'react';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faPen,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Row,
  Skeleton,
  Form,
  Input,
  Modal,
  notification,
  Space,
} from 'antd';
import SponsorService from '../services/sponsorService';

/**
 * A component that displays a card for a reached out list. The card displays the name of the list, the number of sites in the list, and a carousel of the sites in the list. The card also provides options to delete the list and to rename the list.
 * @param {{collectionData: object, setList: function, setReachedCount: function, list: array}} props - An object containing the collection data, a function to set the list, a function to set the reached count, and an array of lists.
 * @returns A React component that displays a reached out list card.
 */
function ReachedOutListCard({
  collectionData,
  setList,
  setReachedCount,
  list,
}) {
  const [editVisible, setEditVisible] = useState(false);
  const sites = collectionData.sites;
  const [box, setBox] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [editForm] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // Scrolls the content of a container element to the left by the width of the container.
  const btnPrev = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
  };

  // Scrolls the content of a container horizontally to the next page.
  const btnNext = () => {
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
  };
  /**
   * Handles the deletion of a reached collection. Sends a request to the SponsorService to delete the
   * collection with the given collection ID. If the request is successful, updates the list of reached
   * collections and the reached count, and closes the delete confirmation modal. If the request fails,
   * displays an error notification.
   */
  const handleOk = () => {
    setLoading(true);
    SponsorService.deleteReachedCollection(collectionData.collectionId)
      .then((res) => {
        setList(res.data.data);
        setReachedCount(res.data.data.length);
        setLoading(false);
        setOpenDelete(false);
      })
      .catch(() => {
        notification.error({
          message: 'Deleting collection failed !! Please try again...',
        });
        setLoading(false);
      });
  };
  const handleCancel = () => {
    setOpenDelete(false);
  };
  // Handles the edit functionality by resetting the edit form fields and setting the edit state to false.
  const handleEdit = () => {
    setEdit(false);
    editForm.resetFields();
  };
  const handleCancelEdit = () => {
    setEdit(false);
    editForm.resetFields();
  };
  /**
   * Renames a collection with the given values and updates the list of collections.
   * @param {{Object}} values - The new values for the collection.
   * @returns None
   */
  const rename = (values) => {
    setEditLoading(true);
    SponsorService.renameCollection(collectionData.collectionId, values)
      .then((res) => {
        let tempList = [];
        list.map((el) => {
          if (el.collectionId === collectionData.collectionId) {
            tempList.push(res.data.data);
          } else {
            tempList.push(el);
          }
        });
        setList(tempList);
        setReachedCount(tempList.length);
        setEditLoading(false);
        handleEdit();
      })
      .catch(() => {
        notification.error({
          message: 'Renaming collection failed !! Please try again...',
        });
        setEditLoading(false);
      });
  };

  /**
   * useEffect hook that sets the box element and the initial value of the collection name field
   * in the edit form.
   */
  useEffect(() => {
    setBox(document.querySelector('.list-no-' + collectionData.collectionId));
    editForm.setFields([
      {
        name: 'collectionName',
        value: collectionData.collectionName,
      },
    ]);
  }, []);
  return (
    <>
      <Modal
        title={
          <div className="remove-collection-modal-header">
            <div className="remove-collection-modal-header-icon">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </div>
            <div>
              <div className="remove-collection-modal-header-text">
                Delete Collection?
              </div>
              <div className="remove-collection-modal-body">
                Are you sure you want to delete {collectionData.collectionName}?
              </div>
              <div className="remove-collection-modal-footer">
                <Space size={20}>
                  <Button
                    size="large"
                    className="remove-collection-modal-footer-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="text"
                    onClick={handleOk}
                    className="remove-collection-modal-footer-remove"
                    loading={loading}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        }
        centered
        open={openDelete}
        onOk={handleOk}
        onCancel={handleCancel}
        className="collection-modal"
        closable={false}
        footer={false}
      />
      <Modal
        title={
          <div className="collection-modal-header">Rename Collection </div>
        }
        open={edit}
        onOk={handleEdit}
        onCancel={handleCancelEdit}
        className="collection-modal"
        centered
        footer={null}
        destroyOnClose
      >
        <Form form={editForm} onFinish={rename}>
          <div className="new-collection-modal-body">
            <div className="collection-modal-body-list-left-text new-collection-modal-body-label">
              <a>Collection Name</a>
            </div>
            <Form.Item
              name={'collectionName'}
              rules={[
                {
                  required: true,
                  message: 'Please Enter collection name',
                },
              ]}
            >
              <Input
                className="signin-input"
                placeholder="Enter Collection Name"
              />
            </Form.Item>
          </div>
          <div className="new-collection-modal-footer">
            <Form.Item>
              <Space size={20}>
                <Button
                  type="primary"
                  size="large"
                  className="new-collection-modal-footer-save"
                  htmlType="submit"
                  loading={editLoading}
                >
                  Save
                </Button>
                <Button
                  size="large"
                  onClick={handleCancelEdit}
                  className="new-collection-modal-footer-cancel"
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div className="list-card">
        <Row>
          <Col xs={24} sm={24} md={18}>
            <Row>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <div className="list-card-header">
                  <p
                    onMouseEnter={() => {
                      setEditVisible(true);
                    }}
                    onMouseLeave={() => {
                      setEditVisible(false);
                    }}
                    onClick={() => {
                      editForm.setFields([
                        {
                          name: 'collectionName',
                          value: collectionData.collectionName,
                        },
                      ]);
                      setEdit(true);
                    }}
                  >
                    <span>{collectionData.countOfSites}</span>{' '}
                    {collectionData.collectionName}
                    {editVisible ? (
                      <FontAwesomeIcon
                        icon={faPen}
                        className="collection-icon"
                        style={{ color: '#999999' }}
                      />
                    ) : null}
                  </p>
                </div>
              </Col>
              <Col xs={0} sm={0} md={6} lg={6} xl={6}>
                <div className="sites-scroller">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="collection-icon"
                    onClick={btnPrev}
                  />
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="collection-icon"
                    onClick={btnNext}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={18}>
            <div className="sites-carousel-container">
              <div
                className={
                  'list-sites-carousel list-no-' + collectionData.collectionId
                }
              >
                {sites.map((site) => {
                  return (
                    <div
                      className="list-site-card"
                      key={site.siteId}
                      onClick={() => {
                        window.open('/sitepage/' + site.siteId, '_blank');
                      }}
                    >
                      <div className="list-site-card-img-wrapper">
                        {site.siteLogo ? (
                          <img
                            src={site.siteLogo}
                            alt={'site logo'}
                            className={'list-site-card-img'}
                          />
                        ) : (
                          <Skeleton.Image className={'list-site-card-img'} />
                        )}
                      </div>
                      <div className="list-site-card-p">
                        {site.siteName.length > 25
                          ? site.siteName.slice(0, 24) + '...'
                          : site.siteName}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <div className="list-card-action">
              <Button
                className="list-card-action-2"
                onClick={() => {
                  setOpenDelete(true);
                }}
              >
                {' '}
                Delete List
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ReachedOutListCard;
