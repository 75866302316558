import { Button, Result, Spin } from 'antd';
import { useEffect, useState } from 'react';
import SponsorService from '../../services/sponsorService';

/**
 * A component that checks if the user's session has timed out. If the user's session has timed out,
 * the component will redirect the user to the login page. Otherwise, the component will display a
 * loading spinner until the session status has been determined.
 * @returns A React component that displays a loading spinner or a session timeout message.
 */
const SessionTimeout = () => {
  const [loading, setLoading] = useState(true);

  // useEffect hook that checks if a sponsor token exists in local storage. If it does, it attempts to retrieve the sponsor's profile using SponsorService.getProfile(). If the profile is successfully retrieved, the user is redirected to the pageNotFound page. If the profile cannot be retrieved, the sponsor token is removed from local storage and the loading state is set to false. If no sponsor token exists in local storage, the loading state is set to false.
  useEffect(() => {
    if (localStorage.getItem('SDIR_SPONSOR_TOKEN')) {
      SponsorService.getProfile()
        .then(() => {
          window.location.href = '/pageNotFound';
        })
        .catch(() => {
          localStorage.removeItem('SDIR_SPONSOR_TOKEN');
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  });
  return loading ? (
    <div className="spin-loader-div-container">
      <Spin size="large" />
    </div>
  ) : (
    <Result
      status="500"
      title="Session Timeout"
      subTitle="Sorry, your session has been ended. Please login again."
      extra={
        <Button
          type="primary"
          onClick={() => {
            window.location.href = '/login';
          }}
          className="signin-button w-200">
          Login
        </Button>
      }
    />
  );
};
export default SessionTimeout;
