import React from 'react';
import { Button, Carousel, Col, Divider, Row, Space } from 'antd';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import dummySitelogo from '../../assets/images/dummySitelogo.png';

/**
 * A dummy site card component that displays a sample site card with placeholder data.
 * @returns A React component that displays a site card with placeholder data.
 */
function DummySiteCard() {
  const history = useHistory();
  return (
    <>
      <div className="search-site-card">
        <Row gutter={[24, 24]}>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="search-site-card-left-wrapper"
          >
            <div className="search-site-card-left">
              <Carousel>
                <div className="search-site-card-imgs" key={'dummyimg'}>
                  <img src={dummySitelogo} alt="Org Photo" />
                </div>
              </Carousel>
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Row gutter={[24, 24]} className="search-site-card-header">
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Space className={'search-site-card-header-left'}>
                  <FontAwesomeIcon icon={faBuilding} />
                  Adams Clinical
                </Space>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                className={'search-site-card-header-right'}
              >
                <div>MA, USA</div>
              </Col>
            </Row>
            <Row>
              <div className="search-site-card-descrition">
                Adams specializes in late-phase psychiatric and neurologic
                clinical trials. These studies look at investigational drugs
                that have already been tested by hundreds of people and …
                <Divider dashed className="site-card-divider" />
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="guest-card-wrapper">
        <div className="guest-header">
          Sponsors & CROs, want to connect with sites within your therapeutic
          areas
        </div>
        <div className="guest-button">
          <Button
            type="primary"
            className="crio-signup-button-big"
            onClick={() => {
              window.open(process.env.REACT_APP_SCHEDULE_A_DEMO_URL, '_blank');
            }}
          >
            GET ACCESS
          </Button>
        </div>
        <div className="guest-p">
          <p>
            Already have an account?
            <span
              onClick={() => {
                history.push('/login');
              }}
            >
              Login
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default DummySiteCard;
