import React from 'react';
import { Checkbox, Col } from 'antd';

function TherapySelectRow({ therapy, areas }) {
  return (
    <Col span={24}>
      <Checkbox
        value={therapy.therapeuticName}
        key={therapy.therapeuticId}
        className={
          areas.includes(therapy.therapeuticName)
            ? 'check-options check-options-selected'
            : 'check-options'
        }
        disabled={
          areas.length <= 4 ? false : !areas.includes(therapy.therapeuticName)
        }>
        {therapy.therapeuticName}
      </Checkbox>
    </Col>
  );
}

export default TherapySelectRow;
