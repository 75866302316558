import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Modal, Button, Input, notification } from 'antd';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import SponsorService from '../services/sponsorService';
import SitePageService from '../services/sitePageService';
import guestUserHand from '../../assets/images/guestUserHand.png';

/**
 * A component that allows users to report content on a site page. The component
 * displays a modal with a series of questions to gather information about the
 * content being reported. Once the user submits the report, the report is sent
 * to the CRIO team for review.
 * @param {{id: string}} id - The ID of the site page being reported.
 * @returns A React component that displays a modal for reporting content on a site page.
 */
function SiteFlagReport({ id }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [guestOpen, setGuestOpen] = useState(false);
  const [data, setData] = useState({
    sponsorUserId: null,
    contentReport: null,
    aboutContent: null,
    comment: null,
  });
  /**
   * Handles the "Ok" button click event in the modal dialog.
   * Closes the modal dialog by setting the state of `isModalOpen` to false.
   */
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Closes the modal by setting the isModalOpen state to false.
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  /**
   * Sets the content report in the data object and updates the page number to 2.
   * @param {{any}} content - the content report to set in the data object
   */
  const setContentReport = (content) => {
    let temp = Object.assign({}, data);
    temp.contentReport = content;
    setData(temp);
    setPageNo(2);
  };

  /**
   * Sets the about content of the page to the given content and updates the state of the component.
   * @param {{string}} content - The content to set as the about content.
   */
  const setAboutContent = (content) => {
    let temp = Object.assign({}, data);
    temp.aboutContent = content;
    setData(temp);
    setPageNo(3);
  };

  // Submits a report for the current site page to the server.
  const submitReport = () => {
    setLoading(true);
    let temp = Object.assign({}, data);
    temp.sponsorUserId = user.sponsorUserId;
    SitePageService.flagSite(temp, id)
      .then(() => {
        setPageNo(4);
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: 'Unexpected error occurred. Please try again later...',
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        open={guestOpen}
        footer={null}
        onCancel={() => {
          setGuestOpen(false);
        }}
        style={{
          top: 40,
        }}>
        <div className="demo-warning-modal">
          <h3>This is an Exclusive Feature for Signed-In Users</h3>
          <img src={guestUserHand} alt="scheduleademoAlert" />
          <p>
            Thank you for your interest in our exclusive content. Our goal is to
            connect you with our sites to help you achieve your goals. To
            explore the full benefits of our platform, we require a demo to be
            scheduled. Our team will be happy to show you around and answer any
            questions you may have.
          </p>
          <Button
            className="schedule-demo-button"
            onClick={() => {
              setGuestOpen(false);
              window.open(
                'https://www.clinicalresearch.io/schedule-a-demo/',
                '_blank',
              );
            }}>
            GET ACCESS
          </Button>
          <Button
            type="text"
            className="schedule-demo-login-button"
            onClick={() => {
              setGuestOpen(false);
              window.location.href = '/login';
            }}>
            Already a user? Sign in
          </Button>
        </div>
      </Modal>
      <Modal
        title={<div className="collection-modal-header">Report</div>}
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="report-modal"
        footer={false}
        destroyOnClose>
        {pageNo === 1 ? (
          <>
            <div className="report-header">
              What content on this page are you reporting?
            </div>
            <div
              className="report-options"
              onClick={() => {
                setContentReport('Study Information');
              }}>
              <p>Study Information</p>{' '}
              <span>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
            <div
              className="report-options"
              onClick={() => {
                setContentReport('Site Information');
              }}>
              <p>Site Information</p>{' '}
              <span>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
            <div
              className="report-options"
              onClick={() => {
                setContentReport('PI Information');
              }}>
              <p>PI Information</p>{' '}
              <span>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
          </>
        ) : null}
        {pageNo === 2 ? (
          <>
            <div className="report-header">
              What are you reporting about the ‘{data.contentReport}’?
            </div>
            <div
              className="report-options"
              onClick={() => {
                setAboutContent('False/incorrect information');
              }}>
              <p>False/incorrect information</p>
              <span>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
            <div
              className="report-options"
              onClick={() => {
                setAboutContent('Suspicious, spam, or fake');
              }}>
              <p>Suspicious, spam, or fake</p>
              <span>
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
            </div>
            <div className="report-footer">
              <Button
                className="report-footer-back-button"
                onClick={() => {
                  setPageNo(1);
                }}>
                Back
              </Button>
            </div>
          </>
        ) : null}
        {pageNo === 3 ? (
          <>
            <div className="report-header">
              {data.aboutContent === 'False/incorrect information'
                ? 'Please tell us what information is false'
                : `How is this ${data.contentReport} suspicious, spam, or fake?`}
            </div>
            <div className="report-input">
              <Input.TextArea
                bordered={false}
                showCount={false}
                maxLength={200}
                className="crio-input"
                style={{
                  height: 120,
                  width: '100%',
                  resize: 'none',
                  fontSize: '14px',
                  fontFamily: ['Poppins-Light', 'Poppins Light', 'Poppins'],
                  fontWeight: '200',
                  color: '#999999 !important',
                }}
                placeholder="Start typing..."
                onChange={(e) => {
                  let temp = Object.assign({}, data);
                  temp.comment = e.target.value;
                  setData(temp);
                }}
              />
            </div>
            <div className="report-footer">
              <Button
                className="report-footer-back-button"
                onClick={() => {
                  setPageNo(2);
                }}>
                Back
              </Button>
              <Button
                className="report-footer-submit-button"
                onClick={submitReport}
                loading={loading}>
                Submit
              </Button>
            </div>
          </>
        ) : null}
        {pageNo === 4 ? (
          <>
            <div className="report-success">
              <div className="report-success-icon">
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
              <div className="report-success-text">
                <h5>Thank you for reporting this.</h5>
                <p>
                  We appreciate you letting us know. We have notified the team
                  at CRIO.
                </p>
              </div>
            </div>
            <div className="report-footer">
              <Button
                className="report-footer-submit-button"
                onClick={handleOk}>
                Done
              </Button>
            </div>
          </>
        ) : null}
      </Modal>
      <div
        className="site-page-flag"
        onClick={() => {
          if (!SponsorService.checkLoggedIn()) {
            setGuestOpen(true);
          } else {
            setIsModalOpen(true);
            setPageNo(1);
          }
        }}>
        <FontAwesomeIcon icon={faFlag} />
      </div>
    </>
  );
}

export default SiteFlagReport;
