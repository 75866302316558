import { Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';

const SECOND = 1000;
const MINUTE = SECOND * 60;

/**
 * A component that displays a timer and a button to resend an invite. The timer counts down
 * from the given deadline and updates every second.
 * @param {{string}} deadline - The deadline for the timer to count down to.
 * @param {{object}} record - The record object containing the email to resend the invite to.
 * @param {{boolean}} resendLoading - A boolean indicating whether or not the resend button is loading.
 * @param {{function}} resendInvite - A function to resend the invite to the email in the record object.
 * @returns A component that displays a timer and a button to resend an invite.
 */
export const Timer = ({ deadline, record, resendLoading, resendInvite }) => {
  /**
   * Calculates the time remaining until the given deadline and sets it as the initial state
   * for the time variable. Uses the useMemo hook to avoid unnecessary re-renders.
   */
  const localDeadline = new Date(
    deadline.getTime() - deadline.getTimezoneOffset() * 60 * 1000,
  );
  const parsedDeadline = useMemo(
    () => Date.parse(localDeadline),
    [localDeadline],
  );
  const [time, setTime] = useState(parsedDeadline - Date.now());

  /**
   * Runs a timer that updates the remaining time until a given deadline every second.
   * @param {{Date}} parsedDeadline - The deadline to count down to, parsed as a Date object.
   */
  useEffect(() => {
    const interval = setInterval(
      () => setTime(parsedDeadline - Date.now()),
      1000,
    );
    return () => clearInterval(interval);
  }, [parsedDeadline]);

  return time && time > 0 ? (
    <div>
      <Button
        loading={resendLoading === record.email}
        onClick={() => {
          resendInvite(record.email);
        }}
        className={'unblock-button'}
        style={{ opacity: '0.8' }}
      >
        Resend
      </Button>
      <div className="timer">
        {Math.floor((time / MINUTE) % 60) > 0
          ? Math.floor((time / MINUTE) % 60) + 'min '
          : null}
        {Math.floor((time / SECOND) % 60)}s{' left'}
      </div>
    </div>
  ) : (
    <Button
      loading={resendLoading === record.email}
      onClick={() => {
        resendInvite(record.email);
      }}
      className={'unblock-button'}
    >
      Resend
    </Button>
  );
};
