import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  faAngleLeft,
  faAngleRight,
  faUserCircle,
  faChevronUp,
  faChevronDown,
  faBuilding,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel, Col, Skeleton, Divider, Row, Space } from 'antd';
import CollectionModal from '../components/CollectionModal';
import CommonService from '../services/commonService';
import util from '../../utils/util';

/**
 * A component that displays a card for a clinical trial site, including site images, site information, and principal investigator data.
 * @param {{siteData}} siteData - The data for the site to display.
 * @returns A React component that displays the site card.
 */
const SiteCard = ({ siteData, selectedAreas, areas }) => {
  const imgRef = useRef();
  const history = useHistory();
  const [imgs, setImgs] = useState([]);
  const [pis, setPis] = useState();
  const [study, setStudy] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingPi, setLoadingPi] = useState(false);
  const [showStudy, setShowStudy] = useState(false);
  const [studyCount, setStudyCount] = useState(0);
  const [arrowStyle, setArrowStyle] = useState('0px');

  /**
   * Opens the site page for the current site. If the user is logged in or has already visited
   * the site, the site page is opened immediately. Otherwise, the user is redirected to the login
   * page if they have visited more than two sites, or the site page is opened and the site is added
   * to the list of visited sites in local storage.
   */
  const openSitePage = () => {
    history.push(
      '/sitepage/' + siteData.siteId + (pis && pis.piId ? '/' + pis.piId : ''),
    );

    //below commented code is for restricting exact no of sites to guest user
    // let visitedSites = [];
    // if (localStorage.getItem("visitedSites")) {
    //   visitedSites = localStorage.getItem("visitedSites").split(",");
    // }
    // if (
    //   SponsorService.checkLoggedIn() ||
    //   visitedSites.includes(siteData.siteId.toString())
    // ) {
    //   history.push("/sitepage/" + siteData.siteId + "/");
    // } else {
    //   if (visitedSites.length > 2) {
    //     history.push("/login");
    //   } else {
    //     visitedSites.push(siteData.siteId);
    //     localStorage.setItem("visitedSites", visitedSites);
    //     history.push("/sitepage/" + siteData.siteId + "/");
    //   }
    // }
  };

  /**
   * useEffect hook that fetches site images and PI data for the given site ID.
   * @param {{Object}} siteData - The site data object containing the site ID.
   */
  useEffect(() => {
    setLoading(true);
    setLoadingPi(true);
    if (siteData) {
      CommonService.getSiteImages(siteData.siteId)
        .then((res) => {
          setImgs(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
      let payload = util.getAreaIdList(areas, selectedAreas);
      CommonService.getPisData(siteData.siteId, payload)
        .then((res) => {
          setPis(res.data.data.piData);
          setStudy(res.data.data.listOfStudy);
          setStudyCount(res.data.data.studySize);
          setLoadingPi(false);
        })
        .catch((err) => {
          setLoadingPi(false);
        });
    }
  }, []);

  return (
    <div className="search-site-card">
      <span
        style={{
          position: 'absolute',
          top: '20px',
          right: '30px',
          zIndex: '99',
        }}
      >
        <CollectionModal site={siteData} />
      </span>
      <Row gutter={[24, 24]}>
        {loading || imgs.length === 0 ? (
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="search-site-card-left-wrapper"
            style={{ cursor: 'pointer' }}
            onClick={openSitePage}
          >
            <Skeleton.Image active={loading} style={{ margin: '15px 0' }} />
          </Col>
        ) : (
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="search-site-card-left-wrapper"
          >
            <div
              className="search-site-card-left"
              style={{ cursor: 'pointer' }}
              onClick={openSitePage}
            >
              <Carousel ref={imgRef}>
                {imgs.slice(0, 5).map((el) => {
                  return (
                    <div className="search-site-card-imgs" key={el.imageUrl}>
                      <img src={el.imageUrl} alt="Org Photo" />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <div className="site-card-img-left-right">
              <div
                onClick={() => {
                  imgRef.current.prev();
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </div>
              <div
                onClick={() => {
                  imgRef.current.next();
                }}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={16}
          lg={16}
          xl={16}
          style={{ paddingBottom: '70px', cursor: 'pointer' }}
          onClick={openSitePage}
        >
          <Row gutter={[24, 24]} className="search-site-card-header">
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Space
                className={'search-site-card-header-left'}
                onClick={openSitePage}
              >
                <FontAwesomeIcon icon={faBuilding} />
                {siteData.siteName}
              </Space>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              className={'search-site-card-header-right'}
            >
              <div>
                {siteData.state
                  ? siteData.state + ', ' + siteData.country
                  : 'NA'}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="search-site-card-descrition">
              {siteData.siteDescription}
            </div>
            <Divider dashed className="site-card-divider" />
          </Row>
          {loadingPi ? (
            <Row
              gutter={[24, 24]}
              className="search-site-card-body-wrapper"
              onClick={openSitePage}
            >
              <Space>
                <Skeleton.Avatar active />
                <Skeleton.Input active />
              </Space>
            </Row>
          ) : pis ? (
            <>
              <Row>
                <Col className="search-site-card-body-left">
                  <p>Principal Investigator</p>
                </Col>
              </Row>
              <Row
                gutter={[24, 24]}
                className="search-site-card-body-wrapper"
                onClick={openSitePage}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={7}
                  xl={7}
                  className="search-site-card-body-left"
                >
                  <div className="search-site-card-pi-profile">
                    <div className="search-site-card-pi-profile-img">
                      {pis.piLogo ? (
                        <img src={pis.piLogo} alt="PI photo" />
                      ) : (
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          fontSize={42}
                          color="#aaaaaa"
                        />
                      )}
                    </div>
                    <div className="search-site-card-pi-profile-text">
                      <div>{pis.piName ? pis.piName : 'NA'}</div>
                      <p>
                        {pis.therapeutic || pis.therapeutic.length !== 0
                          ? pis.therapeutic.length > 1
                            ? pis.therapeutic[0] +
                              ' +' +
                              (pis.therapeutic.length - 1).toString()
                            : pis.therapeutic[0]
                          : 'NA'}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Space direction="vertical">
                    <div>
                      <div className="search-site-card-label">
                        Years of Experience
                      </div>
                      <div className="search-site-card-value">
                        {pis.yearOfExp || pis.yearOfExp === 0
                          ? pis.yearOfExp +
                            `${pis.yearOfExp < 2 ? ' year' : ' years'}`
                          : 'NA'}
                      </div>
                    </div>
                  </Space>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Space direction="vertical">
                    <div>
                      <div className="search-site-card-label"># of studies</div>
                      <div className="search-site-card-value">
                        {pis.numberOfStudies ? pis.numberOfStudies : 'NA'}
                      </div>
                    </div>
                  </Space>
                </Col>
                <Col xs={24} sm={12} md={12} lg={7} xl={7}>
                  <Space direction="vertical">
                    <div>
                      <div className="search-site-card-label">
                        Professional Certification
                      </div>
                      <div className="search-site-card-value">
                        {pis.piCertification || pis.piCertification.length !== 0
                          ? pis.piCertification.length > 1
                            ? pis.piCertification[0] +
                              ' +' +
                              (pis.piCertification.length - 1).toString()
                            : pis.piCertification[0]
                          : 'NA'}
                      </div>
                    </div>
                  </Space>
                </Col>
              </Row>
            </>
          ) : (
            <div className="search-site-card-label">
              No Principal Investigator available
            </div>
          )}
          {showStudy ? (
            <>
              <Row>
                <div className="search-site-card-descrition">
                  <Divider dashed className="site-card-divider" />
                </div>
              </Row>

              {study ? (
                <>
                  <Row>
                    <Col className="search-site-card-body-left">
                      <p>
                        Study Details<span>{studyCount}</span>
                      </p>
                    </Col>
                  </Row>
                  <Row className="search-card-study-table-row-wrapper">
                    <Row className="search-card-study-table-row" gutter={24}>
                      <Col span={7}>
                        <div>
                          <div className="search-site-card-label">
                            Indication
                          </div>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div>
                          <div className="search-site-card-label">Phase</div>
                        </div>
                      </Col>
                      <Col span={7}>
                        <div>
                          <div className="search-site-card-label">
                            Number Enrolled
                          </div>
                        </div>
                      </Col>
                      <Col span={6}>
                        <div>
                          <div className="search-site-card-label">
                            Start Year
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {study.map((st) => {
                      return (
                        <>
                          <Row
                            className="search-card-study-table-row"
                            gutter={24}
                          >
                            <Col span={7}>
                              <div>
                                <div className="search-site-card-value">
                                  {st.indication}
                                </div>
                              </div>
                            </Col>
                            <Col span={4}>
                              <div>
                                <div className="search-site-card-value">
                                  {st.phase}
                                </div>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div>
                                <div className="search-site-card-value">
                                  {st.numberEnrolled +
                                    `${
                                      st.numberEnrolled > 1
                                        ? ' Subjects'
                                        : ' Subject'
                                    }`}
                                </div>
                              </div>
                            </Col>
                            <Col span={6}>
                              <div>
                                <div className="search-site-card-value">
                                  {st.startYear}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col className="search-site-card-body-left">
                      <a onClick={openSitePage}>See more</a>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row>
                  <Col className="search-site-card-body-left">
                    <p>No Study available</p>
                  </Col>
                </Row>
              )}
            </>
          ) : null}
        </Col>
      </Row>
      {showStudy ? (
        <div className="show-study-button-wrapper">
          <div
            className="show-study-button"
            onClick={() => {
              setShowStudy(false);
            }}
            onMouseEnter={() => setArrowStyle('2px')}
            onMouseLeave={() => setArrowStyle('0px')}
          >
            Close Study Metric{' '}
            <span>
              <FontAwesomeIcon
                icon={faChevronUp}
                fontSize={16}
                style={{ marginTop: arrowStyle }}
              />
            </span>
          </div>
        </div>
      ) : (
        <div className="show-study-button-wrapper">
          <div
            className="show-study-button"
            onClick={() => {
              setShowStudy(true);
            }}
            onMouseEnter={() => setArrowStyle('2px')}
            onMouseLeave={() => setArrowStyle('0px')}
          >
            View Study Metric{' '}
            <span>
              <FontAwesomeIcon
                icon={faChevronDown}
                fontSize={16}
                style={{ marginTop: arrowStyle }}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteCard;
