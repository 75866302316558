import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user';
import therapeuticReducer from '../features/therapeuticArea';
import facilityReducer from '../features/facility';
import collectionsReducer from '../features/collections';
import bannedEmailReducer from '../features/bannedEmail';
import promotionalBannerReducer from '../features/promotionalBanner';

/**
 * Configures the Redux store with the given reducers.
 * @param {Object} reducer - An object containing the reducers for the store.
 * @returns A Redux store object.
 */
const Store = configureStore({
  reducer: {
    user: userReducer,
    therapeuticArea: therapeuticReducer,
    facility: facilityReducer,
    collections: collectionsReducer,
    bannedEmail: bannedEmailReducer,
    promotionalBanner: promotionalBannerReducer,
  },
});

export default Store;
