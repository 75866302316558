import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Form, Button, Input, Space, notification } from 'antd';
import CommonService from '../../services/commonService';

/**
 * A form component for editing email templates. Allows the user to edit the subject and body of the email,
 * and provides a preview of the output using ReactQuill. The user can save or reset the changes made to the template.
 * @param {{Object}} template - The email template object to be edited.
 * @param {function} setTemplate - A function to set the edited email template.
 * @returns A form component for editing email templates.
 */
function EmailTemplateForm({ template, setTemplate }) {
  const [form] = Form.useForm();
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [outputText, setOutputText] = useState(template.body);

  /**
   * Saves the updated template to the server.
   * @param {{object}} e - The event object containing the updated subject and body of the template.
   * @returns None
   */
  const saveTemplate = (e) => {
    setLoading(true);
    let payload = Object.assign({}, template);
    payload.subject = e.subject;
    payload.body = e.body;
    CommonService.updateTemplate(payload)
      .then((res) => {
        setTemplate(res.data.data);
        notification.success({
          message: template.type + ' updated successfully!',
        });
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: 'Something went wrong!! please try again...',
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={saveTemplate}
        initialValues={template}
        layout="vertical"
        requiredMark={false}
        onChange={() => {
          setEdited(true);
        }}
        scrollToFirstError
        onReset={() => {
          setEdited(false);
          setOutputText(template.body);
        }}>
        <Form.Item
          name={'subject'}
          label="Email Subject"
          rules={[
            {
              required: true,
              message: 'Please enter email subject',
            },
          ]}>
          <Input className="crio-input" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={'body'}
          label="Email Body"
          rules={[
            {
              required: true,
              message: 'Please enter email body',
            },
          ]}>
          <Input.TextArea
            className="crio-input"
            rows={20}
            typeof="code"
            onChange={(e) => {
              setOutputText(e.target.value);
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <ReactQuill theme="snow" value={outputText} readOnly />

        {/* <Input.TextArea
          className="crio-input"
          rows={10}
          typeof="code"
          value={outputText}
          style={{ width: "100%" }}
          contentEditable="false"
        /> */}
        <Space>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={
                edited ? 'signin-button-accept' : 'signin-button-disabled'
              }
              disabled={!edited}
              loading={loading}>
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="reset"
              className="signin-button-decline"
              disabled={loading}>
              Reset
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
}

export default EmailTemplateForm;
