import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider,
  Input,
  notification,
  Popconfirm,
  Select,
  Space,
  Table,
} from 'antd';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { noOfRowsDropDownData } from '../../../constants/dumps';
import SitePageService from '../../services/sitePageService';

/**
 * A component that displays a table of organizations with various information about each organization.
 * @param {{function}} setSearch - A function to set the search value for the table.
 * @param {{array}} orgs - An array of organization objects to display in the table.
 * @param {{function}} changePageSize - A function to change the number of rows displayed per page.
 * @param {{number}} orgCount - The total number of organizations in the table.
 * @param {{function}} pageChange - A function to change the current page of the table.
 * @param {{boolean}} loading - A boolean indicating whether the table is currently loading.
 * @returns A table of organizations with various information about each organization.
 */
export default function OrgTable({
  setSearch,
  orgs,
  changePageSize,
  orgCount,
  pageChange,
  loading,
  pgSize,
  pgNo,
}) {
  const [pageSize, setPageSize] = useState('5');
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState();
  const [searchSiteName, setSearchSiteName] = useState('');
  const [loadingOrg, setLoadingOrg] = useState(false);

  /**
   * useEffect hook that updates the table data when the orgs state changes.
   * @param {{Array}} orgs - The array of organization objects to be displayed in the table.
   */
  useEffect(() => {
    if (orgs) {
      let tepOrgs = JSON.parse(JSON.stringify(orgs));
      tepOrgs.forEach((record) => {
        record.key = record.organizationId.toString();
      });
      setTableData(tepOrgs);
    }
    setPageNo(pgNo + 1);
    setPageSize(pgSize);
  }, [orgs]);

  /**
   * Handles the search functionality for the search bar. Sets the search site name state
   * based on the value of the search input.
   * @param {{Event}} e - the event object
   */
  const onSearch = (e) => {
    const getSearchValue = e.target.value;
    if (getSearchValue.length < 0) {
      setSearchSiteName('');
    } else {
      setSearchSiteName(getSearchValue);
    }
  };

  // Handles the submission of a search query by setting the search state to the value of the searchSiteName variable.
  const handleSearchSubmit = () => {
    setSearch(searchSiteName);
  };

  /**
   * Calls the pageChange function with the given page and sets the page number to the given page.
   * @param {{number}} page - the page number to change to
   */
  const onPageChange = (page) => {
    pageChange(page);
    setPageNo(page);
  };
  /**
   * Sends an opt-out request to the SitePageService for the given organization ID.
   * @param {{string}} orgId - The ID of the organization to opt-out of.
   */
  const optOut = (orgId) => {
    setLoadingOrg(orgId);
    SitePageService.optOut(orgId)
      .then((res) => {
        handleSearchSubmit();
        setLoadingOrg(false);
        notification.success({
          message: res.data.data,
        });
      })
      .catch((err) => {
        setLoadingOrg(false);
        notification.error({ message: err.response.data.error.message });
      });
  };

  /**
   * An array of objects that define the columns for a table. Each object contains
   * a title, width, and a render function that returns the content for that column.
   * @type {Array}
   */
  let columns = [
    {
      title: 'Organization',
      width: '25%',

      render: (__, record) => {
        return (
          <div
            key={record.organizationId}
            className={'table_site_name pop-reg-666'}
          >
            {record.organizationName}
          </div>
        );
      },
    },
    {
      title: 'Primary Email',
      width: '30%',
      dataIndex: 'primaryEmail',
      render: (primaryEmail) => (
        <div key={primaryEmail} className="table_site_name pop-reg-666 email">
          {primaryEmail}
        </div>
      ),
    },
    {
      title: 'Setup Completion',
      width: '20%',
      render: (__, record) => {
        let unformDate;
        let sts = 'Not done yet.';
        let cd = 0;
        if (record.stepOneCompleted && record.stepTwoCompleted) {
          unformDate = record.stepTwoCompleted.split('T')[0];
          sts = 'Completed on ';
          cd = 2;
        } else if (record.stepOneCompleted) {
          unformDate = record.stepOneCompleted.split('T')[0];
          sts = 'Partially completed on ';
          cd = 1;
        } else {
          unformDate = null;
        }
        let displayDate = unformDate ? new Date(unformDate) : null;
        let formattedDate = displayDate
          ? displayDate.toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : null;

        return (
          <div
            className="table_site_name pop-reg-666"
            style={{
              color: cd === 0 ? '#932f2f' : cd === 1 ? '#e3761c' : '#009245',
            }}
          >
            {sts + (formattedDate ? formattedDate : '')}
          </div>
        );
      },
    },
    {
      title: 'Service opted',
      width: '25%',

      render: (__, record) => {
        return (
          <div key={record.organizationId}>
            {record.serviceOpted === 1 ? (
              <>
                <div
                  className="table_site_name pop-reg-666"
                  style={{
                    color: '#009245',
                  }}
                >
                  Service opted in
                </div>
                <div className="table_site_name pop-reg-666">
                  <Popconfirm
                    title="Confirm opt out ?"
                    placement="leftTop"
                    description="Are you sure to opt out for this organization?"
                    onConfirm={() => {
                      optOut(record.organizationId);
                    }}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Button className="block-button">Opt out</Button>
                  </Popconfirm>
                </div>
              </>
            ) : (
              <div
                className="table_site_name pop-reg-666"
                style={{
                  color: '#932f2f',
                }}
              >
                Service not opted in
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const tableHeader = () => {
    return (
      <>
        <div className="title-container">
          <div className="table-header-wrapper">
            <div className="table-header-wrapper-title">
              <Space>
                <p>Show</p>
                <div className="table-header-wrapper-title-dropdown">
                  <Select
                    defaultValue="5"
                    onChange={(value) => {
                      setPageSize(value);
                      changePageSize(value);
                    }}
                    options={noOfRowsDropDownData}
                    style={{ width: '100%' }}
                    className="crio-input"
                  />
                </div>
                <p>Entries</p>
              </Space>
            </div>
            <div className="table-header-wrapper-search">
              <Input
                className="crio-input"
                placeholder="Search by organization name"
                size="large"
                suffix={
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="dashboard-search-icon"
                    onClick={(e) => {
                      handleSearchSubmit(e);
                    }}
                  />
                }
                onChange={(e) => onSearch(e)}
                onPressEnter={(e) => {
                  handleSearchSubmit(e);
                }}
                style={{ width: '100%', minWidth: '270px' }}
              />
            </div>
          </div>
          <Divider type="horizontal" className="horizontal-divider-class" />
        </div>
        <div className="table-header-column">
          <div className="col-title" style={{ width: '25%' }}>
            {columns[0].title}
          </div>
          <div className="col-title" style={{ width: '30%' }}>
            {columns[1].title}
          </div>
          <div className="col-title" style={{ width: '20%' }}>
            {columns[2].title}
          </div>
          <div className="col-title" style={{ width: '25%' }}>
            {columns[3].title}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={tableData}
        title={tableHeader}
        className={'ant-side-table'}
        showHeader={false}
        loading={loading || loadingOrg !== false}
        pagination={{
          pageSize: pageSize,
          total: orgCount,
          current: pageNo,
          showSizeChanger: false,
          onChange: (page) => {
            onPageChange(page);
          },
        }}
      />
    </div>
  );
}
