import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PageNotFound from '../js/pages/notFoundPage/notFound.page';
import Home from '../js/pages/sponsor/Home';
import Login from '../js/pages/login/login.page';
import Navbar from '../js/components/Navbar';
import Footer from '../js/components/Footer';
import Search from '../js/pages/search/Search';
import SitePage from '../js/pages/sitePage/SitePage';
import MyList from '../js/pages/myList/MyList';
import UserProfile from '../js/pages/profile/SponsorProfile';
import AdminDashboard from '../js/pages/crioAdmin/AdminDashboard';
import UnauthorizeAccess from '../js/pages/unauthorizedPage/UnauthorizeAccess';
import SessionTimeout from '../js/pages/sessionTimeout/SessionTimeout';
import signup from '../js/pages/login/signup.page';

/**
 * Renders the routes for the application using React Router.
 * @returns A JSX element containing the routes for the application.
 */
const Routes = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup/:token" component={signup} />
        <Route exact path="/sitepage/:id" component={SitePage} />
        <Route exact path="/sitepage/:id/:pId" component={SitePage} />
        <Route exact path="/profile" component={UserProfile} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/my-list" component={MyList} />
        <Route exact path="/crio-admin/dashboard" component={AdminDashboard} />
        <Route exact path="/pageNotFound" component={PageNotFound} />
        <Route exact path="/session-timeout" component={SessionTimeout} />
        <Route
          exact
          path="/unauthorized-access"
          component={UnauthorizeAccess}
        />
        <Redirect to="/pageNotFound" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routes;
