import React, { useState } from 'react';
import { Button, Form, Input, Modal, notification, Space } from 'antd';
import SitePageService from '../../services/sitePageService';

/**
 * A modal component that allows the user to block or unblock a site.
 * @param {{object}} record - The record object containing information about the site.
 * @param {{string}} action - The action to perform on the site (block or unblock).
 * @param {{function}} handleModalCancel - The function to call when the modal is cancelled.
 * @param {{function}} updateTable - The function to call to update the table of sites.
 * @returns A modal component that allows the user to block or unblock a site.
 */
export default function SitesActionModal({
  record,
  action,
  handleModalCancel,
  updateTable,
}) {
  const actionModal = true;
  const [blackListReason, setBlackListReason] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  /**
   * Handles the blocking of a site by sending a request to the server with the site ID and the reason for blacklisting.
   * @param {{Event}} e - The event object that triggered the function call.
   * @returns None
   */
  const handleBlock = (e) => {
    setLoading(true);
    const payload = {
      blackListReason: blackListReason,
      action: 'block',
    };
    SitePageService.siteAction(record.siteId, payload)
      .then(() => {
        updateTable();
        handleModalCancel();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  /**
   * Handles the unblocking of a site by sending a request to the server to unblock the site.
   * @param {{Event}} e - The event object that triggered the unblock action.
   */
  const handleUnblock = (e) => {
    setLoading(true);
    const payload = {
      action: 'unblock',
    };
    SitePageService.siteAction(record.siteId, payload)
      .then(() => {
        updateTable();
        handleModalCancel();
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message:
            'Cannot publish site, Please save all mandatory fields to publish ',
        });
        setLoading(false);
      });
  };

  return (
    <div>
      {action === 'block' ? (
        <Modal
          title={['Sure to unpublish ', record.siteName, ' ', ' ?']}
          open={actionModal}
          onOk={handleBlock}
          onCancel={handleModalCancel}
          footer={false}
        >
          <Form
            form={form}
            onFinish={handleBlock}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name={'blackListReason'}
              label="Unpublish Reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter reason to unpublish.',
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={200}
                value={blackListReason}
                style={{
                  height: 80,
                  width: '100%',
                }}
                onChange={(e) => {
                  setBlackListReason(e.target.value);
                }}
              />
            </Form.Item>
            <Space>
              <Form.Item>
                <Button
                  key="back"
                  className="cancel-user-btn"
                  onClick={handleModalCancel}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  key="submit"
                  className="block-user-btn"
                  loading={loading}
                  htmlType="submit"
                >
                  Unpublish
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Modal>
      ) : (
        <Modal
          title={['Publish ', record.siteName]}
          open={actionModal}
          onOk={handleUnblock}
          onCancel={handleModalCancel}
          footer={[
            <Button
              key="back"
              className="cancel-user-btn"
              onClick={handleModalCancel}
              disabled={loading}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              className="unblock-user-btn"
              onClick={handleUnblock}
              loading={loading}
            >
              Publish
            </Button>,
          ]}
        >
          <p>are you sure you want to publish {record.siteName} ?</p>
          <br />
          <br />
        </Modal>
      )}
    </div>
  );
}
