import axios from '../../config/axiosConfig';
import { bannedEmailsUrl, commonUrl, sitesUrl } from '../../constants/urls';

const CommonService = {
  /**
   * Sends a GET request to retrieve a list of therapeutic areas from the server.
   * @returns {Promise} A promise that resolves with the list of therapeutic areas.
   */
  getTherapeuticAreas: () => {
    return axios.get(commonUrl + 'therapeutic');
  },
  /**
   * Sends a GET request to retrieve the facility data from the server.
   * @returns {Promise} A promise that resolves with the facility data.
   */
  getFacility: () => {
    return axios.get(commonUrl + 'facility');
  },
  /**
   * Sends a GET request to the server to retrieve promotional information.
   * @returns {Promise} A promise that resolves with the promotional information.
   */
  getPromo: () => {
    return axios.get(commonUrl + 'promo');
  },
  /**
   * Retrieves a list of banned emails from the server.
   * @returns {Promise} A promise that resolves with the list of banned emails.
   */
  getBannedEmails: () => {
    return axios.get(bannedEmailsUrl);
  },
  /**
   * Sends a search request to the server with the given page size, page number, and payload.
   * @param {number} pageSize - The number of results to return per page.
   * @param {number} pageNumber - The page number to return.
   * @param {Object} payload - The search payload object.
   * @returns A promise that resolves with the search results.
   */
  searchSites: (pageSize, pageNumber, payload) => {
    var config = {
      method: 'post',
      url: localStorage.getItem('SDIR_SPONSOR_TOKEN')
        ? commonUrl + 'search'
        : commonUrl + 'search-guest',
      params: {
        pageSize: pageSize,
        pageNumber: pageNumber,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload,
    };
    return axios(config);
  },
  /**
   * Retrieves the images for a given site ID using an HTTP POST request to the server.
   * @param {string} siteId - The ID of the site to retrieve images for.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  getSiteImages: (siteId) => {
    return axios.post(sitesUrl + siteId + '/images');
  },
  /**
   * Retrieves the PI study data for a given site ID.
   * @param {string} siteId - The ID of the site to retrieve PI study data for.
   * @returns {Promise} A promise that resolves with the PI study data for the given site ID.
   */
  getPisData: (siteId, payload) => {
    return axios.post(sitesUrl + siteId + '/pi-study', payload);
  },
  /**
   * Sends a GET request to retrieve popular searches from the server.
   * @returns {Promise} A promise that resolves with the response data.
   */
  getPopularSearches: () => {
    return axios.get(commonUrl + 'popular');
  },
  /**
   * Sends a GET request to the server to retrieve the current version of the copyright.
   * @returns {Promise} A promise that resolves with the current version of the copyright.
   */
  getCopyright: () => {
    return axios.get(commonUrl + 'copyright-version');
  },
  /**
   * Sends a GET request to retrieve all lookup data from the server.
   * @returns {Promise} A promise that resolves with the response data.
   */
  getAllLookupDatas: () => {
    return axios.get(commonUrl + 'all-lookup');
  },
  /**
   * Sends a GET request to retrieve all templates from the server.
   * @returns {Promise} A promise that resolves with the response data.
   */
  getAllTemplates: () => {
    return axios.get(commonUrl + 'all-templates');
  },
  /**
   * Sends a GET request to retrieve all terms from the server.
   * @returns {Promise} A promise that resolves with the response data.
   */
  getAllTerms: () => {
    return axios.get(commonUrl + 'all-terms');
  },
  /**
   * Sends a PUT request to update all terms with the given payload.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A promise that resolves with the response data if the request is successful, or rejects with an error if the request fails.
   */
  updateTerms: (payload) => {
    return axios.put(commonUrl + 'all-terms', payload);
  },
  /**
   * Sends a PUT request to update a template with the given payload.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A promise that resolves with the response data.
   */
  updateTemplate: (payload) => {
    return axios.put(commonUrl + 'all-templates', payload);
  },
  /**
   * Sends a PUT request to the server to update the lookup table with the given payload.
   * @param {Object} payload - The data to be sent in the request body.
   * @returns {Promise} A promise that resolves with the response data if the request is successful, or rejects with an error if the request fails.
   */
  updateLookup: (payload) => {
    return axios.put(commonUrl + 'all-lookup', payload);
  },
  /**
   * Deletes a lookup with the given ID from the server.
   * @param {number} id - The ID of the lookup to delete.
   * @returns {Promise} A promise that resolves when the lookup has been successfully deleted.
   */
  deleteLookup: (id) => {
    return axios.delete(commonUrl + 'all-lookup/' + id);
  },
  /**
   * Sends a POST request to the server to retrieve a lookup object containing all data
   * for the given payload.
   * @param {Object} payload - The payload object to send with the request.
   * @returns {Promise} A promise that resolves with the lookup object.
   */
  createLookup: (payload) => {
    return axios.post(commonUrl + 'all-lookup', payload);
  },
};

export default CommonService;
