import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PublicUnpublishedFilterTable from '../../components/PublicUnpublishedFilterTable';
import SiteDataTable from '../../components/SiteDataTable';
import SitePageService from '../../services/sitePageService';
import OrganizationOptions from './OrganizationOptions';
let cancelToken;

/**
 * A functional component that renders a page for managing sites. The component
 * includes filters for searching and sorting sites, as well as options for
 * selecting organizations and changing the page size. The component fetches
 * data from the server using SitePageService and displays it in a table.
 * @returns A React component that displays a page for managing sites.
 */
const ManageSites = () => {
  const searchName = '';
  const [siteCount, setSiteCount] = useState(0);
  const [publicCheck, setPublicCheck] = useState(false);
  const [unpublishedCheck, setUnpublishedCheck] = useState(false);
  const [search, setSearch] = useState('');
  const [, setOrgSearch] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [organizationOptionsData, setOrganizationOptionsData] = useState([]);
  const [selectedOrgAreas, setSelectedOrgAreas] = useState([]);
  const [sites, setSites] = useState();
  const [pgNo, setPgNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  /**
   * Applies filters to the site pages based on the given payload, page size, and page number.
   * @param {{object}} payload - The payload object containing the filters to apply.
   * @param {{number}} pageSize - The number of sites to display per page.
   * @param {{number}} pageNumber - The current page number.
   */
  const applyFilters = async (payload, pageSize, pageNumber) => {
    setPgNo(pageNumber + 1);
    setLoading(true);
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Canceling previous call.');
    }
    cancelToken = axios.CancelToken.source();
    try {
      const response = await SitePageService.getAllSites(
        payload,
        pageSize,
        pageNumber,
        cancelToken.token,
      );
      setSites(response.data.data.organizationSitesDatas);
      setSiteCount(response.data.data.sitesCount);
      setLoading(false);
    } catch (err) {}
  };

  /**
   * Changes the search data and applies filters to the search results.
   * @param {{Object}} searchData - The search data to filter the results by.
   */
  const changeSearch = (searchData) => {
    const payload = {
      organizationId: selectedOrgAreas,
      searchSiteName: searchData,
      publish: publicCheck ? 1 : 0,
      unPublish: unpublishedCheck ? 1 : 0,
    };
    setSearch(searchData);
    applyFilters(payload, pageSize, 0);
  };

  // Clears the current selection and applies the default filters to the data.
  const clearSelection = () => {
    const payload = {
      organizationId: selectedOrgAreas,
      publish: 0,
      unPublish: 0,
      searchSiteName: search,
    };
    setPublicCheck(false);
    setUnpublishedCheck(false);
    applyFilters(payload, pageSize, 0);
  };

  /**
   * Changes the published status of the search results based on the given data.
   * @param {{any}} data - The data to use to change the published status.
   */
  const changePublished = (data) => {
    const payload = {
      organizationId: selectedOrgAreas,
      searchSiteName: search,
      publish: data ? 1 : 0,
      unPublish: unpublishedCheck ? 1 : 0,
    };
    setPublicCheck(data);
    applyFilters(payload, pageSize, 0);
  };

  /**
   * Changes the unpublished status of the search results and applies the filters to the page.
   * @param {{any}} data - The data to be updated.
   * @returns None
   */
  const changeUnPublished = (data) => {
    const payload = {
      organizationId: selectedOrgAreas,
      searchSiteName: search,
      publish: publicCheck ? 1 : 0,
      unPublish: data ? 1 : 0,
    };
    setUnpublishedCheck(data);
    applyFilters(payload, pageSize, 0);
  };

  /**
   * Changes the page size of the data displayed on the page and applies the filters with the new page size.
   * @param {{number}} data - the new page size to set
   */
  const changePageSize = (data) => {
    const payload = {
      organizationId: selectedOrgAreas,
      searchSiteName: search,
      publish: publicCheck ? 1 : 0,
      unPublish: unpublishedCheck ? 1 : 0,
    };
    setPageSize(data);
    applyFilters(payload, data, 0);
  };

  /**
   * Handles a page change event by constructing a payload object with the selected organization areas,
   * search term, and publish/unpublish status. The payload is then passed to the applyFilters function
   * along with the page size and the new page number.
   * @param {{any}} data - The new page number.
   */
  const pageChange = (data) => {
    const payload = {
      organizationId: selectedOrgAreas,
      searchSiteName: search,
      publish: publicCheck ? 1 : 0,
      unPublish: unpublishedCheck ? 1 : 0,
    };
    applyFilters(payload, pageSize, data - 1);
  };

  /**
   * Handles a change in the selected organization and applies the appropriate filters.
   * @param {{Event}} e - The event object that triggered the change.
   * @param {{Array}} orgList - The list of organizations to choose from.
   */
  const handleOrgChange = (e, orgList) => {
    const payload = {
      organizationId: e,
      searchSiteName: search,
      publish: publicCheck ? 1 : 0,
      unPublish: unpublishedCheck ? 1 : 0,
    };
    setSelectedOrgAreas(e);
    applyFilters(payload, pageSize, 0);
  };

  /**
   * Updates the organization search state and applies the search filter to the organization options data.
   * @param {{Object}} searchData - The search data to filter the organization options by.
   */
  const changeOrgSearch = (searchData) => {
    setOrgSearch(searchData);
    orgSearchFilter(searchData, organizationOptionsData);
  };

  /**
   * Filters the given data based on the search criteria and sets the organization options.
   * @param {{searchData}} searchData - The search criteria to filter the data by.
   * @param {{data}} data - The data to be filtered.
   */
  const orgSearchFilter = (searchData, data) => {
    let tempOrgs = [];
    if (!searchData || searchData === '') {
      setOrganizationOptions(data);
    } else {
      data.forEach((org) => {
        if (
          org.organizationName.toLowerCase().includes(searchData.toLowerCase())
        ) {
          tempOrgs.push(org);
        }
      });
      setOrganizationOptions(tempOrgs);
    }
  };

  /**
   * useEffect hook that fetches all sites and organizations from the server and sets the state
   * of the component accordingly.
   */
  useEffect(() => {
    setLoading(true);
    let payload = {
      organizationId: [],
      publish: publicCheck,
      unPublish: unpublishedCheck,
      searchSiteName: searchName,
    };
    SitePageService.getAllSites(payload, 5, 0)
      .then((res) => {
        setSites(res.data.data.organizationSitesDatas);
        setSiteCount(res.data.data.sitesCount);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        window.location.href = '/unauthorized-access';
      });
    SitePageService.getAllOrgs()
      .then((res) => {
        setOrganizationOptions(res.data.data);
        setOrganizationOptionsData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {user.isSuperUser === 1 ? (
        <div className="body-container-tab" style={{ minHeight: '70vh' }}>
          <div className="tabs-header">
            Sites<p>{siteCount}</p>
          </div>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <div className="sites-left">
                <PublicUnpublishedFilterTable
                  publicCheck={publicCheck}
                  setPublicCheck={changePublished}
                  unpublishedCheck={unpublishedCheck}
                  setUnpublishedCheck={changeUnPublished}
                  clearSelection={clearSelection}
                />
              </div>
              <OrganizationOptions
                organizationOptions={organizationOptions}
                selectedOrgAreas={selectedOrgAreas}
                setSelectedOrgAreas={handleOrgChange}
                changePageSize={changePageSize}
                pageChange={pageChange}
                setOrgSearch={changeOrgSearch}
              />
            </Col>

            <Col xs={24} sm={24} md={19} lg={19} xl={19}>
              <div className="sites-left">
                <SiteDataTable
                  sites={sites}
                  setSearch={changeSearch}
                  changePageSize={changePageSize}
                  pageChange={pageChange}
                  siteCount={siteCount}
                  loading={loading}
                  pgNo={pgNo}
                />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="crio-loading" style={{ height: '70vh' }}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default ManageSites;
