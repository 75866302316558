import React, { useEffect, useState } from 'react';
import { Collapse, Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import CommonService from '../../services/commonService';
import TermForm from './TermForm';
const { Panel } = Collapse;

/**
 * A React component that displays a list of terms and conditions and allows the user to edit them.
 * @returns A JSX element that displays the terms and conditions.
 */
function TermsTemplate() {
  const [terms, setTerms] = useState();

  /**
   * useEffect hook that fetches all terms from the server and sets the state with the response data.
   * If there is an error, the user is redirected to the unauthorized access page.
   */
  useEffect(() => {
    CommonService.getAllTerms()
      .then((res) => {
        setTerms(res.data.data);
      })
      .catch(() => {
        window.location.href = '/unauthorized-access';
      });
  }, []);
  return (
    <div className="template-body">
      {terms ? (
        terms.map((term) => {
          return (
            <Collapse
              key={term.termsAndConditionId}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <FontAwesomeIcon
                  icon={faCaretRight}
                  rotation={isActive ? 90 : 0}
                  className="site-expand-icon"
                />
              )}
              className="template-collapse"
              destroyInactivePanel
            >
              <Panel
                key={term.termsAndConditionId}
                header={
                  <div className="site-header-bar">
                    <div className="site-header-name">{term.key}</div>
                  </div>
                }
              >
                <TermForm term={term} setTerms={setTerms} />
              </Panel>
            </Collapse>
          );
        })
      ) : (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </div>
  );
}

export default TermsTemplate;
