import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Input,
  notification,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faSearch, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { noOfRowsDropDownData } from '../../constants/dumps';
import SitesActionModal from '../pages/crioAdmin/SitesActionModal';
import SponsorService from '../services/sponsorService';

/**
 * A functional component that renders a table of sites with various information and actions.
 * @returns A table of sites with various information and actions.
 */
const SiteDataTable = ({
  sites,
  setSearch,
  loading,
  changePageSize,
  pageChange,
  siteCount,
  pgNo,
}) => {
  const [pageSize, setPageSize] = useState('5');
  const [pageNo, setPageNo] = useState(1);
  const [searchSiteName, setSearchSiteName] = useState('');
  const [tableData, setTableData] = useState();
  const [openActionModal, setOpenActionModal] = useState(false);
  const [clickedRecord, setClickedRecord] = useState();
  const [action, setAction] = useState();
  const [loadingSample, setloadingSample] = useState(false);

  /**
   * Updates the search site name based on the value of the search input field.
   * @param {{Event}} e - The event object that triggered the search.
   */
  const onSearch = (e) => {
    const getSearchValue = e.target.value;
    if (getSearchValue.length < 0) {
      setSearchSiteName('');
    } else {
      setSearchSiteName(getSearchValue);
    }
  };

  // Handles the submission of a search query by setting the search state to the value of the searchSiteName variable.
  const handleSearchSubmit = () => {
    setSearch(searchSiteName);
    setPageNo(1);
  };

  /**
   * Calls the pageChange function with the given page and sets the current page number to the given page.
   * @param {{number}} page - The page number to change to.
   */
  const onPageChange = (page) => {
    pageChange(page);
    setPageNo(page);
  };

  const openModal = (e, record, action) => {
    setClickedRecord(record);
    setAction(action);
    setOpenActionModal(true);
  };

  const handleModalCancel = (e) => {
    setOpenActionModal(false);
    setClickedRecord(null);
    setAction('');
  };

  /**
   * Makes a site a sample site by calling the SponsorService API and updating the state of the component.
   * @param {{Object}} record - The record object containing the siteId and siteName of the site to be made a sample site.
   * @returns None
   */
  const makeSample = (record) => {
    setloadingSample(record.siteId);
    SponsorService.makeSample(record.siteId)
      .then((res) => {
        handleSearchSubmit();
        setloadingSample(false);
        notification.success({
          message:
            record.siteName +
            (res.data.data.isSampleSite === 1 ? ' set' : ' unset') +
            ' as sample site successfully..',
        });
      })
      .catch((err) => {
        setloadingSample(false);
        notification.error({ message: err.response.data.error.message });
      });
  };

  // Updates the table by calling the handleSearchSubmit function.
  const updateTable = () => {
    handleSearchSubmit();
  };

  /**
   * An array of objects that define the columns for a table. Each object contains a title,
   * a render function that returns the content for the column, and an optional dataIndex
   * property that specifies the key in the data object to use for the column content.
   * @param {{string}} title - The title of the column.
   * @param {{function}} render - A function that returns the content for the column.
   * @param {{string}} dataIndex - The key in the data object to use for the column content.
   * @returns An array of objects that define the columns for a table.
   */
  let columns = [
    {
      title: 'Site',
      render: (__, record) => {
        return (
          <div className="table_site_name pop-reg-666" key={record.siteId}>
            {record.siteLogo ? (
              <img src={record.siteLogo} alt="Site Logo" />
            ) : (
              <Skeleton.Image
                style={{
                  maxWidth: '60px',
                  maxHeight: '30px',
                  marginRight: '10px',
                  padding: '18px',
                }}
              />
            )}
            {record.siteName}
          </div>
        );
      },
    },
    {
      title: 'Organization',
      dataIndex: 'organizationName',
      render: (organizationName) => (
        <div className="pop-reg-666" key={organizationName}>
          {organizationName}
        </div>
      ),
    },
    {
      title: 'Principal Investigators',
      render: (__, record) => {
        return (
          <>
            <div
              className="principal-list"
              key={record.piDataResponseDtos.piId}>
              {record.piDataResponseDtos &&
                record.piDataResponseDtos.map((pi, index) => {
                  if (index < 3) {
                    return (
                      <li key={pi.piId}>
                        {pi.profilePic ? (
                          <img src={pi.profilePic} alt="PI Img" />
                        ) : (
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            fontSize={20}
                            style={{
                              marginRight: '10px',
                              color: 'rgb(153, 153, 153)',
                              border: '1px solid #009ceb',
                              borderRadius: '50%',
                            }}
                          />
                        )}{' '}
                        {pi.piName}
                      </li>
                    );
                  }
                })}
              {record.piDataResponseDtos &&
              record.piDataResponseDtos.length > 3 ? (
                <div className="extra-pi-icon">
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: 'Profile Status',
      render: (__, record) => {
        return (
          <div className="table-profile-status-wrapper" key={record.siteId}>
            <div>
              <div className="table-profile-status pop-reg-666">
                <span
                  style={{ color: record.isActive ? '#009245' : '#C04040' }}>
                  {record.isActive ? 'Public' : 'Unpublished'}
                </span>{' '}
                {record.statusChangedDate ? 'since' : ''}
              </div>
              <div className="table-profile-status-date pop-reg-666">
                {record.statusChangedDate
                  ? moment(record.statusChangedDate).format('DD-MMM-yyyy h:mmA')
                  : null}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'isBlackList',

      render: (__, record) => {
        return (
          <div key={record.siteName}>
            {record.isActive === 0 ? (
              <div className="table_site_name pop-reg-666" key={record.siteId}>
                <Button
                  onClick={(e) => openModal(e, record, 'unblock')}
                  disabled={record.percentageComplete !== 100}
                  className={'unblock-button'}>
                  Publish
                </Button>
                <Tooltip
                  title={
                    record.blackListReason
                      ? record.blackListReason
                      : record.percentageComplete === 100
                        ? 'Not published yet'
                        : 'Cannot publish due to incomplete information'
                  }>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="blocked-reason-info-icon"
                  />
                </Tooltip>
              </div>
            ) : (
              <div className="table_site_name pop-reg-666" key={record.siteId}>
                <Button
                  onClick={(e) => openModal(e, record, 'block')}
                  className={'block-button'}>
                  Unpublish
                </Button>
              </div>
            )}
            {record.isSampleSite === 0 ? (
              <Popconfirm
                title="Set as Sample"
                placement="leftTop"
                description="Are you sure to set this site as sample?"
                onConfirm={() => {
                  makeSample(record);
                }}
                okText="Confirm"
                cancelText="Cancel">
                <Button
                  type="link"
                  loading={loadingSample === record.siteId}
                  style={{
                    color: '#0d2b3a',
                    paddingLeft: 0,
                    marginTop: '10px',
                  }}
                  className="link-button">
                  Set Sample
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Remove Sample"
                placement="leftTop"
                description="Are you sure to unset this site as sample?"
                onConfirm={() => {
                  makeSample(record);
                }}
                okText="Confirm"
                cancelText="Cancel">
                <Button
                  type="link"
                  loading={loadingSample === record.siteId}
                  style={{
                    color: '#932f2f',
                    paddingLeft: 0,
                    marginTop: '10px',
                  }}
                  className="link-button">
                  Unset Sample
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  columns = columns.map((el) => {
    el.onCell = (_, index) => ({
      width: 100 / (columns.length - 1) + '%',
    });
    return el;
  });

  /**
   * useEffect hook that updates the table data whenever the sites prop changes.
   * @param {{Array}} sites - The array of site objects to display in the table.
   * @returns None
   */
  useEffect(() => {
    setTableData(sites);
    setPageNo(pgNo);
  }, [sites]);
  /**
   * Renders the header of a table with a dropdown to select the number of rows to display,
   * a search bar to search for entries, and the column titles.
   * @returns A JSX element containing the table header.
   */
  const tableHeader = () => {
    return (
      <>
        <div className="title-container">
          <div className="table-header-wrapper">
            <div className="table-header-wrapper-title">
              <Space>
                <p>Show</p>
                <div className="table-header-wrapper-title-dropdown">
                  <Select
                    defaultValue="5"
                    onChange={(value) => {
                      setPageSize(value);
                      changePageSize(value);
                      setPageNo(1);
                    }}
                    options={noOfRowsDropDownData}
                    style={{ width: '100%' }}
                    className="crio-input"
                  />
                </div>
                <p>Entries</p>
              </Space>
            </div>
            <div className="table-header-wrapper-search">
              <Input
                className="crio-input"
                placeholder="Search by Name, Site"
                size="large"
                suffix={
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="dashboard-search-icon"
                    onClick={(e) => {
                      handleSearchSubmit(e);
                    }}
                  />
                }
                onChange={(e) => onSearch(e)}
                onPressEnter={(e) => {
                  handleSearchSubmit(e);
                }}
                style={{ width: '100%', minWidth: '270px' }}
              />
            </div>
          </div>
          <Divider type="horizontal" className="horizontal-divider-class" />
        </div>
        <div className="table-header-column">
          {columns.map((col) => {
            return (
              <div
                className="col-title"
                style={{ width: 100 / (columns.length - 1) + '%' }}
                key={col.title}>
                {col.title}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={tableData}
        title={tableHeader}
        className={'ant-side-table'}
        showHeader={false}
        loading={loading}
        pagination={{
          pageSize: pageSize,
          current: pageNo,
          total: siteCount,
          showSizeChanger: false,
          onChange: (page) => {
            onPageChange(page);
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            key: record.siteId,
            style: { opacity: record.isActive ? '1' : '0.7' },
            onClick: (event) => {
              // console.log(record);
              //window.open("/site/" + record.siteId, "_blank");
            },
            onMouseEnter: (event) => {
              setTableData(
                tableData.map((el, index) => {
                  if (rowIndex === index) {
                    el.action = 'visible';
                    return el;
                  }
                  return el;
                }),
              );
            },
            onMouseLeave: (event) => {
              setTableData(
                tableData.map((el, index) => {
                  if (rowIndex === index) {
                    delete el.action;
                    return el;
                  }
                  return el;
                }),
              );
            },
          };
        }}
      />
      {openActionModal ? (
        <SitesActionModal
          handleModalCancel={handleModalCancel}
          record={clickedRecord}
          action={action}
          updateTable={updateTable}
        />
      ) : null}
    </div>
  );
};
export default SiteDataTable;
