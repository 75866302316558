export const commonUrl = '/common/';
export const userProfilePicurl = '/user/profile-pic';
export const loginUrl = '/user/login';
export const getProfileUrl = '/user/profile';
export const forgotEmail = '/user/forgotpassword';
export const verifyEmail = '/user/verify';
export const createPasswordurl = '/user/password';
export const signupUrl = '/user/signup';
export const sitesUrl = '/sites/';
export const organizationUrl = '/organizations/';
export const studiesUrl = 'studies/site/';
export const sitePagePiUrl = 'principal-investigator/site/';
export const manageUsersUrl = '/user/manageAllUsers';
export const userActionUrl = '/user/blackList';
export const newSignupUrl = '/user/verify';
export const bannedEmailsUrl = '/user/banned-emails';
export const termsAndConditionsUrl = '/user/terms-and-condition';
export const acceptedTermsUrl = '/user/accept-terms-and-condition';
export const checkUserRegistered = '/user/user-present';
export const inviteUserUrl = '/user/invite';
export const verifyUserToken = '/user/verify-token';
